import React, {useEffect, useState} from "react";
import {IProfilePersonalForm} from "../../../types/IProfilePersonalForm";
import {useNavigate} from "react-router-dom";
import API from "../../../../requests/_axios";
import {CONFIG} from "../../../../CONFIG";
import SessionModel from "../../../../stores/SessionModel";
import {ProfileIndividualDetailsEditor} from "../../common/ProfileIndividualDetailsEditor";
import {NavigationIndividualMain} from "../navigation/NavigationIndividualMain";
import {ProfileIndividualHeader} from "./ProfileIndividualHeader";
import {IChallenge} from "../../../types/IChallenge";
import {Help} from "../../common/Help";
import {ProfileIndividualView} from "../../common/ProfileIndividualView";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import * as TestAPI from "../../../../requests/TestAPI";
import * as CertificateAPI from "../../../../requests/CertificateAPI";
import * as SsoAPI from "../../../../requests/SsoAPI";
import {ISubGetRequest} from "../../../types/subs/ISubGetRequest";

export const ProfileIndividual: React.FC<{userId: number | null}> = ({userId}) => {
  const [preview, setPreview] = useState<any>();
  const [avatar, setAvatar] = React.useState<File>();
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [subRequest, setSubRequest] = useState<ISubGetRequest>();
  const [challengeList, setChallengeList] = useState<any>([]);
  const [authorities, setAuthorities] = useState<string[]>([]);
  const [form, setForm] = useState<IProfilePersonalForm>({
        firstname: "",
        lastname: "",
        patronymic: "",
        telegram: "",
        birthDate: "",
        skills: "",
        phone: "",
        fias: ""
  });

      useEffect(() => {
        ChallengeAPI.updateLastSeenIndividual();
        fetchProfile();
        if (SessionModel.isPersonal()) {
          fetchChallenges();
        }
        SsoAPI.getSubRequest((response) => {
          if (response.status === 200 && response.data) {
            setSubRequest(response.data);
          }
        });
        if (SessionModel.isSub()) {
          let auths = new Array<string>();
          ChallengeAPI.getAuthorities((response) => {
            if (response.status === 200) {
              auths = auths.concat(response.data);
              TestAPI.getAuthorities((response) => {
                if (response.status === 200) {
                  auths = auths.concat(response.data);
                  CertificateAPI.getAuthorities((response) => {
                    if (response.status === 200) {
                      auths = auths.concat(response.data);
                      setAuthorities(auths);
                    }
                  })
                }
              })
            }
          })
        }
      },[]);

      const fetchChallenges = () => {
        API.get("/challenge/api/v1/challenge?filter=my", {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response => {
          setChallengeList(response.data);
        }));
      }

      const fetchProfile = () => {
        let url = "/challenge/api/v1/personal/profile";
        if(userId !== null && userId !== undefined)
          url = url + "/" + userId;
        API.get(url, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
              if (response.status === 200) {
                setForm(response.data);
                if (response.data.avatar !== null)
                  setPreview(CONFIG.STORAGE_URL + "/" + (userId !== null && userId !== undefined ? userId : SessionModel.getCurrentUserAuthData().id) + "/avatar/" + response.data.avatar);
                else
                  setPreview("/images/no_ava_user.svg");
              }
            });
      }

      const handleAvatarChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        setError("");
        const fileList = e.target.files;
        if (!fileList) return;
        if (fileList[0].size >= 5000000) {
          setError("Размер изображения не должен превышать 5Мб.");
          return;
        }
        const formData = new FormData();
        formData.append("file", fileList[0], fileList[0].name);
        API.post("/challenge/api/v1/personal/profile/avatar", formData, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
          if(response.status === 200) {
            setPreview(CONFIG.STORAGE_URL + "/" + SessionModel.getCurrentUserAuthData().id + "/avatar/" + response.data.avatar);
            setForm({...form, avatar: response.data.avatar});
          } else {
            setError("Изображение должно быть в формате JPG и размер не должен превышать 5 Мб.")
          }
        });

        setAvatar(fileList[0]);
      };

  const handleShowDetails = () => {
    hideTeamMember();
    setCurrentUserId(SessionModel.getCurrentUserAuthData().userId);
    // @ts-ignore
    document.querySelector('#right-block').style.right = "0"
  }
  const handleCloseDetails = () => {
    setCurrentUserId(null);
    // @ts-ignore
    document.querySelector('#right-block').style.right = "-100vw"
  }
  const handleExit = () => {
    localStorage.removeItem("token");
    navigate("/");
  }
  const hideTeamMember = () => {
    // @ts-ignore
    document.querySelector('#right-block-team-member').style.right = "-100vw";
  }
  const showTeamMember = () => {
    handleCloseDetails();
    // @ts-ignore
    document.querySelector('#right-block-team-member').style.right = "0";
  }

  const acceptSubRequest = () => {
    if (!subRequest) return;
    if (!confirm("Вы уверены, что хотите принять запрос? После принятия запроса необходимо будет авторизоваться повторно. " +
        "Обратите внимание, что вы сможете осуществлять действия на платформе после того, как пригласивший вас администратор предоставит вам соответствующие права")) return;
    SsoAPI.acceptSubRequest(subRequest.requestId, (response) => {
      if (response.status === 200) {
        localStorage.removeItem("token");
        navigate("/signin");
      } else {
        setError(response.data.message);
      }
    })
  }

  const rejectSubRequest = () => {
    if (!subRequest) return;
    if (!confirm("Вы уверены, что хотите отклонить запрос?")) return;
    SsoAPI.rejectSubRequest(subRequest.requestId, (response) => {
      if (response.status === 200) {
        localStorage.removeItem("token");
        navigate("/signin");
      } else {
        setError(response.data.message);
      }
    })
  }

  return (
      <div className="platform-background">
        <Help challenge={undefined}/>
        <img src="/images/main.jpg" className="image-back-video" alt=""/>
        <div className="left-block">
          <ProfileIndividualHeader newPreview={preview}/>
          <main className="content">
            <NavigationIndividualMain/>
            <div className="block-center">
              <div className="user-profile">
                <label htmlFor="addimage" className="addpost-btn">
                  <img src={preview} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}} alt="" className="avater-user"/>
                  <input type="file" name="" accept=".jpg,.jpeg" id="addimage" onChange={handleAvatarChange} style={{display: 'none' }}/>
                </label>
                <div className="user-info">
                  <p className="fio-or-name">{form.firstname || "Имя"} {form.lastname || "Фамилия"}</p>
                  <p className="city">{form.city || "Город"}</p>
                </div>
              </div>
              <div className="user-profile-info">
                <div style={{ display: error === "" ? "none" : "" }} className="error-message">{error}</div>
                {subRequest ?
                    <button className="btn">
                      <p className="btn-heading profile">
                        Запрос на добавление в администраторы
                      </p>
                      <p className="btn-description">
                        <div style={{marginBottom: "5px"}}>"{subRequest.domProfileInfo.fullName}" предлагает вам стать администратором</div>
                        <a onClick={acceptSubRequest} className="active-link">Принять</a>
                        <a onClick={rejectSubRequest} className="active-link" style={{marginLeft: "15px", color: "red", borderBottom: "2px solid red"}}>Отклонить</a>
                      </p>
                    </button>
                    :
                    <></>
                }
                {authorities.length > 0 ?
                    <button className="btn">
                      <p className="btn-heading profile">
                        Ваши роли на платформе
                      </p>
                      <p className="btn-description">
                        <div style={{marginBottom: "5px"}}>
                          {authorities.join(", ")}
                        </div>
                      </p>
                    </button>
                    :
                    <></>
                }
                <button className="btn" id="personal" onClick={handleShowDetails} style={form.firstname ? {display: 'none'} : {}}>
                  <p className="btn-heading profile">
                    Личные данные
                  </p>
                  <p className="btn-description">
                    Заполните профиль пользователя. <a href="https://disk.yandex.ru/i/G4sWIpeejVQQLQ" target="_blank" className="active-link">Видеоинструкция</a>
                  </p>
                </button>
                <button className="btn-complete" onClick={handleShowDetails} style={form.firstname ? {} : {display: 'none'}}>
                  <p className="btn-heading complete">
                    Личные данные
                  </p>
                  <p className="btn-description">
                    Все данные заполнены. Нажмите, чтобы отредактировать данные
                  </p>
                </button>
                <button className="btn preview-background" onClick={showTeamMember}>
                  <p className="btn-heading preview">
                    Предпросмотр
                  </p>
                  <p className="btn-description" style={{color: "white"}}>
                    Как видят ваш профиль другие пользователи
                  </p>
                </button>
                {challengeList && challengeList.length > 0 ? (
                    <ul className="btn">
                      <div className="profile-flex">
                        <p className="challenges-profile-heading">
                          Участвую
                        </p>
                        <a href="/challenge" className="link-all-challenges">Все мероприятия</a>
                      </div>
                      {challengeList?.map((challenge: IChallenge) =>
                          <li className="challenge-card">
                            <div className="challenge-card-main">
                              <img id={"challenge-avatar-" + challenge.id} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                   src={CONFIG.STORAGE_URL + "/" + challenge.userId + "/challenge/" + challenge.id + "/avatar/" + challenge.avatar} alt=""
                                   className="challenge-avatar"
                                   onClick={() => window.open(challenge.site)}/>
                              <div className="user-info">
                                <p className="challenge-header"><a href={`/challenge/${challenge.id}/my`}>{challenge.name}</a>
                                </p>
                                <p className="challenge-company">{challenge.orgName || ""}</p>
                              </div>
                            </div>
                            <p className="challenge-description">
                              {challenge.description}
                            </p>
                            <div className="challenge-information">
                              <a href={`/challenge/${challenge.id}/my`} className="challenge-landing">
                                Перейти
                              </a>
                              {new Date(challenge.registrationEndDate || "") > new Date() ? (
                                  <p className="challenge-date">
                                    Идёт регистрация
                                  </p>
                              ) : (
                                  <p className="challenge-date">
                                    Регистрация закончена
                                  </p>
                              )}
                            </div>
                          </li>)}
                    </ul>
                ) : (<></>)}
                <p className="btn" id="personal" style={{display: challengeList && challengeList.length > 0 ? "none" : ""}}>
                  <p className="btn-heading kubok" onClick={() => window.location.href="/challenge?tab=current"} style={{cursor: "pointer"}}>
                    Все мероприятия
                  </p>
                  <p className="btn-description">
                    Посмотрите все мероприятия и выберите те, в которых хотите принять участие. <a href="https://disk.yandex.ru/i/1jfZNFK_nw8yfg" target="_blank" className="active-link">Видеоинструкция</a>
                  </p>
                </p>
                <button className="btn-exit" onClick={handleExit}>Выход из профиля</button>
                {/*<a href="" className="link-no" onClick={() => alert("Это мы еще не обсудили. Хе-хе :)")}>Удалить аккаунт</a>*/}
              </div>
            </div>
          </main>
        </div>


        <div className="right-block" id="right-block" style={{right: "-100vw"}}>
          <header className="header">
            <p className="header-back" id="backmenu" onClick={handleCloseDetails}>Назад</p>
            <p className="name-menu">Личные данные</p>
          </header>
          <ProfileIndividualDetailsEditor currentForm={form} userId={currentUserId}/>
        </div>
        <div className="right-block" id="right-block-team-member" style={{right: "-100vw"}}>
          <header className="header">
            <p className="header-back" onClick={hideTeamMember} id="backmenu">Назад</p>
            <p className="name-menu">Предпросмотр</p>
          </header>
          <ProfileIndividualView
              currentForm={form}
              team={undefined} enableRecruitmentPanel={false}/>
        </div>
      </div>
  )
}