import React, {useEffect, useState} from "react";
import {Help} from "../../common/Help";
import {ProfileIndividualHeader} from "../profile/ProfileIndividualHeader";
import {NavigationIndividualMain} from "../navigation/NavigationIndividualMain";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {ModalMessage} from "../../common/ModalMessage";
import {IPaginationResponse} from "../../../types/IPaginationResponse";
import {IMentoredChallenge} from "../../../types/mentor/IMentoredChallenge";
import {CONFIG} from "../../../../CONFIG";
import plural from "plural-ru";
import InfiniteScroll from "react-infinite-scroll-component";
import {IMentoredProfile} from "../../../types/mentor/IMentoredProfile";

export const Mentor = () => {

    const [error, setError] = useState("");
    const [page, setPage] = useState(0);
    const [challengesResponse, setChallengesResponse] = useState<IPaginationResponse<IMentoredChallenge>>();
    const [mentoredProfiles, setMentoredProfiles] = useState<IMentoredProfile[] | undefined>();
    const [currentChallenge, setCurrentChallenge] = useState<IMentoredChallenge | undefined>();
    const [unloading, setUnloading] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (page === 0) {
            setChallengesResponse({
                items: [],
                total: 0,
                totalPages: 0
            });
            loadChallenges();
        }
    }, [page]);

    const loadChallenges = () => {
        ChallengeAPI.getMentorChallenges(page, search, (response) => {
            setPage(page + 1);
            if (response.status === 200) {
                console.log(page);
                const currentItems = page === 0 || !challengesResponse?.items ? new Array<IMentoredChallenge>() : challengesResponse?.items;
                setChallengesResponse({
                    ...challengesResponse,
                    items: currentItems.concat(response.data.items),
                    total: response.data.total,
                    rawTotal: response.data.rawTotal,
                    totalPages: response.data.totalPages
                })
            } else {
                setError("Произошла ошибка, попробуйте зайти позже");
            }
        });
    }

    const showDetails = (challengeId: number) => {
        ChallengeAPI.getMentorChallenge(challengeId, (response) => {
            if (response.status === 200) {
                setMentoredProfiles(response.data);
                setCurrentChallenge(challengesResponse?.items.filter(i => i.id === challengeId)[0]);
            } else {
                setError("Произошла ошибка, попробуйте зайти позже");
            }
        })
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setPage(0);
        }
    }

    const closeDetails = () => {
        setMentoredProfiles(undefined);
        setCurrentChallenge(undefined);
    }

    const unload = (challengeId: number) => {
        setUnloading(true);
        ChallengeAPI.unloadMentorChallenge(challengeId, (response) => {
            if (response.status === 200) {
                setUnloading(false);
            } else {
                setUnloading(false);
                setError("Ошибка выгрузки участников");
            }
        });
    }

    return (
        <div className="platform-background">
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <Help challenge={undefined}/>
            <img src="/images/main.jpg" className="image-back-video" alt=""/>
            <div className="left-block">
                <ProfileIndividualHeader newPreview={""}/>
                <main className="content">
                    <NavigationIndividualMain/>
                    <div className="main-list-center">
                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Мои ученики</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a className="link-nav-center-item">По мероприятиям</a>
                                </li>
                            </ul>
                        </div>
                        <ul className="challenge-info" id="scrollableList">
                            <input type="text" onKeyDown={handleKeyDown} onChange={(e) => setSearch(e.target.value)} placeholder="Поиск" className="input-challenges"/>
                            <li className="first-message">
                                <p className="city">
                                    В этом разделе отображаются мероприятия, участник(и) которых выбрали вас своим наставником
                                </p>
                            </li>
                            <li className="first-message">
                                <p className="fio-or-name">
                                    <span className="non-active-students">Статистика</span>
                                </p>
                                <div className="stat-students-wrap">
                                    <div className="stat-students-info">
                                        <h5 className="stat-students-heading">
                                            Всего учеников
                                        </h5>
                                        <p className="stat-students-number">
                                            {challengesResponse?.rawTotal || 0}
                                        </p>
                                    </div>
                                    {/*<div className="stat-students-info">
                      <h5 className="stat-students-heading">
                        Разрешили просмотр
                      </h5>
                      <p className="stat-students-number">
                        {total}
                      </p>
                    </div>*/}
                                </div>
                                {/*<div className="stat-students-noview">
                    <p className="stat-students-noview-num">{ ((rawTotal === 0 ? 0 : (100 / rawTotal * total))).toFixed(1)}%</p>
                    <p className="stat-students-noview-text">
                      Участников из вашей организации, разрешивших просмотр их данных</p>
                  </div>*/}
                            </li>
                            <InfiniteScroll next={loadChallenges} hasMore={page < (challengesResponse ? challengesResponse.totalPages : 0)} loader={<h4>Загрузка...</h4>} dataLength={challengesResponse?.items.length || 0} scrollableTarget="scrollableList" className="challenge-info">
                            {challengesResponse?.items.map((i, key) =>
                                <li className="challenge-card">
                                    <div className="challenge-card-main" onClick={() => showDetails(i.id)}>
                                        <img id={"challenge-avatar-" + i.id} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_challenge.svg"}}
                                             src={CONFIG.STORAGE_URL + "/" + i.userId + "/challenge/" + i.id + "/avatar/" + i.avatar} alt=""
                                             className="challenge-avatar"/>
                                        <div className="user-info">
                                            <p className="challenge-header">{i.name}
                                            </p>
                                            <p className="challenge-company">{i.orgName ? i.orgName : ""}</p>
                                        </div>
                                    </div>
                                    <p className="challenge-description">
                                        {i.description}
                                    </p>
                                    <div className="challenge-information">
                                        <button onClick={() => showDetails(i.id)} className="challenge-landing">
                                            Просмотр
                                        </button>
                                        <button onClick={() => unload(i.id)} className="challenge-landing" disabled={unloading}>
                                            {unloading ? "Подождите..." : "Выгрузка"}
                                        </button>
                                        <p className="text-blue">
                                            {plural(i.num, "%d человек", "%d человека", "%d человек")}
                                        </p>
                                    </div>
                                </li>
                            )}
                            </InfiniteScroll>
                        </ul>
                    </div>
                </main>
            </div>

            {
                mentoredProfiles ?
                    <div className="right-block">
                        <header className="header">
                            <p className="header-back" id="backmenu" onClick={closeDetails}>Назад</p>
                            <p className="name-menu">Подаваны в конкурсе</p>
                        </header>
                        <div className="container-right-block">
                            <div className="menu-challenge">
                                <h2 className="headline-right-block">{currentChallenge?.name}</h2>
                                <ul className="challenge-info">
                                    {mentoredProfiles.map((p, index) =>
                                        <li className="respose-item">
                                            <div className="challenge-card">
                                                <div className="challenge-card-mini">
                                                    <img src={`${CONFIG.STORAGE_URL}/${p.userId}/avatar/${p.avatar}`}
                                                         onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                                         alt=""
                                                         className="challenge-avatar"/>
                                                    <div className="user-info">
                                                        <p className="challenge-header">{`${p.lastname} ${p.firstname}`}</p>
                                                        <p className="city-student" style={{marginLeft: 0}}>{p.city}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="challenge-information">
                                                {p.telegram ?
                                                    <p className="message-user">
                                                        <a href={`https://t.me/${p.telegram}`} target="_blank">Сообщение</a>
                                                    </p>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}