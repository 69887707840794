import React from "react";

import {BrowserRouter as Router, useRoutes} from 'react-router-dom';
import {Signup} from "./components/Signup";
import {Confirm} from "./components/Confirm";
import {Reset} from "./components/Reset";
import {Profile} from "./components/profile/common/Profile";
import {SignIn} from "./components/SignIn";
import {ChallengeTab} from "./components/profile/admin/challenge/tabs/ChallengeTab";
import {ChallengeList} from "./components/profile/common/ChallengeList";
import {ChallengeMy} from "./components/profile/personal/challenge/ChallengeMy";
import {NotFound} from "./components/NotFound";
import {University} from "./components/profile/common/University";
import {Unloads} from "./components/profile/common/Unloads";
import {UserList} from "./components/profile/admin/UserList";
import {TeamList} from "./components/profile/admin/TeamList";
import {AdList} from "./components/profile/admin/AdList";
import {StageResult} from "./components/profile/admin/challenge/StageResult";
import {FullSignup} from "./components/FullSignup";
import {UniversityStatistics} from "./components/profile/university/UniversityStatistics";
import {Certificate} from "./components/profile/common/certificate/Certificate";
import {CertificateView} from "./components/profile/common/certificate/CertificateView";
import {UserCertificates} from "./components/profile/personal/achivements/UserCertificates";
import {TestList} from "./components/profile/admin/testing/TestList";
import {Plan} from "./components/profile/common/payment/Plan";
import {PaymentResult} from "./components/profile/common/payment/PaymentResult";
import {BillTemplate} from "./components/profile/common/payment/BillTemplate";
import {BillList} from "./components/profile/admin/challenge/BillList";
import {PublicStageResult} from "./components/profile/common/PublicStageResult";
import {BusinessList} from "./components/profile/admin/BusinessList";
import {CTFDashboard} from "./components/ctf/CTFDashboard";
import {AssessmentTab} from "./components/profile/admin/challenge/assessment/AssessmentTab";
import {Mentor} from "./components/profile/personal/mentor/Mentor";
import {RoleHome} from "./components/profile/common/subs/RoleHome";
import {UserListChallenge} from "./components/profile/admin/UserListChallenge";

const App = () => {
  return useRoutes([
    {path: "/", element: <SignIn/>},
    {path: "/edu/signup", element: <Signup type={"EDU"}/>},
    {path: "/personal/signup", element: <Signup type={"PERSONAL"}/>},
    {path: "/bsn/signup", element: <Signup type={"BSN"}/>},
    {path: "/signup", element: <FullSignup/>},
    {path: "/confirmation/:userId/:confirmationCode", element: <Confirm/>},
    {path: "/:type/reset", element: <Reset/>},
    {path: "/signin", element: <SignIn/>},
    {path: "/profile/certificates", element: <UserCertificates/>},
    {path: "/profile", element: <Profile/>},
    {path: "/profile/university/:id", element: <Profile/>},
    {path: "/university/statistics", element: <UniversityStatistics/>},
    {path: "/university", element: <University/>},
    {path: "/unloads", element: <Unloads/>},
    {path: "/users", element: <UserList/>},
    {path: "/challenge", element: <ChallengeList/>},
    {path: "/challenge/:id", element: <ChallengeTab/>},
    {path: "/challenge/:id/teams", element: <TeamList/>},
    {path: "/challenge/:id/users", element: <UserListChallenge/>},
    {path: "/challenge/:id/ads", element: <AdList/>},
    {path: "/challenge/:id/stage/:stageId/results", element: <StageResult/>},
    {path: "/challenge/:id/my/:tab", element: <ChallengeMy/>},
    {path: "/challenge/:id/my", element: <ChallengeMy/>},
    {path: "/certificates", element: <Certificate/>},
    {path: "/certificates/:id", element: <CertificateView/>},
    {path: "/test", element: <TestList/>},
    {path: "/plan", element: <Plan/>},
    {path: "/payment/:result", element: <PaymentResult/>},
    {path: "/payment/bill/:id", element: <BillTemplate/>},
    {path: "/payment/bills/list", element: <BillList/>},
    {path: "/challenge/:id/stage/:stageId/protocol/:protocolId", element: <PublicStageResult/>},
    {path: "/business", element: <BusinessList/>},
    {path: "*", element: <NotFound/>},
    {path: "/404", element: <NotFound/>},
    {path: "/challenge/:id/stage/:stageId/dashboard", element: <CTFDashboard/>},
    {path: "/challenge/:id/stage/:stageId/assessment_settings", element: <AssessmentTab/>},
    {path: "/profile/mentor", element: <Mentor/>},
    {path: "/roles", element: <RoleHome/>},
  ]);
}

const AppWrapper = () => {
  return (
      <Router>
        <App />
      </Router>
  );
};

export default AppWrapper;