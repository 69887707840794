import React, {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import API from "../../../../requests/_axios";
import {IChallenge} from "../../../types/IChallenge";
import {toNumber} from "lodash";
import {IStageResult} from "../../../types/IStageResult";
import {IChallengeStage} from "../../../types/IChallengeStage";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {getMaxPoints, getPercentResult} from "../../../helpers/ChallengeHelper";
import {PublicStageResultSettings} from "./PublicStageResultSettings";
import {IPublicStageResult} from "../../../types/IPublicStageResult";
import {ModalMessage} from "../../common/ModalMessage";
import * as TestAPI from "../../../../requests/TestAPI";
import {StageResultCommentModal} from "./StageResultCommentModal";

export const StageResult = () => {

    const params = useParams();
    const [challenge, setChallenge] = useState<IChallenge>();
    const [stage, setStage] = useState<IChallengeStage>();
    const [stageResults, setStageResults] = useState<IStageResult[]>([]);
    const [stageResultsNZ, setStageResultsNZ] = useState<IStageResult[]>([]);
    const [tab, setTab] = useState("all");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [showSpinner, setShowSpinner] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [error, setError] = useState("");
    const [settings, setSettings] = useState<IPublicStageResult>(
        {
            challengeId: toNumber(params.id),
            stageId: toNumber(params.stageId),
            showPlace: true,
            showName: true,
            showUniversity: true,
            showCountry: true,
            showCity: true,
            showPoints: true,
            showPercent: true,
            showPass: true
        }
    );
    const [wasPublished, setWasPublished] = useState(false);
    const [maxPts, setMaxPts] = useState<number | undefined>(undefined);
    const [currentCommentIndex, setCurrentCommentIndex] = useState<number | undefined>();
    const [hasAssessment, setHasAssessment] = useState(false);

    useEffect(() => {
        setTab(searchParams.get("tab") || "all");
        fetchChallenge();
        fetchStage();
        fetchResults(searchParams.get("tab") || "all");
        fetchSettings();
        fetchJury();
    }, []);

    useEffect(() => {
        fetchSettings();
    }, [settingsVisible]);

    const fetchJury = () => {
        ChallengeAPI.getJuryList(toNumber(params.id), (response) => {
            if (response.status === 200) {
                response.data.forEach(j => {
                    let jStage = j.juryStages.filter(js => js.stageId === toNumber(params.stageId));
                    if (jStage && jStage.length > 0) {
                        setHasAssessment(true);
                    }
                })
            }
        })
    }

    const fetchSettings = () => {
        ChallengeAPI.getPublicResultSettings(toNumber(params.id), toNumber(params.stageId), (response) => {
            if (response.status === 200) {
                setSettings(response.data);
            } else if (response.status !== 404) {
                setError("Ошибка при получении настроек протокола");
            }
        })
    }

    const fetchChallenge = () => {
        API.get("/challenge/api/v1/challenge/" + params.id).then((response) => {
            setChallenge(response.data);
        })
    }

    const fetchStage = () => {
        API.get(`/challenge/api/v1/challenge/${params.id}/stage/${params.stageId}`).then((response) => {
            if (response.status === 200)
                setStage(response.data);
            if (response.data.type === "TEST") {
                TestAPI.getInfo(response.data.taskLink, (response) => {
                    setMaxPts(response.data.maxPoints);
                });
            } else if (response.data.type === "ISPTEST") {
                setMaxPts(100);
            }
        });
    }

    const handleUnload = (stageId: number) => {
        setShowSpinner(true);
        API.get(`/challenge/api/v1/challenge/${params.id}/stage/${params.stageId}/xls`).then((response) => {
            if(response.status === 200) {
                let name = challenge?.alias ? challenge.alias : challenge?.id;
                const a = document.createElement('a');
                a.download = `${new Date().toISOString().split('T')[0]}_${name}_stage_${params.stageId}.xlsx`;
                a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
                a.click();
                setShowSpinner(false);
            }
        });
    }

    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList) return;
        const formData = new FormData();
        setShowSpinner(true);
        formData.append("file", fileList[0], fileList[0].name);
        API.post(`/challenge/api/v1/challenge/${params.id}/stage/${params.stageId}/xls`, formData).then((response) => {
            if(response.status === 200) {
                window.location.reload();
            }
            setShowSpinner(false);
        });
    }

    const fetchResults = (tab: string) => {
        setShowSpinner(true);
        API.get(`/challenge/api/v1/challenge/${params.id}/stage/${params.stageId}/results?filter=${tab}&search=${searchQuery}`).then((response) => {
            if (response.status === 200) {
                setStageResults(response.data);
            }
            if (response.data.length > 0) {
                response.data[0].wasPublished ? setWasPublished(true) : setWasPublished(false);
            }
            setShowSpinner(false);
        })
    }

    useEffect(() => {
        setStageResultsNZ(stageResults.filter(sr => sr.place && sr.place > 0));
    }, [stageResults]);

    const updateStageResults = (id: number, approvement: boolean) => {
        API.post(`/challenge/api/v1/challenge/${params.id}/stage/${params.stageId}/result/${id}/preapprovement`).then((response) => {
            if (response.status === 200) {
                const newState = stageResults.map((obj : IStageResult) => {
                    if (obj.id === id) {
                        return {
                            id: id,
                            name: obj.name,
                            universityName: obj.universityName,
                            country: obj.country,
                            city: obj.city,
                            points: obj.points,
                            preApprovement: approvement,
                            comment: obj.comment,
                            place: obj.place,
                            timeSpent: obj.timeSpent
                        };
                    }
                    return obj;
                });

                setStageResults(newState);
            }
        });
    };

    const handleSubmitResults = () => {
        if (new Date(stage?.endDate?.substr(0, 10) || "") > new Date() && !confirm("Этап еще не завершен. При подтверждении действия все пользователи, не успевшие выполнить задание, выбудут из мероприятия. Вы действительно желаете продолжить?")) {
            return;
        }
        if (confirm("Вы действительно желаете опубликовать результаты? Действие необратимо")) {
            setShowSpinner(true);
            API.post(`/challenge/api/v1/challenge/${params.id}/stage/${params.stageId}/results`, stageResults).then((response) => {
                if (response.status === 200)
                    window.location.reload();
                else {
                    setShowSpinner(false);
                    setError("Ошибка при публикации результатов");
                }
            })
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setStageResults([]);
            fetchResults(tab);
        }
    }

    const refreshResults = () => {
        if (params && params.id && params.stageId) {
            if (!confirm("Внимание!!! После обновления результатов все отметки \"Да\" и \"Нет\" сбросятся до значений по умолчанию. Вы точно уверены, что хотите обновить протокол?"))
                return;
            setShowSpinner(true);
            if (stage?.type === "CTF") {
                ChallengeAPI.updatePublicProtocol(toNumber(params.id), toNumber(params.stageId), (response) => {
                   if (response.status === 200)
                       window.location.reload();
                   setShowSpinner(false);
                });
            } else {
                ChallengeAPI.refreshTestResults(toNumber(params.id), toNumber(params.stageId), (response) => {
                    if (response.status === 200)
                        window.location.reload();
                    setShowSpinner(false);
                });
            }
        }
    }

    const downloadTestDetails = (csrId: number, name: string) => {
        if (confirm("Вы действительно желаете выгрузить результаты теста для данного пользователя?")) {
            ChallengeAPI.getTestDetails(toNumber(params.id), toNumber(params.stageId), csrId, (response) => {
                if (response.status === 200) {
                    var a = document.createElement('a');
                    a.download = `${name.replace(" ", "_")}.xlsx`;
                    a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
                    a.click();
                } else {
                    setError("Ошибка при выгрузке результата");
                }
            })
        }
    }

    const resetTest = (csrId: number) => {
        if (!confirm("Вы действительно желаете УДАЛИТЬ результаты теста для данного пользователя?")) return;
        ChallengeAPI.resetTest(toNumber(params.id), toNumber(params.stageId), csrId, (response) => {
           if (response.status === 200) {
               window.location.reload();
           } else {
               setError("Ошибка при удалении результата");
           }
        });
    }

    const redeemAssessment = () => {
        setShowSpinner(true);
        ChallengeAPI.redeemAssessment(toNumber(params.id), toNumber(params.stageId), (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                console.log(response.data);
                setError(response.data.message);
            }
            setShowSpinner(false);
        });
    }

    if (showSpinner) {
        return (
            <div className="load-block">
                <p className="load-sheet">
                    загрузка
                </p>
                <div className="circle-block">
                    <div className="circle-load one-load"></div>
                    <div className="circle-load two-load"></div>
                    <div className="circle-load three-load"></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="left-block-rating">
                <ModalMessage msg={error} header="Ошибка" _callback={() => setError("")}/>
                <header className="header-rating">
                    <div className="user-info-rating">
                        <p className="fio-or-name">{challenge?.name}: этап "{stage?.name}"</p>
                        {hasAssessment ?
                            <div className="btn-flex-rating">
                                <a onClick={redeemAssessment} className="btn rating-btn">
                                    Подтянуть результаты жюри
                                </a>
                            </div>
                            : <></>}
                        {stage?.type === "TASK" || stage?.type === "TASK_FILE" ? (
                            <div className="btn-flex-rating">
                                <label htmlFor="upload" className="btn rating-btn">
                                    Загрузить результаты
                                    <input type="file" accept=".xlsx" onChange={(e) => handleUpload(e)} name="" id="upload" className="hidden"/>
                                </label>
                                <a onClick={() => handleUnload(toNumber(params.stageId))} download className="btn rating-btn">
                                    Скачать результаты
                                </a>
                            </div>
                        ) : (
                            <div className="btn-flex-rating">
                                <a onClick={refreshResults} download className="btn rating-btn">
                                    Обновить результаты
                                </a>
                            </div>
                        )}
                    </div>

                    <ul className="nav-block-center">
                        <li className="nav-center-item">
                            <a href="?tab=all" className={tab === "all" && !searchParams.get("nz") ? "link-nav-center-item" : "link-nav-center-item lock-link"}>Все {tab === "all" ? " (" + stageResults.length + ")" : ""}</a>
                        </li>
                        <li className="nav-center-item">
                            <a href="?tab=all&nz=true" className={tab === "all" && searchParams.get("nz") ? "link-nav-center-item" : "link-nav-center-item lock-link"}>С ненулевыми местами {tab === "all" ? " (" + stageResultsNZ.length + ")" : ""}</a>
                        </li>
                        <li className="nav-center-item">
                            <a href="?tab=others" className={tab === "others" ? "link-nav-center-item" : "link-nav-center-item lock-link"}>Неподтвержденные {tab === "others" ? " (" + stageResults.length + ")" : ""}</a>
                        </li>
                    </ul>
                </header>

                <main className="content-rating">

                    <div className="main-list-center-rating">

                        <div className="button-add fixed-position" id="print-settings" onClick={() => setSettingsVisible(!settingsVisible)}>Настройки протокола</div>

                        <PublicStageResultSettings wasPublished={wasPublished} currentSettings={settings} visibility={settingsVisible} setVisibility={setSettingsVisible}/>
                        <StageResultCommentModal currentIndex={currentCommentIndex} setCurrentIndex={setCurrentCommentIndex} stageResults={stageResults} setStageResults={setStageResults}/>

                        <div className="rating-info">
                            <input type="search" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchQuery(e.target.value);
                            }} onKeyDown={handleKeyDown} placeholder="Введите имя, вуз, город, страну" className="input-challenges"/>

                            <p className="fio-or-name">
                                <span className="max-bal-rating"></span>
                                {stage?.type === "TEST" ? maxPts : stage?.type === "CTF" ? getMaxPoints(stage?.ctfTasks) : getMaxPoints(stage?.criteriaList) }
                            </p>

                            {stageResults.length === 0 ? (
                                <div className="first-message-error">
                                    <p className="fio-or-name">
                                        Поиск не дал результатов
                                    </p>
                                    <p className="city">
                                        Введите реальные вакансии, которые существуют или заявлены
                                        в рамках описания и правил конкурса
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div className="rating-row-header">
                                        <p className="rating-cell-header position">
                                            Место
                                        </p>
                                        <p className="rating-cell-header fio-rating">
                                            Команда / ФИО
                                        </p>
                                        <p className="rating-cell-header university">
                                            Организация
                                        </p>
                                        <p className="rating-cell-header country">
                                            Страна
                                        </p>
                                        <p className="rating-cell-header city-rating">
                                            Город
                                        </p>
                                        <p className="rating-cell-header point">
                                            Балл
                                        </p>
                                        <p className="rating-cell-header percent">
                                            Проценты
                                        </p>
                                        {stage?.type === "TEST" || stage?.type === "ISPTEST" ?
                                            <p className="rating-cell-header point">
                                                Время
                                            </p> : <></>}
                                        <div className="rating-cell-header yes-no-header">
                                            Допуск
                                        </div>
                                        {stage?.type === "TASK" || stage?.type === "TASK_FILE" ?
                                            <p className="rating-cell-header point">
                                                Коммент
                                            </p> : <></>}
                                    </div>

                                    {searchParams.get("nz") ?
                                        stageResultsNZ && stageResultsNZ.map((sr : IStageResult, index) =>
                                            <div key={index} className="rating-row">
                                                <p className="rating-cell position">
                                                    {sr.place}
                                                </p>
                                                <p className="rating-cell fio-rating">
                                                    {sr.name}
                                                </p>
                                                <p className="rating-cell university">
                                                    {sr.universityName}
                                                </p>
                                                <p className="rating-cell country">
                                                    {sr.country}
                                                </p>
                                                <p className="rating-cell city-rating">
                                                    {sr.city}
                                                </p>
                                                <p className="rating-cell point">
                                                    {stage?.type === "TEST" && challenge?.challengeType === "INDIVIDUAL" && sr.id ?
                                                        <>
                                                            <a onClick={() => downloadTestDetails(sr.id, sr.name + "_" + sr.city + "_" + stage?.name || "noname")} className="active-link">{sr.points}</a>
                                                            <a onClick={() => resetTest(sr.id)} style={{"color": "red", "cursor": "pointer"}}> [X]</a>
                                                        </>
                                                        :
                                                        sr.points
                                                    }
                                                </p>
                                                <p className="rating-cell percent">
                                                    {getPercentResult(stage?.type === "CTF" ? stage.ctfTasks : stage?.criteriaList, sr.points || 0, maxPts)}
                                                </p>
                                                {stage?.type === "TEST" || stage?.type === "ISPTEST" ?
                                                    <p className="rating-cell point">
                                                        {sr.timeSpent}
                                                    </p> : <></>}
                                                <div className="rating-cell yes-no">
                                                    <label htmlFor={`aga-${index}`} className="yes-no-label">
                                                        <input type="radio" name={`yes-no-${index}`} id={`aga-${index}`} value="true" checked={sr.preApprovement} onChange={() => updateStageResults(sr.id, true)} disabled={tab === "others"}/>
                                                        <p className="text-label">
                                                            Да
                                                        </p>
                                                    </label>
                                                    <label htmlFor={`ne-ne-${index}`} className="yes-no-label">{!sr.preApprovement}
                                                        <input type="radio" name={`yes-no-${index}`} id={`ne-ne-${index}`} value="false" checked={!sr.preApprovement} onChange={() => updateStageResults(sr.id, false)} disabled={tab === "others"}/>
                                                        <p className="text-label">
                                                            Нет
                                                        </p>
                                                    </label>
                                                </div>
                                                {stage?.type === "TASK" || stage?.type === "TASK_FILE" ?
                                                    <p className="rating-cell point">
                                                        <a onClick={() => setCurrentCommentIndex(index)} className="active-link">{sr.comment ? "Изменить" : "Добавить"}</a>
                                                    </p> : <></>
                                                }
                                            </div>
                                        )


                                        :


                                    stageResults && stageResults.map((sr : IStageResult, index) =>
                                        <div key={index} className="rating-row">
                                        <p className="rating-cell position">
                                    {sr.place}
                                        </p>
                                        <p className="rating-cell fio-rating">
                                    {sr.name}
                                        </p>
                                        <p className="rating-cell university">
                                    {sr.universityName}
                                        </p>
                                        <p className="rating-cell country">
                                    {sr.country}
                                        </p>
                                        <p className="rating-cell city-rating">
                                    {sr.city}
                                        </p>
                                        <p className="rating-cell point">
                                    {stage?.type === "TEST" && challenge?.challengeType === "INDIVIDUAL" && sr.id ?
                                        <>
                                        <a onClick={() => downloadTestDetails(sr.id, sr.name + "_" + sr.city + "_" + stage?.name || "noname")} className="active-link">{sr.points}</a>
                                        <a onClick={() => resetTest(sr.id)} style={{"color": "red", "cursor": "pointer"}}> [X]</a>
                                        </>
                                        :
                                        sr.points
                                    }
                                        </p>
                                        <p className="rating-cell percent">
                                    {getPercentResult(stage?.type === "CTF" ? stage.ctfTasks : stage?.criteriaList, sr.points || 0, maxPts)}
                                        </p>
                                    {stage?.type === "TEST" || stage?.type === "ISPTEST" ?
                                        <p className="rating-cell point">
                                    {sr.timeSpent}
                                        </p> : <></>}
                                        <div className="rating-cell yes-no">
                                        <label htmlFor={`aga-${index}`} className="yes-no-label">
                                        <input type="radio" name={`yes-no-${index}`} id={`aga-${index}`} value="true" checked={sr.preApprovement} onChange={() => updateStageResults(sr.id, true)} disabled={tab === "others"}/>
                                        <p className="text-label">
                                        Да
                                        </p>
                                        </label>
                                        <label htmlFor={`ne-ne-${index}`} className="yes-no-label">{!sr.preApprovement}
                                        <input type="radio" name={`yes-no-${index}`} id={`ne-ne-${index}`} value="false" checked={!sr.preApprovement} onChange={() => updateStageResults(sr.id, false)} disabled={tab === "others"}/>
                                        <p className="text-label">
                                        Нет
                                        </p>
                                        </label>
                                        </div>
                                    {stage?.type === "TASK" || stage?.type === "TASK_FILE" ?
                                        <p className="rating-cell point">
                                        <a onClick={() => setCurrentCommentIndex(index)} className="active-link">{sr.comment ? "Изменить" : "Добавить"}</a>
                                        </p> : <></>
                                    }
                                        </div>
                                        )
                                    }


                                    <button className="btn-main rating-end" onClick={handleSubmitResults} style={{display: tab === "others" ? "none" : ""}}>{wasPublished ? "Переопубликовать результаты" : "Опубликовать результаты"}</button>
                                </>
                            )}
                        </div>
                    </div>
                </main>

            </div>
        );
    }
}