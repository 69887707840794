import React, {useEffect, useState} from "react";
import {CONFIG} from "../../../CONFIG";
import {IProfilePersonalForm} from "../../types/IProfilePersonalForm";
import {toNumber} from "lodash";
import {useParams, useSearchParams} from "react-router-dom";
import API from "../../../requests/_axios";
import SessionModel from "../../../stores/SessionModel";
import InfiniteScroll from "react-infinite-scroll-component";
import {ModalMessage} from "../common/ModalMessage";
import {OrgHeader} from "../common/OrgHeader";
import {OrgNavigation} from "../common/OrgNavigation";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";
import {ModalBanMessage} from "../common/ModalBanMessage";
import {IBanModal} from "../../types/IBanModal";
import {IBanRequest} from "../../types/IBanRequest";

export const UserListChallenge = () => {

    const [profiles, setProfiles] = useState([]);
    const [tab, setTab] = useState("all");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchInProgress, setSearchInProgress] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const [hasMore, setHasMore] = useState(true);
    const [searchTrigger, setSearchTrigger] = useState(true);
    const [error, setError] = useState("");
    const [banModal, setBanModal] = useState<IBanModal>();

    useEffect(() => {
        setTab(searchParams.get("tab") || "all");
        if (!SessionModel.isOrg() && !SessionModel.isAdmin()) {
            localStorage.getItem("token");
            window.location.href = "/";
        }
        setSearchQuery(searchParams.get("search") || searchQuery);
        setCurrentPage(currentPage || toNumber(searchParams.get("page")) || 0);
        fetchMoreData();
    }, [searchTrigger]);

    const fetchMoreData = () => {
        setCurrentPage(currentPage + 1);
        setSearchInProgress(true);
        API.get(`/challenge/api/v1/personal/profile/search?query=${encodeURIComponent(searchQuery)}&page=${currentPage}&filter=${searchParams.get("tab")}&challengeId=${params.id}`)
            .then((response) => {
                if(response.status === 200) {
                    setProfiles(profiles.concat(response.data.items));
                    setTotalItems(response.data.total);
                    if (response.data.totalPages <= currentPage) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                }
                setSearchInProgress(false);
            });
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setProfiles([]);
            setCurrentPage(0);
            setSearchTrigger(!searchTrigger);
        }
    }

    const ban = (userId: number) => {
        const profile : IProfilePersonalForm = profiles.filter((p: IProfilePersonalForm) => p.userId === userId)[0];
        if (profile) {
            if (profile.isBanned && profile.userId) {
                doBan(profile.userId, "");
            } else {
                setBanModal({...banModal, id: userId, title: "Блокировка пользователя", text: `Вы действительно желаете заблокировать пользователя ${profile.lastname} ${profile.firstname}?`});
            }
        }
    }

    const doBan = (userId: number, reason: string) => {
        const req : IBanRequest = {
            id: userId,
            reason: reason
        }
        ChallengeAPI.banChallengeUser(toNumber(params.id), req, (response) => {
            if (response.status === 200) {
                window.location.reload()
            } else {
                setError("Ошибка блокировки пользователя");
            }
        })
    }

    return (
        <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
                <source src={require("../../../../public/images/main_video.mp4").default}/>
            </video>
            <ModalBanMessage banDetails={banModal} _callbackCancel={() => setBanModal(undefined)} _callbackBan={doBan}/>
            <div className="left-block">
                <OrgHeader preview={null}/>
                <main className="content">
                    <OrgNavigation/>
                    <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                    <div className="main-list-center">
                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Пользователи</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href={`/challenge/${params.id}/users?tab=all`} className={tab === "all" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Все</a>
                                </li>
                                <li className="nav-center-item">
                                    <a href={`/challenge/${params.id}/users?tab=banned`} className={tab === "banned" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Заблокированные</a>
                                </li>
                            </ul>
                        </div>
                        <ul className="challenge-info" id="scrollableList">
                            <input type="text" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchQuery(e.target.value);
                            }} onKeyDown={handleKeyDown} placeholder="ФИО, Email или телефон" className="input-challenges"/>
                            <div className="first-message">Всего: {totalItems}</div>
                            {!searchInProgress && profiles.length === 0 ? (
                                <div className="first-message-error" style={{display: profiles.length === 0 ? "" : "none"}}>
                                    <p className="fio-or-name">
                                        Поиск не дал результатов
                                    </p>
                                </div>
                            ) : (<></>)}
                            <InfiniteScroll next={fetchMoreData} hasMore={hasMore} loader={<h4>Загрузка...</h4>} dataLength={profiles.length} scrollableTarget="scrollableList" className="challenge-info">
                            {profiles && profiles.map((p: IProfilePersonalForm, i) =>
                                <li key={i} className="challenge-card">
                                    <div className="challenge-card-main">
                                        <img src={CONFIG.STORAGE_URL + "/" + p.userId + "/avatar/" + p.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                             alt=""
                                             className="challenge-avatar"/>
                                        <div className="user-info">
                                            <p className="challenge-header">{p.firstname + " " + p.lastname}</p>
                                            <p>{p.username}</p>
                                            <p>{p.phone}</p>
                                        </div>
                                    </div>
                                    <div className="block-input">
                                        {p.isBanned ?
                                            <div className="block-input">
                                                <p className="legend-about">
                                                    Причина блокировки
                                                </p>
                                                <ul className="interests">
                                                    <li className="interest-item-comand" style={{backgroundColor: "rgba(208, 208, 255, 0.3)"}}>
                                                        {p.banReason}
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            <></>
                                        }
                                        {p.isBanned ? <button className="btn-main" onClick={() => ban(p.userId || 0)}>Разблокировать</button> : <button className="btn-no" onClick={() => ban(p.userId || 0)}>Заблокировать</button>}
                                    </div>
                                </li>
                            )}
                            </InfiniteScroll>
                        </ul>
                    </div>
                </main>
            </div>
        </div>
    );
}