import React, {useEffect, useState} from "react";
import {ProfileUniversityHeader} from "../../university/ProfileUniversityHeader";
import {NavigationUniversity} from "../../university/NavigationUniversity";
import {PlanList} from "./right/PlanList";
import {PlanHeader} from "./PlanHeader";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import * as CertificateAPI from "../../../../requests/CertificateAPI";
import * as TestAPI from "../../../../requests/TestAPI";
import {IChallengeUserPlan} from "../../../types/IChallengeUserPlan";
import {formatDate} from "../../../helpers/DateHelper";
import {IPlan} from "../../../types/IPlan";
import {Spinner} from "../Spinner";
import {ModalMessage} from "../ModalMessage";
import SessionModel from "../../../../stores/SessionModel";
import {ProfileBusinessHeader} from "../../business/ProfileBusinessHeader";
import {NavigationBusiness} from "../../business/NavigationBusiness";

export const PlanTab = () => {
    const [userChallengePlan, setUserChallengePlan] = useState<IChallengeUserPlan>();
    const [certificatePlan, setCertificatePlan] = useState<IPlan>();
    const [testPlan, setTestPlan] = useState<IPlan>();
    const [hasCertificatePlan, setHasCertificatePlan] = useState<boolean | null>(false);
    const [hasTestPlan, setHasTestPlan] = useState<boolean | null>(false);
    const [showPlanList, setShowPlanList] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        ChallengeAPI.getActivePlan((response) => {
           if (response.status === 200) {
               setUserChallengePlan(response.data);
           }
        });
        CertificateAPI.getPlans((response) => {
            if (response.status === 200) {
                setCertificatePlan(response.data[0]);
            }
        });
        getActiveCertificatePlan();
        TestAPI.getPlans((response) => {
            if (response.status === 200) {
                setTestPlan(response.data[0]);
            }
        });
        getActiveTestPlan();
    }, []);

    const getActiveTestPlan = () => {
        TestAPI.getActivePlan((response) => {
            if (response.status === 200) {
                setHasTestPlan(true);
            } else if (response.status === 403) {
                setHasTestPlan(false);
            } else {
                setHasTestPlan(null);
            }
        })
    }

    const getActiveCertificatePlan = () => {
        CertificateAPI.getActivePlan((response) => {
            if (response.status === 200) {
                setHasCertificatePlan(true);
            } else if (response.status === 403) {
                setHasCertificatePlan(false);
            } else {
                setHasCertificatePlan(null);
            }
        });
    }

    const addTestPlan = () => {
        if (testPlan && testPlan.id) {
            if (confirm("С вашего счета будет списана сумма, согласно тарифу")) {
                setShowSpinner(true);
                TestAPI.addPlan(testPlan.id, (response) => {
                    if (response.status === 200) {
                        window.location.reload();
                    } else {
                        if (response.data.code === "010-003") {
                            setError("На вашем счете недостаточно средств");
                        } else {
                            setError("Ошибка подключения тарифа");
                        }
                    }
                    setShowSpinner(false);
                })
            }
        }
    }

    const addCertificatePlan = () => {
        if (certificatePlan && certificatePlan.id) {
            if (confirm("С вашего счета будет списана сумма, согласно тарифу")) {
                setShowSpinner(true);
                CertificateAPI.addPlan(certificatePlan.id, (response) => {
                    if (response.status === 200) {
                        window.location.reload();
                    } else {
                        if (response.data.code === "010-003") {
                            setError("На вашем счете недостаточно средств");
                        } else {
                            setError("Ошибка подключения тарифа");
                        }
                    }
                    setShowSpinner(false);
                })
            }
        }
    }

return (
    <div className="platform-background">
        <Spinner text={"Подождите..."} enabled={showSpinner}/>
        <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
        <img src="/images/main.jpg" className="image-back-video" alt=""/>
        <div className="left-block">
            {SessionModel.isBsn() ? <ProfileBusinessHeader newPreview={null}/> : <ProfileUniversityHeader newPreview={null}/>}
            <main className="content">
                {SessionModel.isBsn() ? <NavigationBusiness/> : <NavigationUniversity />}
                <div className="main-list-center">
                    <PlanHeader/>
                    <div className="challenge-info">
                        {!SessionModel.isAdmin() ?
                            <div className="first-message">
                                <p className="city">
                                    <a href="https://disk.yandex.ru/d/h5h1ikVGh7PAsg" target="_blank" className="active-link">Видеоинструкции</a> по работе с сервисом "Тарифы"
                                </p>
                            </div>
                            :
                            <></>
                        }
                        {userChallengePlan ?
                            <div className="stage-button balance-green">
                                <h2 className="headline-tarif">
                                    Тариф «{userChallengePlan.plan.name}»
                                </h2>
                                <p className="pay-date-update">
                                    Оплачено до {formatDate(userChallengePlan.paidUntil)}
                                </p>
                                <div className="btn-info balance-green">
                                    <div className="tarif-flex-status">
                                        <h4 className="btn-info-heading-green">
                                            Активен
                                        </h4>
                                        <h4 className="btn-info-heading-money">
                                            {userChallengePlan.plan.price / 100}
                                        </h4>
                                    </div>
                                    <ul className="tarif-about">
                                        <li className="tarif-about-item">
                                            <p className="tarif-about-text">
                                                Количество участников одного мероприятия
                                            </p>
                                            <p className="tarif-about-num">{userChallengePlan.plan.persons === -1 ? "Безлимит" : userChallengePlan.plan.persons}</p>
                                        </li>
                                        <li className="tarif-about-item">
                                            <p className="tarif-about-text">
                                                Количество инициатив одновременно
                                            </p>
                                            <p className="tarif-about-num">{userChallengePlan.plan.challenges === -1 ? "Безлимит" : userChallengePlan.plan.challenges}</p>
                                        </li>
                                        <li className="tarif-about-item">
                                            <p className="tarif-about-text">
                                                Обязательная принадлежность к организации
                                            </p>
                                            <p className="tarif-about-num">{userChallengePlan.plan.employeesOnly ? "Да" : "Нет"}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="flex-button-pay">
                                    <a className="btn-tarif-edit" onClick={() => setShowPlanList(true)}>Изменить тариф</a>
                                </div>
                            </div>
                        :
                            <div className="stage-button balance-red">
                                <div className="btn-info-red" onClick={() => setShowPlanList(true)}>
                                    <h4 className="btn-info-heading">
                                        Подключить тариф
                                    </h4>
                                    <p className="btn-info-text" onClick={() => setShowPlanList(true)}>
                                        Нажмите чтобы выбрать один из имеющихся тарифов
                                    </p>
                                </div>
                            </div>
                        }


                        <h2 className="headline-additional-service">
                            Дополнительные сервисы
                        </h2>

                        {hasCertificatePlan !== null ?
                            <div className={hasCertificatePlan ? "stage-button balance-green" : "stage-button"} style={{cursor: "default"}}>
                                <h5 className="stage-card-heading">
                                    Сервис сертификатов
                                </h5>
                                <div className={hasCertificatePlan ? "btn-info balance-green" : "btn-info"}>
                                    <h4 className="btn-info-heading-money">
                                        10000
                                    </h4>
                                    <p className="btn-info-text">
                                        Сервис позволяет создавать сертификаты и дипломы с отображением их в личных кабинетах участников. <i>ВНИМАНИЕ, АКЦИЯ!</i> Данный сервис подключается всем образовательным организациям и НКО бесплатно. Для этого обратитесь в техподдержку платформы.
                                    </p>
                                    {hasCertificatePlan ?
                                        <p className="pay-service-info-ok">
                                            Подключено
                                        </p>
                                        :
                                        <p className="pay-service-info">
                                            Единоразовая оплата
                                        </p>
                                    }
                                </div>
                                {!hasCertificatePlan ?
                                    <div className="flex-button-additional-service">
                                        <a className="btn-pay-invoice" style={{cursor: "pointer"}} onClick={() => addCertificatePlan()}>Подключить</a>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            :
                            <>
                                <div className="stage-button" style={{cursor: "default"}}>
                                    <h5 className="stage-card-heading">
                                        Сервис сертификатов
                                    </h5>
                                    <div className="btn-info">
                                        <p className="pay-service-info">
                                            Ошибка получения данных
                                        </p>
                                    </div>
                                </div>
                            </>
                        }

                        {hasTestPlan !== null ?
                            <div className={hasTestPlan ? "stage-button balance-green" : "stage-button"} style={{cursor: "default"}}>
                                <h5 className="stage-card-heading">
                                    Сервис тестирования
                                </h5>
                                <div className={hasTestPlan ? "btn-info balance-green" : "btn-info"}>
                                    <h4 className="btn-info-heading-money">
                                        10000
                                    </h4>
                                    <p className="btn-info-text">
                                        Сервис позволяет создавать тесты любой сложности, которые можно подключать к этапам мероприятий. <i>ВНИМАНИЕ, АКЦИЯ!</i> Данный сервис подключается всем образовательным организациям и НКО бесплатно. Для этого обратитесь в техподдержку платформы.
                                    </p>
                                    {hasTestPlan ?
                                        <p className="pay-service-info-ok">
                                            Подключено
                                        </p>
                                        :
                                        <p className="pay-service-info">
                                            Единоразовая оплата
                                        </p>
                                    }
                                </div>
                                {!hasTestPlan ?
                                    <div className="flex-button-additional-service">
                                        <a className="btn-pay-invoice" style={{cursor: "pointer"}} onClick={() => addTestPlan()}>Подключить</a>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            :
                            <>
                                <div className="stage-button" style={{cursor: "default"}}>
                                    <h5 className="stage-card-heading">
                                        Сервис тестирования
                                    </h5>
                                    <div className="btn-info">
                                        <p className="pay-service-info">
                                            Ошибка получения данных
                                        </p>
                                    </div>
                                </div>
                            </>
                        }

                        {/*<div className="stage-button">
                            <h5 className="stage-card-heading">
                                Сервис анкетирования
                            </h5>
                            <div className="btn-info">
                                <h4 className="btn-info-heading-money">
                                    10000
                                </h4>
                                <p className="btn-info-text">
                                    Создание анкет, привязка в этапы соревнования
                                    или к другому основному сервису
                                </p>
                                <p className="pay-service-info">
                                    Единоразовая оплата
                                </p>
                            </div>
                            <div className="flex-button-additional-service">
                                <a className="btn-pay-invoice">Подключить</a>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </main>
        </div>
        <PlanList currentPlan={userChallengePlan?.plan} visible={showPlanList} setVisible={setShowPlanList}/>
    </div>
);
}