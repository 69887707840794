import React from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {formatDate} from "../../../../helpers/DateHelper";
import {toNumber} from "lodash";
import {useParams} from "react-router-dom";
import {StageButtonAdminVideoAdmin} from "./StageButtonVideoAdmin";

export const StageButtonAdmin: React.FC<{
  stage: IChallengeStage,
  showStage: (id: number) => void,
  deleteStage: (id: number) => void}> = ({stage, showStage, deleteStage}) => {

  const params = useParams();

  if (stage.type === "VIDEO") {
    return (<StageButtonAdminVideoAdmin stage={stage} showStage={showStage} deleteStage={deleteStage}/>)
  } else if (stage.type === "INFO") {
    return (
        <div className="stage-card-new-work">
          <div className="stage-card-new-info">
            <div className="stage-card-info">
              <h5 className="stage-card-heading">Инфо</h5>

            </div>
            <div className="graph">
              <p className="stage-card-new-heading" onClick={() => showStage(toNumber(stage.id))}>
                {stage.name}
              </p>
              <div className="stage-card-new-gap-work" onClick={() => showStage(toNumber(stage.id))}>
                <p className="stage-card-new-date">
                  {formatDate(stage.startDate)}
                </p>
                <p className="stage-card-new-date">
                  -
                </p>
                <p className="stage-card-new-date">
                  {formatDate(stage.endDate)}
                </p>
              </div>
              <p className="stage-card-new-text">
                {stage.description || "Нет описания"}
              </p>
            </div>
          </div>
          <div className="stage-card-new-wrapper-link">
            <a onClick={() => deleteStage(toNumber(stage.id))} className="card-standart-link-false">
              Удалить
            </a>
          </div>
        </div>
    )
  }
  else if (stage.endDate && stage.startDate) {
    // past
    if (new Date() > new Date(stage.endDate + "+03:00" || "")) {
      return (
          <div className="stage-card-new">
            <div className="stage-card-new-info">
              <div className="stage-card-info">
                <h5 className="stage-card-heading">Статус</h5>
                <p className="stage-card-done">
                  Завершено
                </p>
              </div>
              <div className="graph">
                <p className="stage-card-new-heading" onClick={() => showStage(toNumber(stage.id))}>
                  {stage.name}
                </p>
                <div className="stage-card-new-gap-work" onClick={() => showStage(toNumber(stage.id))}>
                  <p className="stage-card-new-date">
                    {formatDate(stage.startDate)}
                  </p>
                  <p className="stage-card-new-date">
                    -
                  </p>
                  <p className="stage-card-new-date">
                    {formatDate(stage.endDate)}
                  </p>
                </div>
                <p className="stage-card-new-text">
                  {stage.description || "Нет описания"}
                </p>
              </div>
            </div>
            <div className="stage-card-new-wrapper-link">
              {stage.type === "CTF" || stage.type === "TEST" || stage.type === "ISPTEST" || stage.type === "PROFLINE" ?
                  <></>
                  :
                  <a href={`/challenge/${params.id}/stage/${stage.id}/assessment_settings`} className="card-standart-link">
                    Допуск
                  </a>
              }
              <a href={`/challenge/${params.id}/stage/${stage.id}/results`} target="_blank" className="card-standart-link">
                Протокол этапа
              </a>
              {
                stage.type === "CTF" ?
                    <a href={`/challenge/${params.id}/stage/${stage.id}/dashboard`} target="_blank" className="card-standart-link">
                      Дашборд
                    </a>
                    :
                    <></>
              }
              <a onClick={() => deleteStage(toNumber(stage.id))} className="card-standart-link-false">
                Удалить
              </a>
            </div>
          </div>
      )
    }
    // current
    else if (new Date(stage.startDate + "+03:00" || "") <= new Date() && new Date() < new Date(stage.endDate + "+03:00" || "")) {
      return (
          <div className="stage-card-new-work">
            <div className="stage-card-new-info">
              <div className="stage-card-info">
                <h5 className="stage-card-heading">Статус</h5>
                <p className="stage-card-active">
                  Текущее
                </p>
              </div>
              <div className="graph">
                <p className="stage-card-new-heading" onClick={() => showStage(toNumber(stage.id))}>
                  {stage.name}
                </p>
                <div className="stage-card-new-gap-work" onClick={() => showStage(toNumber(stage.id))}>
                  <p className="stage-card-new-date">
                    {formatDate(stage.startDate)}
                  </p>
                  <p className="stage-card-new-date">
                    -
                  </p>
                  <p className="stage-card-new-date">
                    {formatDate(stage.endDate)}
                  </p>
                </div>
                <p className="stage-card-new-text">
                  {stage.description || "Нет описания"}
                </p>
              </div>
            </div>
            <div className="stage-card-new-wrapper-link">
              {stage.type === "CTF" || stage.type === "TEST" || stage.type === "ISPTEST" || stage.type === "PROFLINE" ?
                  <></>
                  :
                  <a href={`/challenge/${params.id}/stage/${stage.id}/assessment_settings`} className="card-standart-link">
                    Допуск
                  </a>
              }
              <a href={`/challenge/${params.id}/stage/${stage.id}/results`} target="_blank" className="card-standart-link">
                Протокол этапа
              </a>
              {
                stage.type === "CTF" ?
                    <a href={`/challenge/${params.id}/stage/${stage.id}/dashboard`} target="_blank" className="card-standart-link">
                      Дашборд
                    </a>
                    :
                    <></>
              }
              <a onClick={() => deleteStage(toNumber(stage.id))} className="card-standart-link-false">
                Удалить
              </a>
            </div>
          </div>
      )
    }
    // future
    else {
      return (
          <div className="stage-card-new-work">
            <div className="stage-card-new-info">
              <div className="stage-card-info">
                <h5 className="stage-card-heading">Статус</h5>
                <p className="stage-card-date">
                  Будущее
                </p>
              </div>
              <div className="graph" onClick={() => showStage(toNumber(stage.id))}>
                <p className="stage-card-new-heading">
                  {stage.name}
                </p>
                <div className="stage-card-new-gap-work" onClick={() => showStage(toNumber(stage.id))}>
                  <p className="stage-card-new-date">
                    {formatDate(stage.startDate)}
                  </p>
                  <p className="stage-card-new-date">
                    -
                  </p>
                  <p className="stage-card-new-date">
                    {formatDate(stage.endDate)}
                  </p>
                </div>
                <p className="stage-card-new-text">
                  {stage.description || "Нет описания"}
                </p>
              </div>
            </div>
            <div className="stage-card-new-wrapper-link">
              {stage.type === "CTF" || stage.type === "TEST" || stage.type === "ISPTEST" || stage.type === "PROFLINE" ?
                <></>
                  :
                <a href={`/challenge/${params.id}/stage/${stage.id}/assessment_settings`} className="card-standart-link">
                  Допуск
                </a>
              }
              {
                stage.type === "CTF" ?
                    <a href={`/challenge/${params.id}/stage/${stage.id}/dashboard`} target="_blank" className="card-standart-link">
                      Дашборд
                    </a>
                    :
                    <></>
              }
              <a className="card-standart-link-false" onClick={() => deleteStage(toNumber(stage.id))}>
                Удалить
              </a>
            </div>
          </div>
      )
    }
  } else {
    return (
        <div className="stage-card-new-work">
          <div className="stage-card-new-info">
            <div className="stage-card-info">
              <h5 className="stage-card-heading">Статус</h5>
              <p className="stage-card-date">
                Создан
              </p>
            </div>
            <div className="graph" onClick={() => showStage(toNumber(stage.id))}>
              <p className="stage-card-new-heading">
                {stage.name}
              </p>
              <p className="stage-card-new-text">
                {stage.description || "Нет описания"}
              </p>
            </div>
          </div>
          <div className="stage-card-new-wrapper-link">
            {stage.type === "CTF" || stage.type === "TEST" || stage.type === "ISPTEST" || stage.type === "PROFLINE" ?
                <></>
                :
                <a href={`/challenge/${params.id}/stage/${stage.id}/assessment_settings`} className="card-standart-link">
                  Допуск
                </a>
            }
            {
              stage.type === "CTF" ?
                  <a href={`/challenge/${params.id}/stage/${stage.id}/dashboard`} target="_blank" className="card-standart-link">
                    Дашборд
                  </a>
                  :
                  <></>
            }
            <a className="card-standart-link-false" onClick={() => deleteStage(toNumber(stage.id))}>
              Удалить
            </a>
          </div>
        </div>
    )
  }
}