import React, {useEffect, useState} from "react";
import API from "../requests/_axios";
import {useNavigate} from "react-router-dom";
import {MainPageHelp} from "./profile/common/MainPageHelp";

export const SignupSecondStep = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [signupInProgress, setSignupInProgress] = useState(false);
    const [mailing, setMailing] = useState(false);
    const [politics, setPolitics] = useState(false);
    const navigate = useNavigate();
    const [signupSuccess, setSignupSuccess] = useState(false);

    useEffect(() => {
        API.post("/sso/api/v1/user").then((response) => {
            if (response.status === 200)
                navigate("/profile");
        })
    }, []);

    const handleSignup = () => {
        if (password.length < 8) {
            alert("Пароль должен быть не короче 8 символов. При создании пароля рекомендуем использовать латинские строчные и заглавные буквы, цифры и специальные символы");
            return;
        }
        if(password !== repassword) {
            alert("Пароли не совпадают");
            return;
        }
        if (!politics || !mailing) {
            alert("Вы должны согласиться с правилами и политикой конфиденциальности");
            return;
        }
        setSignupInProgress(true);

        API.post<any>('/sso/api/v1/signup', {username: username, password: password, type: "PERSONAL", mailing: mailing, personalDataPolitics: politics}).then((response) => {
            if(response.status === 200) {
                setSignupSuccess(true);
            } else {
                console.log(response.data.code);
                if(response.data.code === "001-007")
                    alert("Неправильный E-mail");
                else if(response.data.code === "001-000")
                    alert("Пользователь с таким E-mail уже зарегистрирован");
                else if(response.data.code === "001-009")
                    alert("Такой логин уже существует");
            }
            setSignupInProgress(false);
        });
    }



    return (
        <div className="reg-background">
            <MainPageHelp/>
            <img src="/images/logo_white.svg" alt="" className="logo-reg"/>
            <img src="/images/background.jpg" className="video-reg" alt=""/>
            <div className="container-registration">
                <div className="registration">
                    <ul className="number-registration">
                        <a href="/signup">
                            <li className="number-registration-item" style={{cursor: "pointer"}}>
                                <p className="number-reg" style={{cursor: "pointer"}}>1</p>
                            </li>
                        </a>
                        <li className="number-registration-line"></li>
                        <li className="number-registration-item-active">
                            <p className="number-reg-active">2</p>
                        </li>
                        <li className="number-registration-line"></li>
                        <li className="number-registration-item">
                            <p className="number-reg">3</p>
                        </li>
                    </ul>
                    <div className="registration" style={{display: signupSuccess ? "none" : ""}}>
                        <h2 className="reg-heading">Регистрация</h2>
                        <input type="email" className="input-standart" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="E-mail"/>
                        <input type="password" className="input-standart" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Пароль"/>
                        <input type="password" className="input-standart" value={repassword} onChange={(e) => setRepassword(e.target.value)} placeholder="Повторите пароль"/>
                        <label className="label-checkbox-radio">
                            <input type="checkbox" name="email-ads" checked={mailing} onChange={() => setMailing(!mailing)} className="checkbox-radio"/>
                            <p>Соглашаюсь с <a href="/storage/terms_braim.pdf" target="_blank" className="active">правилами использования платформы Braim</a></p>
                        </label>
                        <label className="label-checkbox-radio">
                            <input type="checkbox" name="email-ads" checked={politics} onChange={() => setPolitics(!politics)} className="checkbox-radio"/>
                            <p>Соглашаюсь с <a href="/storage/privacy_policy.pdf" target="_blank" className="active">политикой конфиденциальности Braim</a></p>
                        </label>
                        <button className="btn-main" id="signup-btn" onClick={handleSignup} disabled={signupInProgress}>{signupInProgress ? "Подождите..." : "Регистрация"}</button>
                        <a href="/signin" className="code-error">Я уже зарегистрирован</a>
                    </div>
                    <div id="afterSignup" style={{display: signupSuccess ? "" : "none"}}>
                        <h2 className="reg-heading">На вашу почту отправлена ссылка для подтверждения регистрации</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}