import React, {useEffect, useState} from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {getPercentResult} from "../../../../helpers/ChallengeHelper";
import {formatDate} from "../../../../helpers/DateHelper";
import {IChallengeStageGradeCriteria} from "../../../../types/IChallengeStageGradeCriteria";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {IPublicStageResult} from "../../../../types/IPublicStageResult";
import * as TestAPI from "../../../../../requests/TestAPI";
import {IChallengeStageCTFTask} from "../../../../types/IChallengeStageCTFTask";
import {InfoButtonRedisign} from "./InfoButtonRedisign";
import {StageButtonVideo} from "./StageButtonVideo";

export const StageButtonRedisign: React.FC<{
    stage: IChallengeStage,
    showStage: (id: number | undefined) => void,
    index: number,
    showRating: (id: number | undefined) => void,
    showMyResults: (id: number | undefined) => void}> = ({stage, showStage, index, showRating, showMyResults}) => {

    const [testCriteriaList, setTestCriteriaList] = useState<IChallengeStageGradeCriteria[]>([]);
    const [publicStageResult, setPublicStageResult] = useState<IPublicStageResult>();

    useEffect(() => {
        if (stage.type === "TEST" && stage.taskLink ) {
            TestAPI.getInfo(stage.taskLink, (responseInfo) => {
                if (responseInfo.status === 200) {
                    const criteria = {id: 0, stageId: stage.id, name: "test", points: responseInfo.data.maxPoints}
                    const criteriaList = [];
                    criteriaList.push(criteria);
                    setTestCriteriaList(criteriaList);
                }
            })
        } else if ((stage.type === "ISPTEST" || stage.type === "PROFLINE") && stage.challengeId && stage.id) {
            const criteria = {id: 0, stageId: stage.id, name: "isptest", points: 100}
            const criteriaList = [];
            criteriaList.push(criteria);
            setTestCriteriaList(criteriaList);
        } else if (stage.type === "CTF") {
            let maxPoints = 0;
            stage.ctfTasks.forEach(t => {
                maxPoints += t.points || 0;
            });
            const criteria = {id: 0, stageId: stage.id, name: "CTF", points: maxPoints}
            const criteriaList = [];
            criteriaList.push(criteria);
            setTestCriteriaList(criteriaList);
        }
        if (stage.resultWasPublished) {
            ChallengeAPI.getPublicResultByStageId(stage.id || 0, (response) => {
                if (response.status === 200) {
                    setPublicStageResult(response.data);
                }
            })
        }
    }, [stage]);

    if (stage.type === "VIDEO") {
        return (
            <StageButtonVideo stage={stage} showStage={showStage} key={index}/>
        )
    } else if (stage.type === "INFO") {
        return (<InfoButtonRedisign stage={stage} showStage={showStage} key={index}/>)
    } else
    // done
    if(new Date(stage.startDate + "+03:00" || "") <= new Date() && new Date() > new Date(stage.endDate + "+03:00" || "") && !stage.wasted && stage.resultWasPublished) {
        return (
            <div key={index} className="stage-card-new">
                <div className="stage-card-new-info" onClick={() => showStage(stage.id)}>
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Завершен</h5>
                        <p className="stage-card-done">
                            Прошел
                        </p>
                    </div>
                    <div className="graph">
                        <div className="graph-line">
                            <div className="graph-line-container">
                                <div className="graph-line-line" style={{width: `${getPercentResult(stage.criteriaList.length == 0 ? testCriteriaList : stage.criteriaList, stage.points || 0, undefined) || 0}%`}}></div>
                            </div>
                            <p className="graph-line-result">
                                {getPercentResult(stage.criteriaList.length == 0 ? testCriteriaList : stage.criteriaList, stage.points || 0, undefined) || 0}%
                            </p>
                        </div>
                        <p className="stage-card-new-heading" onClick={() => showStage(stage.id)}>
                            {stage.name}
                        </p>
                        <div className="stage-card-new-gap" onClick={() => showStage(stage.id)}>
                            <p className="stage-card-new-date">
                                {stage?.startDate ? formatDate(stage.startDate) : "-"}
                            </p>
                            <p className="stage-card-new-date">
                                —
                            </p>
                            <p className="stage-card-new-date">
                                {stage?.endDate ? formatDate(stage.endDate) : "-"}
                            </p>
                        </div>
                    </div>
                    <p className="stage-card-new-text">
                        {stage.description}
                    </p>
                </div>
                <div className="stage-card-new-wrapper-link" style={{display: (publicStageResult && publicStageResult.id) || !stage.hideRating ? "" : "none"}}>
                    {publicStageResult && publicStageResult.id ?
                    <a onClick={() => showMyResults(stage.id)} className="card-standart-link">
                        Мой результат
                    </a> : <></>}
                    {stage.hideRating ? <></> :
                    <a onClick={() => showRating(stage.id)} className="card-standart-link">
                        Рейтинг
                    </a>
                    }
                    {
                        publicStageResult && publicStageResult.id ?
                            <a href={`/challenge/${stage.challengeId}/stage/${stage.id}/protocol/${publicStageResult.id}`} className="card-standart-link" target="_blank">
                                Протокол
                            </a>
                            :
                            <></>
                    }
                </div>
            </div>
        )
    }
    else if(new Date(stage.startDate + "+03:00" || "") <= new Date() && new Date() > new Date(stage.endDate + "+03:00" || "") && !stage.wasted && !stage.resultWasPublished) {
        return (
            <div key={index} className="stage-card-new">
                <div className="stage-card-new-info" onClick={() => showStage(stage.id)}>
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Завершен</h5>
                        <p className="stage-card-done">
                            Проводится оценка
                        </p>
                    </div>
                    <div className="graph">
                        <p className="stage-card-new-heading" onClick={() => showStage(stage.id)}>
                            {stage.name}
                        </p>
                        <div className="stage-card-new-gap" onClick={() => showStage(stage.id)}>
                            <p className="stage-card-new-date">
                                {stage?.startDate ? formatDate(stage.startDate) : "-"}
                            </p>
                            <p className="stage-card-new-date">
                                —
                            </p>
                            <p className="stage-card-new-date">
                                {stage?.endDate ? formatDate(stage.endDate) : "-"}
                            </p>
                        </div>
                    </div>
                    <p className="stage-card-new-text">
                        {stage.description}
                    </p>
                </div>
            </div>
        )
    }
    // current
    else if (new Date(stage.startDate + "+03:00" || "") <= new Date() && new Date() < new Date(stage.endDate + "+03:00" || "") && !stage.wasted) {
        return (
            <div key={index} className="stage-card-new-work">
                <div className="stage-card-new-info" onClick={() => showStage(stage.id)}>
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Статус</h5>
                        <p className="stage-card-active">
                            Активный этап
                        </p>
                    </div>
                    <div className="graph">
                        <p className="stage-card-new-heading" onClick={() => showStage(stage.id)}>
                            {stage.name}
                        </p>
                        <div className="stage-card-new-gap-work" onClick={() => showStage(stage.id)}>
                            <p className="stage-card-new-date">
                                {stage?.startDate ? formatDate(stage.startDate) : "-"}
                            </p>
                            <p className="stage-card-new-date">
                                —
                            </p>
                            <p className="stage-card-new-date">
                                {stage?.endDate ? formatDate(stage.endDate) : "-"}
                            </p>
                        </div>
                    </div>
                    <p className="stage-card-new-text">
                        {stage.description}
                    </p>
                </div>
            </div>
        )
    }
    // Недоступен
    else if (new Date(stage.startDate + "+03:00" || "") > new Date()) {
        return (
            <div key={index} className="stage-card-new-future">
                <div className="stage-card-new-info">
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Статус</h5>
                        <p className="stage-card-date">
                            Скоро будет доступен
                        </p>
                    </div>
                    <div className="graph">
                        <p className="stage-card-new-heading">
                            {stage.name}
                        </p>
                        <div className="stage-card-new-gap-work">
                            <p className="stage-card-new-date">
                                {stage?.startDate ? formatDate(stage.startDate) : "-"}
                            </p>
                            <p className="stage-card-new-date">
                                —
                            </p>
                            <p className="stage-card-new-date">
                                {stage?.endDate ? formatDate(stage.endDate) : "-"}
                            </p>
                        </div>
                    </div>
                    <p className="stage-card-new-text"></p>
                </div>
            </div>
        )
    }
    // Потрачено
    else if (stage.wasted) {
        return (
            <div key={index} className="stage-card-new-false">
                <div className="stage-card-new-info" onClick={() => showStage(stage.id)}>
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Завершен</h5>
                        <p className="stage-card-fale">
                            Не пройден
                        </p>
                    </div>
                    <div className="graph">
                        <div className="graph-line">
                            <div className="graph-line-container">
                                <div className="graph-line-line" style={{width: `${getPercentResult(stage.criteriaList.length == 0 ? testCriteriaList : stage.criteriaList, stage.points || 0, undefined) || 0}%`}}></div>
                            </div>
                            <p className="graph-line-result">
                                {getPercentResult(stage.criteriaList.length == 0 ? testCriteriaList : stage.criteriaList, stage.points || 0, undefined) || 0}%
                            </p>
                        </div>
                        <p className="stage-card-new-heading" onClick={() => showStage(stage.id)}>
                            {stage.name}
                        </p>
                        <div className="stage-card-new-gap" onClick={() => showStage(stage.id)}>
                            <p className="stage-card-new-date">
                                {stage?.startDate ? formatDate(stage.startDate) : "-"}
                            </p>
                            <p className="stage-card-new-date">
                                —
                            </p>
                            <p className="stage-card-new-date">
                                {stage?.endDate ? formatDate(stage.endDate) : "-"}
                            </p>
                        </div>
                    </div>
                    <p className="stage-card-new-text">
                        {stage.description}
                    </p>
                </div>
                <div className="stage-card-new-wrapper-link" style={{display: (publicStageResult && publicStageResult.id) || !stage.hideRating ? "" : "none"}}>
                    {publicStageResult && publicStageResult.id ?
                        <a onClick={() => showMyResults(stage.id)} className="card-standart-link">
                            Мой результат
                        </a> : <></>}
                    {stage.hideRating ? <></> :
                        <a onClick={() => showRating(stage.id)} className="card-standart-link">
                            Рейтинг
                        </a>
                    }
                    {
                        publicStageResult && publicStageResult.id ?
                            <a href={`/challenge/${stage.challengeId}/stage/${stage.id}/protocol/${publicStageResult.id}`} className="card-standart-link" target="_blank">
                                Протокол
                            </a>
                            :
                            <></>
                    }

                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}