import React, {useEffect, useState} from "react";
import {OrgHeader} from "../../../common/OrgHeader";
import {OrgNavigation} from "../../../common/OrgNavigation";
import {IChallenge} from "../../../../types/IChallenge";
import {JuryInvite} from "../right/JuryInvite";
import {JuryStage} from "../right/JuryStage";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {ModalMessage} from "../../../common/ModalMessage";
import {IJury} from "../../../../types/jury/IJury";
import {CONFIG} from "../../../../../CONFIG";

export const JuryTab:React.FC<{challenge: IChallenge | undefined}> = ({challenge}) => {

    const [right, setRight] = useState<string | undefined>();
    const [juryList, setJuryList] = useState<IJury[]>([]);
    const [jury, setJury] = useState<IJury>();
    const [error, setError] = useState("");

    useEffect(() => {
        if (challenge?.id) {
            ChallengeAPI.getJuryList(challenge.id, (response) => {
                if (response.status === 200) {
                    setJuryList(response.data);
                } else {
                    setError("Ошибка при получении списка");
                }
            });
        }
    }, []);

    const deleteJury = (id: string) => {
        if(!confirm("Вы действительно хотите удалить члена жюри?") || !challenge?.id) return;
        ChallengeAPI.deleteJury(challenge.id, id, (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                setError("Ошибка удаления жюри");
            }
        })
    }

    const showInvite = () => {
        setJury(undefined);
        setRight("invite");
    }

    const showStages = (j: IJury) => {
        setJury(j);
        setRight("stage");
    }

    const hideAll = () => {
        setJury(undefined);
        setRight(undefined);
    }

    return (
        <div className="platform-background">
            <img src="/images/main.jpg" className="image-back-video" alt=""/>
            <ModalMessage header={"Ошибка"} msg={error} _callback={() => setError("")}/>
            <div className="left-block">
                <OrgHeader preview={null}/>
                <main className="content">
                    <OrgNavigation />
                    <div className="main-list-center-comand">
                        <button className="button-add" id="add" onClick={showInvite}>
                            Добавить
                        </button>

                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">{challenge?.name}</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href={`/challenge/${challenge?.id}`} className="link-nav-center-item non-active-nav">Настройки</a>
                                </li>
                                <li className="nav-center-item">
                                    <a className="link-nav-center-item">Эксперты</a>
                                </li>
                            </ul>
                        </div>
                        <div className="challenge-info">
                            <div className="first-message">
                                <p className="city">
                                    В этом разделе вы можете добавлять членов жюри, которые могут удалённо оценивать работы участников мероприятия. <a href="https://disk.yandex.ru/d/MjX6rXudwbTHKg" target="_blank" className="active-link">Видеоинструкция</a>
                                </p>
                            </div>
                            <ul className="challenge-list">
                                {juryList.map((j, index) =>
                                    <li className="challenge-card" key={index}>
                                        <div className="challenge-card-main">
                                            <img onClick={() => showStages(j)}
                                                 src={CONFIG.STORAGE_URL + "/" + j.profile.userId + "/avatar/" + j.profile.avatar}
                                                 onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                                 alt=""
                                                 className="challenge-avatar"/>
                                            <div className="user-info" onClick={() => showStages(j)}>
                                                <p className="challenge-header">{`${j.profile.lastname} ${j.profile.firstname}`}
                                                </p>
                                                <p className="city">{j.profile.city}</p>
                                            </div>
                                        </div>
                                        {
                                            j.juryStages.length > 0 ?
                                                <div className="block-input">
                                                    <p className="legend-about">
                                                        Оценивает
                                                    </p>
                                                    <ul className="interests">
                                                        {
                                                            j.juryStages.map((js, jsIndex) =>
                                                                <li className="interest-item-comand" key={jsIndex}>
                                                                    {`${js.stage?.name} (оценено: ${js.assessed} из ${js.total})`}
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                :
                                                <></>
                                        }
                                        <div className="stage-card-new-wrapper-link">
                                            <a onClick={() => deleteJury(j.id)} className="card-standart-link-false">
                                                Удалить эксперта
                                            </a>
                                        </div>
                                    </li>
                                )}

                                {/*<li className="challenge-card">
                                    <div className="stage-card-new-wrapper">
                                        <p className="status-stage">
                                            Не создан
                                        </p>
                                    </div>
                                    <div className="challenge-card-main">
                                        <div className="user-info">
                                            <p className="challenge-header">Новый эексперт
                                            </p>
                                            <p className="city">Москва</p>
                                        </div>
                                    </div>
                                    <div className="stage-card-new-wrapper-link">
                                        <a className="card-standart-link-false">
                                            Удалить эксперта
                                        </a>
                                    </div>
                                </li>*/}
                            </ul>

                        </div>
                    </div>
                </main>
            </div>

            {
                right === "invite" ? <JuryInvite close={hideAll}/> : ""
            }

            {
                right === "stage" && jury && challenge?.stages ? <JuryStage close={hideAll} jury={jury} stages={challenge.stages}/> : ""
            }
        </div>
    )
}