import React, {useEffect, useState} from "react";
import {Help} from "../../common/Help";
import {CONFIG} from "../../../../CONFIG";
import {ProfileIndividualHeader} from "../profile/ProfileIndividualHeader";
import {NavigationIndividualMain} from "../navigation/NavigationIndividualMain";
import {IChallenge} from "../../../types/IChallenge";
import {useSearchParams} from "react-router-dom";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {max, toNumber} from "lodash";
import {IJuryAssessmentList} from "../../../types/assessment/IJuryAssessmentList";
import InfiniteScroll from "react-infinite-scroll-component";
import {GradeDetails} from "./right/GradeDetails";
import {IJuryAssessmentListTeam} from "../../../types/assessment/IJuryAssessmentListTeam";
import {IJuryAssessmentListParticipant} from "../../../types/assessment/IJuryAssessmentListParticipant";
import {JuryStageButton} from "./JuryStageButton";
import {ModalMessage} from "../../common/ModalMessage";

export const JuryStage:React.FC<{challenge: IChallenge}> = ({challenge}) => {

    const [error, setError] = useState("");
    const [search, setSearch] = useState("");
    const [tab, setTab] = useState("not_graded");
    const [searchParams, setSearchParams] = useSearchParams();
    const [maxPoints, setMaxPoints] = useState(0);

    const [assessmentResponse, setAssessmentResponse] = useState<IJuryAssessmentList>();
    const [page, setPage] = useState(0);
    const [rightInstance, setRightInstance] = useState<IJuryAssessmentListTeam | IJuryAssessmentListParticipant | undefined>(undefined);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (page === 0) loadData();
    }, [page]);

    useEffect(() => {
        setPage(0);
    }, [tab]);

    useEffect(() => {
        let total = 0;
        assessmentResponse?.criteriaList.forEach(c => total += c.points || 0);
        setMaxPoints(total);
    }, [assessmentResponse]);

    const loadData = () => {
        ChallengeAPI.getJuryAssessmentList(toNumber(challenge.id), toNumber(searchParams.get("stageId")), page, search, tab, (response) => {
            if (response.status === 200) {
                if (response.data.teams) {
                    if (!assessmentResponse?.teams || page == 0) {
                        setAssessmentResponse(response.data);
                        setPage(page + 1)
                    } else {
                        let currentTeams = assessmentResponse.teams;
                        currentTeams.totalPages = response.data.teams.totalPages;
                        currentTeams.total = response.data.teams.total;
                        currentTeams.items = currentTeams.items.concat(response.data.teams.items);
                        currentTeams.rawTotal = response.data.teams.rawTotal;
                        setAssessmentResponse({
                            ...assessmentResponse,
                            teams: currentTeams,
                            participants: undefined
                        });
                        setPage(page + 1)
                    }
                } else if (response.data.participants) {
                    if (!assessmentResponse?.participants || page == 0) {
                        setAssessmentResponse(response.data);
                        setPage(page + 1);
                    } else {
                        let currentParticipants = assessmentResponse.participants;
                        currentParticipants.totalPages = response.data.participants.totalPages;
                        currentParticipants.total = response.data.participants.total;
                        currentParticipants.rawTotal = response.data.participants.rawTotal;
                        currentParticipants.items = currentParticipants.items.concat(response.data.participants.items);
                        setAssessmentResponse({
                            ...assessmentResponse,
                            teams: undefined,
                            participants: currentParticipants
                        });
                        setPage(page + 1)
                    }
                }
            } else {
                setError("Организаторы еще не передали вам работы для оценки");
            }
        });
    }

    const getTotalGraded = () => {
        return assessmentResponse?.teams ?
            assessmentResponse.teams.total
            :
            assessmentResponse?.participants ?
                assessmentResponse.participants.total
                :
                0;
    }

    const getTotal = () => {
        return assessmentResponse?.teams ?
            assessmentResponse.teams.rawTotal
            :
            assessmentResponse?.participants ?
                assessmentResponse.participants.rawTotal
                :
                0;
    }

    const getGradedPercent = () : number => {
        return Number((100 / toNumber(getTotal()) * toNumber(getTotalGraded())).toFixed(1));
    }

    const submitAllAssessments = () => {
        ChallengeAPI.submitAllAssessments(toNumber(challenge.id), toNumber(searchParams.get("stageId")), (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                setError(response.data.message);
            }
        })
    }

    const isSubmitButtonVisible = () : boolean => {
        if (tab !== "graded") return false;
        if (!assessmentResponse?.teams && !assessmentResponse?.participants) return false;
        if (assessmentResponse.teams?.items && assessmentResponse.teams.items.length > 0) {
            return !assessmentResponse.teams?.items[0].submitted
        } else if (assessmentResponse.participants?.items && assessmentResponse.participants.items.length > 0) {
            console.log(assessmentResponse.participants?.items)
            return !assessmentResponse.participants?.items[0].submitted;
        }
        return false;
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setPage(0);
        }
    }

    return (
        <div className="platform-background">
            <Help challenge={challenge}/>
            {challenge.branding && challenge.branding.backgroundPath ?
                challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                    <img src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath} className="image-back-video"/>
                    :
                    <video autoPlay muted loop playsInline className="image-back-video">
                        <source src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath}/>
                    </video>
                :
                <img src="/images/main.jpg" className="image-back-video" alt=""/>
            }
            <div className="left-block">
                <ProfileIndividualHeader newPreview={""}/>
                <main className="content">
                    <NavigationIndividualMain categoryCode={challenge.categoryCode}/>
                    <div className="main-list-center">
                        <div className="my-challenge">
                            <a href={challenge.site} target="_blank" className="link-landing"></a>
                            <div className="user-info">
                                <p className="fio-or-name" style={{cursor: "pointer"}}>{challenge.name}</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a onClick={() => setTab("not_graded")}
                                       style={{cursor: "pointer"}}
                                       className={`link-nav-center-item ${tab === "graded" ? "non-active-nav" : ""}`}>Без оценки</a>
                                </li>
                                <li className="nav-center-item">
                                    <a onClick={() => setTab("graded")}
                                       style={{cursor: "pointer"}}
                                       className={`link-nav-center-item ${tab === "not_graded" ? "non-active-nav" : ""}`}>С оценкой</a>
                                </li>
                            </ul>
                        </div>
                        <div className="challenge-info" id="scrollableDiv">
                            {isSubmitButtonVisible() ?
                                <button className="button-add-rate" onClick={submitAllAssessments}>
                                    Отправить оценки
                                </button>
                                :
                                <></>
                            }
                            <input type="text" placeholder="Поиск" className="input-challenges"
                                   onKeyDown={handleKeyDown}
                                   onChange={(e) => setSearch(e.target.value)}/>

                            {
                                (!assessmentResponse?.teams || assessmentResponse.teams.items.length == 0)
                                &&
                                (!assessmentResponse?.participants || assessmentResponse.participants.items.length == 0)
                                ?
                                    <div className="first-message-error">
                                        <p className="fio-or-name">
                                            Поиск не дал результатов
                                        </p>
                                    </div>
                                    :
                                    <></>
                            }

                            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>

                            <div className="results-profile-rating">
                                <div className="user-profile-rating-info">
                                    <p className="stage-card-new-heading">
                                        Оценено
                                    </p>
                                    <div className="user-profile-rating-status-block">
                                        <p className="user-profile-status-fact">{getTotalGraded()}</p>
                                        <p className="user-profile-status-fact">/</p>
                                        <p className="user-profile-status-plan">{getTotal()}</p>
                                    </div>
                                </div>
                                {getGradedPercent()}
                                <div className="raring-graph">
                                    <div className="rating-graph-line" style={{width: getGradedPercent() + "%"}}/>
                                </div>
                            </div>

                            {assessmentResponse?.teams ?
                                <InfiniteScroll next={loadData} hasMore={page < assessmentResponse.teams.totalPages} loader={<h4>Загрузка...</h4>} dataLength={assessmentResponse.teams.items.length} className="challenge-info" scrollableTarget="scrollableDiv">
                                    {assessmentResponse.teams.items.map((t, index) =>
                                        <JuryStageButton maxPoints={maxPoints} tab={tab} name={t.name} solutionDate={t.solutionDate} instance={t} setRightInstance={setRightInstance} key={index}/>
                                    )}
                                </InfiniteScroll>
                                : assessmentResponse?.participants ?
                                    <InfiniteScroll next={loadData} hasMore={page < assessmentResponse.participants.totalPages} loader={<h4>Загрузка...</h4>} dataLength={assessmentResponse.participants.items.length} className="challenge-info" scrollableTarget="scrollableDiv">
                                        {assessmentResponse.participants.items.map((p, index) =>
                                                <JuryStageButton maxPoints={maxPoints} tab={tab} name={`${p.lastname} ${p.firstname}`} solutionDate={p.solutionDate} instance={p} setRightInstance={setRightInstance} key={index}/>
                                        )}
                                    </InfiniteScroll>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </main>
            </div>
            <GradeDetails allowComments={assessmentResponse?.allowComments || false}
                          rulesLink={assessmentResponse?.rules}
                          instance={rightInstance}
                          criteriaList={assessmentResponse? assessmentResponse.criteriaList : []}
                          closeSelf={setRightInstance}/>
        </div>
    )
}