import React, {useEffect, useRef, useState} from "react";
import * as TestAPI from "../../../../../requests/TestAPI";
import {ITopic} from "../../../../types/ITopic";
import {ModalMessage} from "../../../common/ModalMessage";
import {toNumber} from "lodash";
import {IQuestion} from "../../../../types/IQuestion";
import {IAnswer} from "../../../../types/IAnswer";
import {IUpdateQuestionsRequest} from "../../../../types/IUpdateQuestionsRequest";
import {b64toBlob, getBase64} from "../../../../helpers/Util";
import {CONFIG} from "../../../../../CONFIG";
import SessionModel from "../../../../../stores/SessionModel";

interface IUploadErrors {
    errors: IUploadError[]
}

interface IUploadError {
    message?: string,
    rowNumber: number
}
export const UploadTopics: React.FC<{ testId: string }> = ({testId}) => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [errors, setErrors] = useState<IUploadErrors>();


    const handleUpload = (testId: string | undefined, e: React.ChangeEvent<HTMLInputElement>) => {
        if (!testId) return;
        const fileList = e.target.files;
        if (!fileList) return;
        const formData = new FormData();
        setShowSpinner(true);
        formData.append("file", fileList[0], fileList[0].name);
        TestAPI.uploadTest(testId, formData, (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                setErrors(response.data);
            }
            setShowSpinner(false);
        });
    }

    if (showSpinner) {
        return (
            <div className="load-block">
                <p className="load-sheet">
                    загрузка
                </p>
                <div className="circle-block">
                    <div className="circle-load one-load"></div>
                    <div className="circle-load two-load"></div>
                    <div className="circle-load three-load"></div>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div className="container-right-block">
                    <div className="test-theme-block">
                        <li className="first-message"><p className="fio-or-name">Это информация о пользовании</p><p
                            className="city">Для того, чтобы загрузить тест, воспользуйтесь <a
                            href="https://disk.yandex.ru/d/h5h1ikVGh7PAsg" target="_blank"
                            className="active-link">Видеоинструкцией</a> и <a
                            href="https://disk.yandex.ru/d/h5h1ikVGh7PAsg" target="_blank"
                            className="active-link">Шаблоном</a> еще почитайте что-нибудь. Войну и мир,
                            например. Или другое необходимое чтобы составить этот текст</p></li>
                        {errors?.errors.map((e, index) =>
                            <div className="error-message" key={index}>Строка {e.rowNumber}: {e.message}</div>
                        )}
                        <label htmlFor={`upload-${testId}`} className="btn-main"
                               style={{display: "block", textAlign: "center"}}>
                            Загрузить из файла
                            <input type="file" accept=".zip" onChange={(e) => handleUpload(testId, e)} name=""
                                   id={`upload-${testId}`}
                                   className="hidden"/>
                        </label>
                    </div>
                </div>
            </>
        )
    }
}