import React, {useEffect, useState} from "react";
import API from "../../../requests/_axios";
import {CONFIG} from "../../../CONFIG";
import {ProfileNavigationAdmin} from "./ProfileNavigationAdmin";
import {ProfileAdminHeader} from "./ProfileAdminHeader";
import {ProfileUniversityView} from "../common/ProfileUniversityView";
import {useParams, useSearchParams} from "react-router-dom";
import {UniversityAdminButtons} from "./UniversityAdminButtons";
import * as SsoAPI from "../../../requests/SsoAPI";
import {toNumber} from "lodash";
import {IBusinessProfile} from "../../types/IBusinessProfile";
import SessionModel from "../../../stores/SessionModel";

export const BusinessList = () => {

  const [businessList, setBusinessList] = useState([]);
  const [tab, setTab] = useState("new");
  const [currentBusinessId, setCurrentBusinessId] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const params = useParams();

  useEffect(() => {
    setTab(searchParams.get("tab") || "new");
    fetchUniversities(searchParams.get("tab") || "new");
    if (params.id) {
      handleShowDetails(toNumber(params.id))
    }
  }, []);

  const fetchUniversities = (tab: string) => {
    API.get(`/challenge/api/v1/business?status=${tab}&search=${searchQuery}`, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response => {
      setBusinessList(response.data);
    }));
  }

  const handleApprove = (id: number) => {
    API.post("/challenge/api/v1/business/" + id + "/approve", {}, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response => {
      if(response.status === 200)
         { // @ts-ignore
           setBusinessList(businessList.filter(item => item.userId !== id));
         }
    }));
  }

  const handleReject = (id: number) => {
    API.post("/challenge/api/v1/business/" + id + "/reject", {}, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response => {
      if(response.status === 200)
      { // @ts-ignore
        setBusinessList(businessList.filter(item => item.userId !== id));
      }
    }));
  }

  const handleDelete = (id: number) => {
    if(confirm("Уверен, братуха? Пути назад нет"))
      API.delete("/challenge/api/v1/business/" + id).then((response => {
        if(response.status === 200)
        { // @ts-ignore
          setBusinessList(businessList.filter(item => item.userId !== id));
        }
      }));
  }

  const handleShowDetails = (userId: number) => {
    setCurrentBusinessId(userId);
    // @ts-ignore
    document.querySelector('.right-block').style.right = "0"
  }
  const handleCloseDetails = () => {
    setCurrentBusinessId(null);
    // @ts-ignore
    document.querySelector('.right-block').style.right = "-100vw"
  }

  const handleOfficialLetterChange = function (id: number, e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;
    if (fileList[0].size >= 5000000) {
      alert("Размер не должен превышать 5 Мб.")
    }

    const formData = new FormData();
    formData.append("file", fileList[0], fileList[0].name);
    API.post(`/challenge/api/v1/business/${id}/official-letter`, formData, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
      if(response.status === 200) {
        window.location.reload();
      } else {
        alert("Файл должен быть в формате PDF и размер не должен превышать 5 Мб.")
      }
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setBusinessList([]);
      fetchUniversities(tab);
    }
  }

  return (
      <div className="platform-background">
        <img src="/images/main.jpg" className="image-back-video" alt=""/>
        <div className="left-block">
          <ProfileAdminHeader/>
          <main className="content">
            <ProfileNavigationAdmin />
            <div className="main-list-center">
              <div className="my-challenge">
                <div className="user-info">
                  <p className="fio-or-name">Администрирование</p>
                </div>
                <ul className="nav-block-center">
                  <li className="nav-center-item">
                    <a href="/business/?tab=new" id="new-filter-button" className={tab === "new" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Новые</a>
                  </li>
                  <li className="nav-center-item">
                    <a href="/business/?tab=onModeration" onClick={() => setTab("onModeration")} id="on-moderation-filter-button" className={tab === "onModeration" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>На модерации</a>
                  </li>
                  <li className="nav-center-item">
                    <a href="/business/?tab=approved" onClick={() => setTab("approved")} id="approved-filter-button" className={tab === "approved" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Допущенные</a>
                  </li>
                  <li className="nav-center-item">
                    <a href="/business/?tab=rejected" onClick={() => setTab("rejected")} id="approved-filter-button" className={tab === "rejected" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Отклоненные</a>
                  </li>
                </ul>
              </div>
              <ul className="challenge-info">
                <input type="text" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(e.target.value);
                }} onKeyDown={handleKeyDown} placeholder="Частичное/полное название или Email" className="input-challenges"/>
                <div className="first-message">Всего: {businessList.length}</div>
                {businessList && businessList.map((p: IBusinessProfile, i) =>
                      <li className="challenge-card" key={i}>
                        <div className="challenge-card-main">
                          <img onClick={() => handleShowDetails(p?.userId || 0)}
                               src={CONFIG.STORAGE_URL + "/" + p?.userId + "/avatar/" + p?.avatar } alt=""
                               onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_university.svg"}}
                               className="challenge-avatar"/>
                            <div className="user-info">
                              <p className="challenge-header" onClick={() => handleShowDetails(p?.userId || 0)}>
                                {p?.shortName}
                              </p>
                              <p className="challenge-company">{p?.city}, {p?.region}</p>
                            </div>
                        </div>
                        <p className="challenge-description">
                          {p?.fullName}
                          <p>{p?.username}</p>
                        </p>
                        <p className="challenge-description">
                          <a href={`${CONFIG.STORAGE_URL}/${p?.userId}/officialLetter/${p?.officialLetter}`}
                             className="active-link" style={{display: p?.officialLetter ? "" : "none"}}>Посмотреть письмо</a>
                          <label className="load-file-input">
                            <span>Загрузить письмо</span>
                            <input type="file" onChange={(e) => handleOfficialLetterChange(p?.userId || 0, e)} className="load-file-hidden"/>
                          </label>
                        </p>
                        {SessionModel.isAdmin() ?
                            <div className="block-input">
                              <button className="btn-main" onClick={() => SsoAPI.loginAs(p?.username || "")}>Войти ╭ᑎ╮</button>
                            </div>
                            :
                            <></>
                        }
                        <div className="challenge-information">
                          <UniversityAdminButtons key={i} profile={p} approve={handleApprove} reject={handleReject} remove={handleDelete}/>
                        </div>
                      </li>
                )}
              </ul>
            </div>
          </main>
        </div>
        <div className="right-block" style={{right: "-100vw"}}>
          <header className="header">
            <p className="header-back" onClick={handleCloseDetails} id="backmenu">Назад</p>
            <p className="name-menu">Об организации</p>
          </header>
          <div className="container-right-block">
            <ProfileUniversityView currentForm={undefined} userId={currentBusinessId}/>
          </div>
        </div>
      </div>
  );
}