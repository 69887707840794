import React, {useEffect, useState} from "react";
import API from "../requests/_axios";
import {useNavigate, useParams} from "react-router-dom";
import {MainPageHelp} from "./profile/common/MainPageHelp";

export const Reset = () => {
  const [username, setUsername] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    API.post("/sso/api/v1/user").then((response) => {
      if (response.status === 200)
        navigate("/profile");
    })
  }, [])

  const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }

  const handleReset = () => {
    if(params.type === "link")
      API.post<any>('/sso/api/v1/signup/resend', {email: username}).then((response) => {
        alert("На вашу почту отправлена новая ссылка");
        navigate("/?message=link_reset");
      })
    else
      API.post<any>('/sso/api/v1/signup/reset', {email: username}).then((response) => {
        alert("На вашу почту отправлен новый пароль");
        navigate("/?message=password_reset");
      })
  }

  // return (
  //     <div>
  //       <input type="text" value={username} onChange={handleUsername}/>
  //       {params.type === "link" ? (<button onClick={handleReset}>Отправить</button>) : <button onClick={handleReset}>Сбросить</button>}
  //     </div>
  // )

  return (
      <div className="reg-background">
        <MainPageHelp/>
        <a href="https://braim.org" target="_blank">
          <img src={require("../../public/images/logo_white.svg").default} alt="" className="logo-reg"/>
        </a>
        <img src="/images/background.jpg" className="video-reg"/>
        <div className="container-registration">
          <div className="registration">
            <p>Введите адрес электронной почты</p>
            <input type="email" className="input-standart" onChange={handleUsername} value={username} placeholder="E-mail"/>
            <input type="button" className="btn-main" onClick={handleReset} value="Продолжить"/>
          </div>
        </div>
      </div>
  )
}