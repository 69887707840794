import React, {useEffect, useState} from "react";
import API from "../../../../../requests/_axios";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {toNumber} from "lodash";
import {IChallengeStageGradeCriteria} from "../../../../types/IChallengeStageGradeCriteria";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {ITest} from "../../../../types/ITest";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {StageVideo} from "./StageVideo";
import {StageCTF} from "./StageCTF";
import {ModalMessage} from "../../../common/ModalMessage";

export const StageSettings: React.FC<{
    challengeId: number | undefined,
    stageId: number,
    handleSave: () => void
}> = ({challengeId, stageId, handleSave}) => {

    const [stage, setStage] = useState<IChallengeStage>({
        criteriaList: [],
        videos: [],
        ctfTasks: []
    });
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [totalPoints, setTotalPoints] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [tests, setTests] = useState([]);
    const [task, setTask] = useState('');
    const [commonText, setCommonText] = useState('');
    const [startDateHint, setStartDateHint] = useState(false);
    const [endDateHint, setEndDateHint] = useState(false);
    const [descriptionHint, setDescriptionHint] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        setShowSpinner(true);
        API.get("/challenge/api/v1/challenge/" + challengeId + "/stage/" + stageId).then((response) => {
            if (response.data.startDate) {
                setStartDate(response.data.startDate.substring(0, 16));
                //response.data.startDate = response.data.startDate + ":00:01.000+03:00";
            }
            if (response.data.endDate) {
                setEndDate(response.data.endDate.substring(0, 16));
                //response.data.endDate = response.data.endDate + ":00:59.000+03:00";
            }
            precalculateTotalPoints(response.data.criteriaList);
            setStage(response.data);
            setTask(response.data.task);
            setCommonText(response.data.commonText);
            if (response.data.type === "TEST") {
                ChallengeAPI.getAdminTests((response) => {
                    if (response.status === 200) {
                        setTests(response.data);
                        setShowSpinner(false);
                    }
                })
            } else if (response.data.type === "ISPTEST") {
                /* iSpring tests */
                ChallengeAPI.getISpringContentList((response) => {
                    if (response.status === 200) {
                        setTests(response.data);
                        setShowSpinner(false);
                    }
                });
            } else {
                setShowSpinner(false);
            }
        });
    }, [stageId]);

    useEffect(() => {
        setStage({...stage, task: task});
    }, [task]);

    useEffect(() => {
        setStage({...stage, commonText: commonText});
    }, [commonText]);

    const precalculateTotalPoints = (criteriaList: IChallengeStageGradeCriteria[]) => {
        let tempTotalPoints = 0;
        if (criteriaList) {
            criteriaList.map((criteria: IChallengeStageGradeCriteria) => {
                tempTotalPoints += criteria.points || 0;
            });
        }
        setTotalPoints(tempTotalPoints);
    }

    const handleSubmit = () => {
        setErrorMessage("");
        API.put("/challenge/api/v1/challenge/" + challengeId + "/stage/" + stageId, stage).then((response) => {
            if (response.status === 200)
                handleSave();
            else {
                setErrorMessage("Ошибка! " + response.data.message);
                // @ts-ignore
                document.querySelector('.container-right-block').scrollTo(0, 0);
            }
        });
    }

    const createCriteria = () => {
        if (!confirm("Вы точно хотите добавить ещё один критерий?")) return;
        API.post("/challenge/api/v1/challenge/" + challengeId + "/stage/" + stageId + "/criteria").then((response) => {
            if (response.status === 200) {
                const newCriteriaList: IChallengeStageGradeCriteria[] = stage.criteriaList;
                newCriteriaList.push(response.data);
                setStage({...stage, criteriaList: newCriteriaList})
            } else {
                setErrorMessage(response.data.message);
            }
        })
    }

    const deleteCriteria = (id: number | undefined) => {
        API.delete("/challenge/api/v1/challenge/" + challengeId + "/stage/" + stageId + "/criteria/" + id).then((response) => {
            if (response.status === 200) {
                setStage({
                    ...stage, criteriaList: stage.criteriaList.filter(function (criteria) {
                        if (criteria.id !== id)
                            return criteria;
                    })
                });
            } else {
                if (response.data.code === "009-031") {
                    setErrorMessage("Удалить нельзя. Есть решения или пройденные тесты");
                } else {
                    setErrorMessage("Ошибка! " + response.data.message);
                }
                // @ts-ignore
                document.querySelector('.container-right-block').scrollTo(0, 0);
            }
        })
    }

    const handlePointsChange = (event: React.ChangeEvent<HTMLInputElement>, id: number | undefined) => {
        //event.target.value
        let tempTotalPoints = 0;
        setStage({
            ...stage, criteriaList: stage.criteriaList.filter(function (criteria) {
                if (criteria.id === id)
                    criteria.points = toNumber(event.target.value);
                tempTotalPoints += criteria.points || 0;
                return criteria;
            })
        });
        setTotalPoints(tempTotalPoints);
    }

    const handleCriteriaNameChange = (event: React.ChangeEvent<HTMLInputElement>, id: number | undefined) => {
        setStage({
            ...stage, criteriaList: stage.criteriaList.filter(function (criteria) {
                if (criteria.id === id)
                    criteria.name = event.target.value;
                return criteria;
            })
        });
    }

    const handleChangeStartDate = function (e: React.ChangeEvent<HTMLInputElement>) {
        setStage({...stage, startDate: e.target.value + ":00:01.000+03:00"});
        setStartDate(e.target.value);
    }

    const handleChangeEndDate = function (e: React.ChangeEvent<HTMLInputElement>) {
        setStage({...stage, endDate: e.target.value + ":00:59.000+03:00"});
        setEndDate(e.target.value);
    }

    const handleDelete = () => {
        API.delete("/challenge/api/v1/challenge/" + challengeId + "/stage/" + stageId).then((response) => {
            if (response.status === 200)
                window.location.reload();
            else {
                if (response.data.code === "009-031") {
                    setErrorMessage("Удалить нельзя. Есть решения или пройденные тесты");
                } else {
                    setErrorMessage("Ошибка! " + response.data.message);
                }
                // @ts-ignore
                document.querySelector('.container-right-block').scrollTo(0, 0);
            }
        });
    }

    const getStageType = () => {
        if (stage.type === "TASK")
            return "Задание (ссылка)"
        else if (stage.type === "TEST")
            return "Тестирование"
        else if (stage.type === "TASK_FILE")
            return "Задание (файл)"
        else if (stage.type === "ISPTEST")
            return "Тест ISpring"
        else if (stage.type === "VIDEO")
            return "Обучение"
        else if (stage.type === "INFO")
            return "Инфо"
        else if (stage.type === "CTF")
            return "CTF"
        else if (stage.type === "PROFLINE")
            return "Тест PROFLINE"
    }

    if (stage.type === "CTF") {
        return (
            <div className="container-right-block">
                <div className="menu-challenge">
                    <h2 className="headline-right-block">{getStageType()} (ID {stage.id})</h2>
                    <div className="btn-info">
                        <h4 className="btn-info-heading">
                            Внимание
                        </h4>
                        <p className="btn-info-text">
                            Строки отмеченные этим знаком * являются обязательными для заполнения
                        </p>
                    </div>
                    <ModalMessage msg={errorMessage} header={"Ошибка"} _callback={() => setErrorMessage("")}/>
                    <fieldset className="block-input">
                        <legend className="legend">Дата и время начала*</legend>
                        <input type="datetime-local" value={startDate} onChange={handleChangeStartDate}
                               className="input-challenges" placeholder="Начните вводить" min="1700-01-01"
                               max="2100-01-01"/>
                        <div className="answer" onMouseOver={() => setStartDateHint(!startDateHint)}
                             onMouseOut={() => setStartDateHint(!startDateHint)}></div>
                        <p className="answer-text" style={{display: startDateHint ? "" : "none"}}>Время московское (UTC
                            +3).</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Дата и время окончания*</legend>
                        <input type="datetime-local" value={endDate} onChange={handleChangeEndDate}
                               className="input-challenges"
                               placeholder="Начните вводить" min="1700-01-01" max="2100-01-01"/>
                        <div className="answer" onMouseOver={() => setEndDateHint(!endDateHint)}
                             onMouseOut={() => setEndDateHint(!endDateHint)}></div>
                        <p className="answer-text" style={{display: endDateHint ? "" : "none"}}>Время московское (UTC
                            +3).</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Название CTF*</legend>
                        <input type="text" value={stage.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setStage({...stage, name: e.target.value});
                        }} className="input-challenges" placeholder="Название 1"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Описание CTF*</legend>
                        <textarea className="input-challenges" value={stage.description}
                                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      setStage({...stage, description: e.target.value});
                                  }} cols={30} rows={10} maxLength={500}
                                  placeholder="Заполните описание (до 500 символов)"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Минимальное количество баллов для прохождения этапа/задания*</legend>
                        <input type="number" value={stage.passScore}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   setStage({...stage, passScore: toNumber(e.target.value)});
                               }} className="input-challenges" placeholder="введите число"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Навыки, требуемые для прохождения (через запятую)*</legend>
                        <input type="text" value={stage.skills} onChange={(e) => {
                            setStage({...stage, skills: e.target.value})
                        }} className="input-challenges" placeholder="Начните вводить"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Настройки видимости</legend>
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.hidden}
                                   onChange={(e) => setStage({...stage, hidden: !stage.hidden})} className="radio-btn"/>
                            <span className="span-radio">Не отображать в разделе "Задания"</span>
                        </label>
                    </fieldset>
                    <fieldset className="block-input">
                        {/*<legend className="legend">Видимость на лендинге</legend>*/}
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.landingVisibility}
                                   onChange={(e) => setStage({...stage, landingVisibility: !stage.landingVisibility})}
                                   className="radio-btn"/>
                            <span className="span-radio">Отображать на лендинге</span>
                        </label>
                    </fieldset>
                    <fieldset className="block-input">
                        {/*<legend className="legend">Видимость рейтинга</legend>*/}
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.hideRating}
                                   onChange={(e) => setStage({...stage, hideRating: !stage.hideRating})}
                                   className="radio-btn"/>
                            <span className="span-radio">Не отображать рейтинг участников</span>
                        </label>
                    </fieldset>
                </div>
                <StageCTF stage={stage} refreshStage={setStage} setErrorMessage={setErrorMessage}/>
            </div>
        )
    } else if (stage.type === "VIDEO") {
        return (
            <div className="container-right-block">
                <div className="menu-challenge">
                    <h2 className="headline-right-block">{getStageType()} (ID {stage.id})</h2>
                    <div className="btn-info">
                        <h4 className="btn-info-heading">
                            Внимание
                        </h4>
                        <p className="btn-info-text">
                            Строки отмеченные этим знаком * являются обязательными для заполнения
                        </p>
                    </div>
                    <ModalMessage msg={errorMessage} header={"Ошибка"} _callback={() => setErrorMessage("")}/>
                    <fieldset className="block-input">
                        <legend className="legend">Дата и время начала*</legend>
                        <input type="datetime-local" value={startDate} onChange={handleChangeStartDate}
                               className="input-challenges" placeholder="Начните вводить" min="1700-01-01"
                               max="2100-01-01"/>
                        <div className="answer" onMouseOver={() => setStartDateHint(!startDateHint)}
                             onMouseOut={() => setStartDateHint(!startDateHint)}></div>
                        <p className="answer-text" style={{display: startDateHint ? "" : "none"}}>Время московское (UTC
                            +3).</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Дата и время окончания*</legend>
                        <input type="datetime-local" value={endDate} onChange={handleChangeEndDate}
                               className="input-challenges"
                               placeholder="Начните вводить" min="1700-01-01" max="2100-01-01"/>
                        <div className="answer" onMouseOver={() => setEndDateHint(!endDateHint)}
                             onMouseOut={() => setEndDateHint(!endDateHint)}></div>
                        <p className="answer-text" style={{display: endDateHint ? "" : "none"}}>Время московское (UTC
                            +3).</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Название курса*</legend>
                        <input type="text" value={stage.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setStage({...stage, name: e.target.value});
                        }} className="input-challenges" placeholder="Название 1"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Описание курса*</legend>
                        <textarea className="input-challenges" value={stage.description}
                                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      setStage({...stage, description: e.target.value});
                                  }} cols={30} rows={10} maxLength={500}
                                  placeholder="Заполните описание (до 500 символов)"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Навыки, требуемые для прохождения (через запятую)*</legend>
                        <input type="text" value={stage.skills} onChange={(e) => {
                            setStage({...stage, skills: e.target.value})
                        }} className="input-challenges" placeholder="Начните вводить"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Настройки видимости</legend>
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.hidden}
                                   onChange={(e) => setStage({...stage, hidden: !stage.hidden})} className="radio-btn"/>
                            <span className="span-radio">Не отображать в разделе "Задания"</span>
                        </label>
                    </fieldset>
                    <fieldset className="block-input">
                        {/*<legend className="legend">Видимость на лендинге</legend>*/}
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.landingVisibility}
                                   onChange={(e) => setStage({...stage, landingVisibility: !stage.landingVisibility})}
                                   className="radio-btn"/>
                            <span className="span-radio">Отображать на лендинге</span>
                        </label>
                    </fieldset>
                </div>
                <StageVideo stage={stage} refreshStage={setStage} setErrorMessage={setErrorMessage}/>
            </div>
        )
    } else if (stage.type === "INFO") {
        return (
            <div className="container-right-block">
                <div className="menu-challenge">
                    <h2 className="headline-right-block">{getStageType()} (ID {stage.id})</h2>
                    <div className="btn-info">
                        <h4 className="btn-info-heading">
                            Внимание
                        </h4>
                        <p className="btn-info-text">
                            Строки отмеченные этим знаком * являются обязательными для заполнения
                        </p>
                    </div>
                    <ModalMessage msg={errorMessage} header={"Ошибка"} _callback={() => setErrorMessage("")}/>
                    <fieldset className="block-input">
                        <legend className="legend">Дата и время начала*</legend>
                        <input type="datetime-local" value={startDate} onChange={handleChangeStartDate}
                               className="input-challenges" placeholder="Начните вводить" min="1700-01-01"
                               max="2100-01-01"/>
                        <div className="answer" onMouseOver={() => setStartDateHint(!startDateHint)}
                             onMouseOut={() => setStartDateHint(!startDateHint)}></div>
                        <p className="answer-text" style={{display: startDateHint ? "" : "none"}}>Время московское (UTC
                            +3).</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Дата и время окончания*</legend>
                        <input type="datetime-local" value={endDate} onChange={handleChangeEndDate}
                               className="input-challenges"
                               placeholder="Начните вводить" min="1700-01-01" max="2100-01-01"/>
                        <div className="answer" onMouseOver={() => setEndDateHint(!endDateHint)}
                             onMouseOut={() => setEndDateHint(!endDateHint)}></div>
                        <p className="answer-text" style={{display: endDateHint ? "" : "none"}}>Время московское (UTC
                            +3).</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Название*</legend>
                        <input type="text" value={stage.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setStage({...stage, name: e.target.value});
                        }} className="input-challenges" placeholder="Название 1"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Краткое описание</legend>
                        <textarea className="input-challenges" value={stage.description}
                                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      setStage({...stage, description: e.target.value});
                                  }} cols={30} rows={15} maxLength={500}
                                  placeholder="Заполните краткое описание (до 500 символов)"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Подробное описание для участников*</legend>
                        <ReactQuill theme="snow" value={task} onChange={setTask}
                                    style={{height: "300px", paddingBottom: "45px"}}/>
                        <div className="answer" onMouseOver={() => setDescriptionHint(!descriptionHint)}
                             onMouseOut={() => setDescriptionHint(!descriptionHint)}></div>
                        <p className="answer-text" style={{display: descriptionHint ? "" : "none"}}>Подробно опишите,
                            какое
                            задание необходимо будет выполнить участникам</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Настройки видимости</legend>
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.hidden}
                                   onChange={(e) => setStage({...stage, hidden: !stage.hidden})} className="radio-btn"/>
                            <span className="span-radio">Не отображать в разделе "Задания"</span>
                        </label>
                    </fieldset>
                    <fieldset className="block-input">
                        {/*<legend className="legend">Видимость на лендинге</legend>*/}
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.landingVisibility}
                                   onChange={(e) => setStage({...stage, landingVisibility: !stage.landingVisibility})}
                                   className="radio-btn"/>
                            <span className="span-radio">Отображать на лендинге</span>
                        </label>
                    </fieldset>
                    <button className="btn-main" id="submit" onClick={handleSubmit}>Сохранить</button>
                    <button className="btn-no" onClick={handleDelete}>Удалить</button>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container-right-block">
                <div className="menu-challenge">
                    <h2 className="headline-right-block">{getStageType()} (ID {stage.id})</h2>
                    <div className="btn-info">
                        <h4 className="btn-info-heading">
                            Внимание
                        </h4>
                        <p className="btn-info-text">
                            Строки отмеченные этим знаком * являются обязательными для заполнения
                        </p>
                    </div>
                    <ModalMessage msg={errorMessage} header={"Ошибка"} _callback={() => setErrorMessage("")}/>
                    <fieldset className="block-input">
                        <legend className="legend">Дата и время начала*</legend>
                        <input type="datetime-local" value={startDate} onChange={handleChangeStartDate}
                               className="input-challenges" placeholder="Начните вводить" min="1700-01-01"
                               max="2100-01-01"/>
                        <div className="answer" onMouseOver={() => setStartDateHint(!startDateHint)}
                             onMouseOut={() => setStartDateHint(!startDateHint)}></div>
                        <p className="answer-text" style={{display: startDateHint ? "" : "none"}}>Время московское (UTC
                            +3).</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Дата и время окончания*</legend>
                        <input type="datetime-local" value={endDate} onChange={handleChangeEndDate}
                               className="input-challenges"
                               placeholder="Начните вводить" min="1700-01-01" max="2100-01-01"/>
                        <div className="answer" onMouseOver={() => setEndDateHint(!endDateHint)}
                             onMouseOut={() => setEndDateHint(!endDateHint)}></div>
                        <p className="answer-text" style={{display: endDateHint ? "" : "none"}}>Время московское (UTC
                            +3).</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Название этапа/задания*</legend>
                        <input type="text" value={stage.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setStage({...stage, name: e.target.value});
                        }} className="input-challenges" placeholder="Название 1"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Краткое описание</legend>
                        <textarea className="input-challenges" value={stage.description}
                                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      setStage({...stage, description: e.target.value});
                                  }} cols={30} rows={15} maxLength={500}
                                  placeholder="Заполните краткое описание (до 500 символов)"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Подробное описание для участников*</legend>
                        <ReactQuill theme="snow" value={task} onChange={setTask}
                                    style={{height: "300px", paddingBottom: "45px"}}/>
                        <div className="answer" onMouseOver={() => setDescriptionHint(!descriptionHint)}
                             onMouseOut={() => setDescriptionHint(!descriptionHint)}></div>
                        <p className="answer-text" style={{display: descriptionHint ? "" : "none"}}>Подробно опишите,
                            какое
                            задание необходимо будет выполнить участникам</p>
                    </fieldset>
                    {stage.type === "TASK" || stage.type === "TASK_FILE" || stage.type === "PROFLINE" ? (
                        <fieldset className="block-input">
                            <legend className="legend">{stage.type === "PROFLINE" ? "ID теста*" : "Ссылка на задание*"}</legend>
                            <input type="text" value={stage.taskLink}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                       setStage({...stage, taskLink: e.target.value});
                                   }} className="input-challenges" placeholder={stage.type === "PROFLINE" ? "Вставьте ID*" : "Вставьте ссылку*"}/>
                        </fieldset>
                    ) : (
                        <>
                            <fieldset className="block-input">
                                <legend className="legend">Выберите тест*</legend>
                                <select value={stage.taskLink} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    setStage({...stage, taskLink: e.target.value})
                                }} className="input-challenges-select">
                                    <option
                                        value={""}>{showSpinner ? "Подождите, идет загрузка..." : "Выберите из списка"}</option>
                                    {tests && tests.map((test: ITest, key) =>
                                        <option value={test.id}>{test.name}</option>
                                    )}
                                </select>
                            </fieldset>
                            <fieldset className="block-input">
                                <legend className="legend">Текст после прохождения тестирования</legend>
                                <ReactQuill theme="snow" value={commonText} onChange={setCommonText}
                                            style={{height: "300px", paddingBottom: "45px"}}/>
                            </fieldset>
                        </>
                    )}

                    <div className="criteria"
                         style={{display: stage.type === "TEST" || stage.type === "ISPTEST" || stage.type === "PROFLINE" ? "none" : ""}}>
                        <fieldset className="block-input">
                            <legend className="legend">Критерии оценки*</legend>
                            {stage.criteriaList && stage.criteriaList.map((criteria: IChallengeStageGradeCriteria) =>
                                <div id="single-criteria" key={criteria.id}>
                                    <div className="block-input-flex">
                                        <input type="text" value={criteria.name}
                                               onChange={(event) => handleCriteriaNameChange(event, criteria.id)}
                                               className="input-challenges" placeholder="Введите критерий"/>
                                        <div className="block-input-flex">
                                            <input type="number" value={criteria.points}
                                                   onChange={(event) => handlePointsChange(event, criteria.id)}
                                                   className="criteria-input"
                                                   placeholder="Баллы"/>
                                            <button className="delete-criteria"
                                                    onClick={() => deleteCriteria(criteria.id)}><img
                                                src="/images/delete_red.svg" alt=""/></button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <p className="summa-criteria">
                                {totalPoints} баллов
                            </p>
                            <button className="btn-main" id="add-criteria" onClick={createCriteria}>Добавить</button>
                        </fieldset>
                    </div>
                    <fieldset className="block-input">
                        <legend className="legend">Минимальное количество баллов для прохождения этапа/задания*</legend>
                        <input type="number" value={stage.passScore}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   setStage({...stage, passScore: toNumber(e.target.value)});
                               }} className="input-challenges" placeholder="введите число"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Ссылка на инструкцию для экспертов</legend>
                        <input type="text" value={stage.juryRules}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   setStage({...stage, juryRules: e.target.value});
                               }} className="input-challenges" placeholder="Ссылка на инструкцию"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Настройки видимости</legend>
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.hidden}
                                   onChange={(e) => setStage({...stage, hidden: !stage.hidden})} className="radio-btn"/>
                            <span className="span-radio">Не отображать в разделе "Задания"</span>
                        </label>
                    </fieldset>
                    <fieldset className="block-input">
                        {/*<legend className="legend">Видимость на лендинге</legend>*/}
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.landingVisibility}
                                   onChange={(e) => setStage({...stage, landingVisibility: !stage.landingVisibility})}
                                   className="radio-btn"/>
                            <span className="span-radio">Отображать на лендинге</span>
                        </label>
                    </fieldset>
                    <fieldset className="block-input">
                        {/*<legend className="legend">Видимость рейтинга</legend>*/}
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" checked={stage.hideRating}
                                   onChange={(e) => setStage({...stage, hideRating: !stage.hideRating})}
                                   className="radio-btn"/>
                            <span className="span-radio">Не отображать рейтинг участников</span>
                        </label>
                    </fieldset>
                    <button className="btn-main" id="submit" onClick={handleSubmit}>Сохранить</button>
                    <button className="btn-no" onClick={handleDelete}>Удалить</button>
                </div>
            </div>
        )
    }
}