import React, {useEffect, useState} from "react";
import {Help} from "../../common/Help";
import {ProfileIndividualHeader} from "../profile/ProfileIndividualHeader";
import {NavigationIndividualMain} from "../navigation/NavigationIndividualMain";
import {UserCertificatesList} from "./right/UserCertificatesList";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {IChallengeWithCertificate} from "../../../types/IChallengeWithCertificate";
import {CONFIG} from "../../../../CONFIG";
import plural from "plural-ru";
import {useSearchParams} from "react-router-dom";
import {Spinner} from "../../common/Spinner";

export const UserCertificates = () => {

    const [rightBlockStyle, setRightBlockStyle] = useState("-100vw");
    const [challengeId, setChallengeId] = useState<number | null>(null);
    const [type, setType] = useState<string | null>(null);
    const [tab, setTab] = useState("");
    const [currentYear, setCurrentYear] = useState<number>();
    const [years, setYears] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setShowSpinner(true);
        setTab(searchParams.get("tab") || "");
        ChallengeAPI.fetchCertificateYears(tab, (response) => {
            if (response.data[0] !== undefined) {
                setYears(response.data);
                if (response.data.length > 0 && !currentYear) {
                    setCurrentYear(response.data[0]);
                }
                ChallengeAPI.fetchCertificateChallenges(searchParams.get("tab") || tab, currentYear || response.data[0], (response) => {
                    setChallenges(response.data);
                });
            }
            setShowSpinner(false);
        });
    }, [tab, currentYear])

    const toggleSettings = (challengeId: number | null, type: string | null) => {
        setChallengeId(challengeId);
        setType(type);
        if (challengeId !== null && type !== null) {
            setRightBlockStyle("0");
        } else {
            setRightBlockStyle("-100vw");
        }
    }

    const getPluralInstance = (num: number) => {
        if (tab === "CERT") {
            return plural(num, "%d сертификат", "%d сертификата", "%d сертификатов")
        } else if (tab === "DIPLOMA") {
            return plural(num, "%d диплом", "%d диплома", "%d дипломов")
        } else {
            return plural(num, "%d грамота", "%d грамоты", "%d грамот")
        }
    }

    if (showSpinner) {
        return (<Spinner text={"Подождите..."} enabled={showSpinner}/>)
    } else {
        return (
            <div className="platform-background">
                <Help challenge={undefined}/>
                <img src="/images/main.jpg" className="image-back-video" alt=""/>
                <div className="left-block">
                    <ProfileIndividualHeader newPreview={""}/>
                    <main className="content">
                        <NavigationIndividualMain />
                        <div className="main-list-center">
                            <div className="my-challenge">
                                <div className="user-info">
                                    <p className="fio-or-name">Достижения</p>
                                </div>
                                <ul className="nav-block-center">
                                    <li className="nav-center-item">
                                        <a href="?tab=CERT" className={tab === "CERT" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Сертификаты</a>
                                    </li>
                                    <li className="nav-center-item">
                                        <a href="?tab=DIPLOMA" className={tab === "DIPLOMA" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Дипломы</a>
                                    </li>
                                    <li className="nav-center-item">
                                        <a href="?tab=CHARTER" className={tab === "CHARTER" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Грамоты</a>
                                    </li>
                                </ul>
                            </div>
                            <ul className="challenge-info">

                                <input type="search" placeholder="Поиск" className="input-challenges"/>
                                {years.length === 0 && tab !== "" ? (
                                    <>
                                        <li className="first-message">
                                            <p className="fio-or-name">
                                                {tab === "CERT" ? "Сертификаты" : tab === "DIPLOMA" ? "Дипломы" : "Грамоты"} не найдены
                                            </p>
                                        </li>
                                    </>
                                ) : <></>}
                                <div className="year-search">
                                    {years && years.map((year: number) =>
                                        <p className={currentYear === year ? "year-cert-active" : "year-cert"} key={year} style={{cursor: "pointer"}} onClick={() => setCurrentYear(year)}>{year}</p>
                                    )}
                                </div>
                                {challenges && challenges.map((challenge: IChallengeWithCertificate) =>
                                    <li className="challenge-card" key={challenge.id}>
                                        <div className="challenge-card-main">
                                            <img onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_challenge.svg"}}
                                                 src={CONFIG.STORAGE_URL + "/" + challenge.userId + "/challenge/" + challenge.id + "/avatar/" + challenge.avatar} alt=""
                                                 className="challenge-avatar" onClick={() => toggleSettings(challenge.id, tab)}/>
                                            <div className="user-info">
                                                <p className="challenge-header" onClick={() => toggleSettings(challenge.id, tab)}>{challenge.name}</p>
                                                <p className="challenge-company">{challenge.orgName}</p>
                                            </div>
                                        </div>
                                        <p className="challenge-description">
                                            {challenge.description}
                                        </p>
                                        <div className="challenge-information">
                                            <a className="challenge-landing" onClick={() => toggleSettings(challenge.id, tab)}>
                                                {getPluralInstance(challenge.num)}
                                            </a>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </main>
                </div>
                <div className="right-block" style={{right: rightBlockStyle}}>
                    <header className="header">
                        <p className="header-back" id="backmenu" onClick={() => toggleSettings(null, null)}>Назад</p>
                        <p className="name-menu">Сертификаты</p>
                    </header>
                    <UserCertificatesList challengeId={challengeId} type={type}/>
                </div>
            </div>
        )
    }
}