import React, {useEffect, useState} from "react";
import {IPublicStageResult} from "../../types/IPublicStageResult";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";
import {useParams} from "react-router-dom";
import {ModalMessage} from "./ModalMessage";
import {CONFIG} from "../../../CONFIG";
import {formatDate} from "../../helpers/DateHelper";
import {getPercentResult} from "../../helpers/ChallengeHelper";

export const PublicStageResult = () => {

    const params = useParams();
    const [error, setError] = useState("");
    const [protocol, setProtocol] = useState<IPublicStageResult>();

    useEffect(() => {
        if (params.protocolId) {
            ChallengeAPI.getPublicResults(params.protocolId, (response) => {
                if (response.status === 200) {
                    setProtocol(response.data);
                } else if (response.status === 404) {
                    window.location.href = "/404";
                } else {
                    setError("Ошибка при получении протокола");
                }
            });
        }

        const handleContextMenu = (e: Event) => {
            e.preventDefault()
        }

        document.addEventListener("contextmenu", handleContextMenu)

        return () => {
            document.removeEventListener("contextmenu", handleContextMenu)
        }
    }, []);

    return (
        <div className="block-print-export">
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <div className="button-add-print fixed-position no-print" onClick={() => window.print()}>Печать</div>
            <div className="text-block-print-export">
                <div className="block-print-export-stam-official">
                    <div>
                        <p className="print-export-first-last-name">
                            {protocol?.resultResponsibleName}
                        </p>
                        <p className="print-export-dolzhnost">
                            {protocol?.resultResponsiblePosition}
                        </p>
                    </div>
                    <div className="block-print-export-stam">
                        <img src={CONFIG.STORAGE_URL + protocol?.signPath} alt="" className="stam-official-podpis" onDragStart={(event)=> event.preventDefault()}/>
                        <img src={CONFIG.STORAGE_URL + protocol?.stampPath} alt="" className="stam-official" onDragStart={(event)=> event.preventDefault()}/>
                    </div>
                </div>
                <h2 className="heading-print-export">
                    ПРОТОКОЛ «{protocol?.challengeInfo?.challengeName}»<br/>«{protocol?.challengeInfo?.stageName}»
                </h2>
                <div className="date-location-print-export">
                    <p className="city-yaer-print-export">
                        {protocol?.showPoints ? "Макс. кол-во баллов: " + protocol.challengeInfo?.maxPoints : <></>}
                    </p>
                    <p className="city-yaer-print-export">
                        {formatDate(protocol?.publicationDate || protocol?.createdAt || "")}
                    </p>
                </div>
                <div className="info-text-print-export">
                    {protocol?.description}
                </div>
                {/*<div className="jury-print-export">
                    <h3 className="heading-jury-print-export">
                        Список жюри (экспертов)
                    </h3>
                    <ul className="jury-print-export-list">
                        <li className="jury-print-export-item">
                            <p className="name-jury-print-expor">
                                Тест Тестов
                            </p>
                            <p className="regalia-jury-print-expor">
                                Эксперт по внедрению живописных видов
                            </p>
                        </li>
                        <li className="jury-print-export-item">
                            <p className="name-jury-print-expor">
                                Владислав Усик
                            </p>
                            <p className="regalia-jury-print-expor">
                                Эксперт по внедрению живописных видов
                            </p>
                        </li>
                        <li className="jury-print-export-item">
                            <p className="name-jury-print-expor">
                                Владислав Усик
                            </p>
                            <p className="regalia-jury-print-expor">
                                Эксперт по внедрению живописных видов
                            </p>
                        </li>
                    </ul>
                </div>*/}
            </div>
            <div className="rating-row-header">
                <p className="rating-cell-header position" style={{display: protocol?.showPlace ? "" : "none"}}>
                    Место
                </p>
                <p className="rating-cell-header fio-rating" style={{display: protocol?.showName ? "" : "none"}}>
                    Команда / ФИО участников
                </p>
                <p className="rating-cell-header university" style={{display: protocol?.showUniversity ? "" : "none"}}>
                    Учебное заведение
                </p>
                <p className="rating-cell-header country" style={{display: protocol?.showCountry ? "" : "none"}}>
                    Страна
                </p>
                <p className="rating-cell-header city-rating" style={{display: protocol?.showCity ? "" : "none"}}>
                    Населённый пункт
                </p>
                <p className="rating-cell-header point" style={{display: protocol?.showPoints ? "" : "none"}}>
                    Балл
                </p>
                <p className="rating-cell-header percent" style={{display: protocol?.showPercent ? "" : "none"}}>
                    %
                </p>
                <p className="rating-cell-header percent" style={{display: protocol?.showPass ? "" : "none"}}>
                    Допуск
                </p>
            </div>

            {protocol?.results?.filter((r) => r.points > 0 || r.place > 0).map((r, index) =>
                <div className="rating-row" key={index}>
                    <p className="rating-cell position" style={{display: protocol?.showPlace ? "" : "none"}}>
                        {r.place}
                    </p>
                    <p className="rating-cell fio-rating" style={{display: protocol?.showName ? "" : "none"}}>
                        {r.name.split(":").length > 1 ?
                            <>
                                <div dangerouslySetInnerHTML={{ __html:
                                        r.name.split(":")[0] + ":<br/>" +
                                        r.name.split(":")[1].split(",").join("<br/>")}} />
                            </>
                            :
                            r.name
                        }

                    </p>
                    <p className="rating-cell university" style={{display: protocol?.showUniversity ? "" : "none"}}>
                        {r.universityName}
                    </p>
                    <p className="rating-cell country" style={{display: protocol?.showCountry ? "" : "none"}}>
                        {r.country}
                    </p>
                    <p className="rating-cell city-rating" style={{display: protocol?.showCity ? "" : "none"}}>
                        {r.city}
                    </p>
                    <p className="rating-cell point" style={{display: protocol?.showPoints ? "" : "none"}}>
                        {r.points}
                    </p>
                    <p className="rating-cell percent" style={{display: protocol?.showPercent ? "" : "none"}}>
                        {getPercentResult(protocol?.stage?.criteriaList, r.points, protocol?.challengeInfo?.maxPoints)}
                    </p>
                    <p className="rating-cell percent" style={{display: protocol?.showPass ? "" : "none"}}>
                        {r.approvement ? "Да" : "Нет"}
                    </p>
                </div>
            )}
        </div>
    )
}