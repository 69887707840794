import React, {useEffect, useState} from "react";
import API from "../../../requests/_axios";
import {IProfilePersonalForm} from "../../types/IProfilePersonalForm";
import {CONFIG} from "../../../CONFIG";
import {NavigationUniversity} from "./NavigationUniversity";
import {ProfileUniversityHeader} from "./ProfileUniversityHeader";
import {ProfileIndividualView} from "../common/ProfileIndividualView";
import {toNumber} from "lodash";
import {useSearchParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import SessionModel from "../../../stores/SessionModel";

export const UniversityStudents = () => {

  const [students, setStudents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [rawTotal, setRawTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [hideStat, setHideStat] = useState(false);
  const [currentForm, setCurrentForm] = useState<IProfilePersonalForm>({
    firstname: "",
    lastname: "",
    patronymic: "",
    telegram: "",
    birthDate: "",
    skills: "",
    phone: "",
    fias: ""
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(true);
  const [tab, setTab] = useState("students");
  const [error, setError] = useState("");

  useEffect(() => {
    if (SessionModel.getCurrentUserAuthData().type !== "EDU") {
      localStorage.getItem("token");
      window.location.href = "/";
    }
    setTab(searchParams.get("tab") || "students");
    setSearchQuery(searchParams.get("search") || searchQuery);
    setCurrentPage(currentPage || toNumber(searchParams.get("page")) || 0);
    fetchMoreData();
  }, [searchTrigger]);

  useEffect(() => { trigSearch(); }, [filterYear]);

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
    setError("");
    API.get(`/challenge/api/v1/university/students?page=${currentPage}&search=${searchQuery}&year=${filterYear}`).then((response) => {
      if(response.status === 200) {
        setStudents(students.concat(response.data.items));
        setTotalPages(response.data.totalPages);
        setTotal(response.data.total);
        setRawTotal(response.data.rawTotal);
        if (response.data.totalPages <= currentPage) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setHasMore(false);
        setError("1");
      }
    })
  }

  const handleShowDetails = (profile: IProfilePersonalForm) => {
    setCurrentForm(profile);
    // @ts-ignore
    document.querySelector('.right-block').style.right = "0"
  }
  const handleCloseDetails = () => {
    //setCurrentUniversityId(null);
    // @ts-ignore
    document.querySelector('.right-block').style.right = "-100vw"
  }
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      trigSearch();
    }
  }

  const trigSearch = () => {
    setStudents([]);
    setCurrentPage(0);
    setHideStat(!!searchQuery);
    setSearchTrigger(!searchTrigger);
  }

  const getFilterYears = () : number[] => {
    return [...Array(new Date().getFullYear() + 1).keys()].slice(new Date().getFullYear() - 2).reverse();
  }

  return (
      <div className="platform-background">
        <img src="/images/main.jpg" className="image-back-video" alt=""/>
        <div className="left-block">
          <ProfileUniversityHeader newPreview={null}/>
          <main className="content">
            <NavigationUniversity />
            <div className="main-list-center">
              <div className="my-challenge">
                <div className="user-info">
                  <p className="fio-or-name">Участники</p>
                </div>
                <ul className="nav-block-center">
                  <li className="nav-center-item">
                    <a href="?tab=students" className={tab === "students" || tab === "" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Все</a>
                  </li>
                  <li className="nav-center-item">
                    <a href="?tab=challenges" className={tab === "challenges" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>По мероприятиям</a>
                  </li>
                  <li className="nav-center-item">
                    <a href="?tab=unloads" className={tab === "unloads" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Выгрузки</a>
                  </li>
                  {/*<li className="nav-center-item">
                    <a href="my_students_challenge.html" className="link-nav-center-item non-active-nav">Конкурсы</a>
                  </li>
                  <li className="nav-center-item">
                    <a href="" className="link-nav-center-item non-active-nav">Активность</a>
                  </li>*/}
                </ul>
              </div>
              <ul className="challenge-info" id="scrollableList">
                <select className="filter-list" onChange={(e) => setFilterYear(e.target.value)}>
                  <option value="">Все года</option>
                  {getFilterYears().map((year, idx) =>
                      <option value={year} key={idx}>{year}</option>
                  )}
                </select>
                <input type="search" placeholder="Поиск" className="input-challenges" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(e.target.value);
                }} onKeyDown={handleKeyDown}/>
                  <li className="first-message">
                    <p className="city">
                      В этом разделе отображается список студентов и выпускников, которые указали в своей регистрационной анкете вашу организацию.
                    </p>
                  </li>
                <li className="first-message" style={{display: hideStat ? "none" : ""}}>
                  <p className="fio-or-name">
                    <span className="non-active-students">Всего</span>
                  </p>
                  {/*<p className="description-stat">
                    Вы можете просматривать данные только тех участников из вашей организации, которые дали на это согласие.
                  </p>*/}
                  <div className="stat-students-wrap">
                    <div className="stat-students-info">
                      {/*<h5 className="stat-students-heading">
                        Зарегистрировано
                      </h5>*/}
                      <p className="stat-students-number">
                        {total}
                      </p>
                    </div>
                    {/*<div className="stat-students-info">
                      <h5 className="stat-students-heading">
                        Разрешили просмотр
                      </h5>
                      <p className="stat-students-number">
                        {total}
                      </p>
                    </div>*/}
                  </div>
                  {/*<div className="stat-students-noview">
                    <p className="stat-students-noview-num">{ ((rawTotal === 0 ? 0 : (100 / rawTotal * total))).toFixed(1)}%</p>
                    <p className="stat-students-noview-text">
                      Участников из вашей организации, разрешивших просмотр их данных</p>
                  </div>*/}
                </li>
                <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>
                  У вас нет разрешения на просмотр персональных данных. Вероятнее всего, у вас не подтверждены или истекли полномочия. Для уточнения причин обратитесь в техподдержку по адресу <a href="mailto:info@braim.org">info@braim.org</a> или в Телеграм <a href="https://t.me/braim_org" target="_blank">@braim_org</a>
                </p>
                <InfiniteScroll next={fetchMoreData} hasMore={hasMore} loader={<h4>Загрузка...</h4>} dataLength={students.length} scrollableTarget="scrollableList" className="challenge-info">
                {students && students.map((p: IProfilePersonalForm, i) =>
                    <li className="challenge-card">
                      <div className="challenge-card-main">
                        <img onClick={() => handleShowDetails(p)} src={CONFIG.STORAGE_URL + "/" + p.userId + "/avatar/" + p.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                             alt=""
                             className="challenge-avatar"/>
                          <div className="user-info">
                            <p className="challenge-header" onClick={() => handleShowDetails(p)}>{(p.firstname || "") + " " + (p.lastname || "")}
                            </p>
                            <p className="challenge-company">{p.city ? (p.city) : "Не указано"}</p>
                          </div>
                      </div>
                      <div className="student-info">
                        <p className="student-info-kurs">{p.course && p.course > 0 ? (p.course + " курс") : "курс не указан"}</p>
                        <p className="student-info-facult">
                          {p.faculty}
                        </p>
                      </div>
                      {/*<div className="student-stat">
                        <p className="name-stat">Все конкурсов</p>
                        <p className="number-stat">15</p>
                      </div>
                      <div className="student-stat">
                        <p className="name-stat">Участвует</p>
                        <p className="number-stat">3</p>
                      </div>
                      <div className="student-stat-wins">
                        <p className="name-stat-white">Побед</p>
                        <p className="number-stat-white">0</p>
                      </div>
                      <div className="student-stat-fale">
                        <p className="name-stat-white">Поражения</p>
                        <p className="number-stat-white">12</p>
                      </div>*/}
                      <div className="challenge-information">
                        {/*<a href="/" className="challenge-landing">
                          Показать конкурсы
                        </a>*/}
                        <p>&nbsp;</p>
                        <p className="message-user">
                          <a href={"https://t.me/" + p?.telegram} target="_blank">Сообщение</a>
                        </p>
                      </div>
                    </li>


                    /*<li className="challenge-card">
                      <div className="challenge-card-main">
                        <img onClick={() => handleShowDetails(p)} src={CONFIG.STORAGE_URL + "/" + p.userId + "/avatar/" + p.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                             alt=""
                             className="challenge-avatar"/>
                          <div className="user-info">
                            <p className="challenge-header" onClick={() => handleShowDetails(p)}>{(p.firstname || "") + " " + (p.lastname || "")}
                            </p>
                            <p className="challenge-company">{p.city && p.region ? (p.city + ", " + p.region) : "Не указано"}</p>
                          </div>
                      </div>
                      <div className="challenge-information">
                        <a href="/" className="challenge-activity">
                          Где участвует
                        </a>
                        <p className="message-user">
                          <a href={"https://t.me/" + p?.telegram} target="_blank">Сообщение</a>
                        </p>
                      </div>
                    </li>*/
                )}
                </InfiniteScroll>
              </ul>
          </div>
          </main>
          </div>
        <div className="right-block" style={{right: "-100vw"}}>
          <header className="header">
            <p className="header-back" onClick={handleCloseDetails} id="backmenu">Назад</p>
            <p className="name-menu">Информация</p>
          </header>
          <ProfileIndividualView currentForm={currentForm} team={undefined} enableRecruitmentPanel={false}/>
        </div>
      </div>
  );
}