import React, {useEffect, useState} from "react";
import {ProfileAdminHeader} from "./ProfileAdminHeader";
import {ProfileNavigationAdmin} from "./ProfileNavigationAdmin";
import {CONFIG} from "../../../CONFIG";
import {IProfilePersonalForm} from "../../types/IProfilePersonalForm";
import {toNumber} from "lodash";
import {useSearchParams} from "react-router-dom";
import API from "../../../requests/_axios";
import {ProfileIndividualDetailsEditor} from "../common/ProfileIndividualDetailsEditor";
import SessionModel from "../../../stores/SessionModel";
import InfiniteScroll from "react-infinite-scroll-component";
import {ModalMessage} from "../common/ModalMessage";
import * as SsoAPI from "../../../requests/SsoAPI";

export const UserList = () => {

    const [userId, setUserId] = useState<number | undefined | null>(null);
    const [profiles, setProfiles] = useState([]);
    const [tab, setTab] = useState("all");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchInProgress, setSearchInProgress] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasMore, setHasMore] = useState(true);
    const [searchTrigger, setSearchTrigger] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        setTab(searchParams.get("tab") || "all");
        if (SessionModel.getCurrentUserAuthData().type !== "ADMIN") {
            localStorage.getItem("token");
            window.location.href = "/";
        }
        setSearchQuery(searchParams.get("search") || searchQuery);
        setCurrentPage(currentPage || toNumber(searchParams.get("page")) || 0);
        fetchMoreData();
    }, [searchTrigger]);

    const fetchMoreData = () => {
        setCurrentPage(currentPage + 1);
        setSearchInProgress(true);
        API.get("/challenge/api/v1/personal/profile/search?query=" + encodeURIComponent(searchQuery) + "&page=" + currentPage + "&filter=" + searchParams.get("tab"))
            .then((response) => {
                if(response.status === 200) {
                    setProfiles(profiles.concat(response.data.items));
                    setTotalPages(response.data.totalPages);
                    setTotalItems(response.data.total);
                    if (response.data.totalPages <= currentPage) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                }
                setSearchInProgress(false);
            });
    }

    const handleDelete = (id: number) => {
        setError("");
        if(confirm("Уверен, братуха? Пути назад нет"))
            API.delete("/challenge/api/v1/personal/profile/" + id).then((response => {
                if(response.status === 200)
                { // @ts-ignore
                    setProfiles(profiles.filter(item => item.userId !== id));
                } else {
                    setError("Нельзя удалить пользователя, поскольку он выступает в роли капитана")
                }
            }));
    }

    const handleShowDetails = (userId: number) => {
        setUserId(userId);
        // @ts-ignore
        document.querySelector('.right-block').style.right = "0"
    }
    const handleCloseDetails = () => {
        setUserId(null);
        // @ts-ignore
        document.querySelector('.right-block').style.right = "-100vw"
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setProfiles([]);
            setCurrentPage(0);
            setSearchTrigger(!searchTrigger);
        }
    }
    const ban = (userId: number) => {
        API.post(`/challenge/api/v1/personal/profile/${userId}/ban`)
            .then((response) => {
                if(response.status === 200) {
                    window.location.reload();
                }
                setSearchInProgress(false);
            });
    }

    return (
        <div className="platform-background">
            <img src="/images/main.jpg" className="image-back-video" alt=""/>
            <div className="left-block">
                <ProfileAdminHeader/>
                <main className="content">
                    <ProfileNavigationAdmin />
                    <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                    <div className="main-list-center">
                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Пользователи</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href="/users/?tab=all" className={tab === "all" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Все</a>
                                </li>
                                <li className="nav-center-item">
                                    <a href="/users/?tab=banned" className={tab === "banned" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Заблокированные</a>
                                </li>
                            </ul>
                        </div>
                        <ul className="challenge-info" id="scrollableList">
                            <input type="text" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchQuery(e.target.value);
                            }} onKeyDown={handleKeyDown} placeholder="ФИО, Email или телефон" className="input-challenges"/>
                            <div className="first-message">Всего: {totalItems}</div>
                            {!searchInProgress && profiles.length === 0 ? (
                                <div className="first-message-error" style={{display: profiles.length === 0 ? "" : "none"}}>
                                    <p className="fio-or-name">
                                        Поиск не дал результатов
                                    </p>
                                </div>
                            ) : (<></>)}
                            <InfiniteScroll next={fetchMoreData} hasMore={hasMore} loader={<h4>Загрузка...</h4>} dataLength={profiles.length} scrollableTarget="scrollableList" className="challenge-info">
                            {profiles && profiles.map((p: IProfilePersonalForm, i) =>
                                <li key={i} className="challenge-card">
                                    <div className="challenge-card-main">
                                        <img onClick={() => handleShowDetails(p.userId || 0)} src={CONFIG.STORAGE_URL + "/" + p.userId + "/avatar/" + p.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                             alt=""
                                             className="challenge-avatar"/>
                                        <div className="user-info">
                                            <p className="challenge-header" onClick={() => handleShowDetails(p.userId || 0)}>{p.firstname + " " + p.lastname}</p>
                                            <p className="city">{p.country ? p.country + ',' : ""} {p.city}</p>
                                            <p>{p.username}</p>
                                            <p>{p.phone}</p>
                                        </div>
                                    </div>
                                    <div className="block-input">
                                        <p className="legend-about">
                                            Навыки
                                        </p>
                                        <ul className="interests">
                                            {
                                                p.skills && p.skills.split(",").map((skill, i) =>
                                                    <li key={i} className="interest-item-comand">
                                                        {skill.trim()}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    <div className="block-input">
                                        {p.isBanned ? <button className="btn-main" onClick={() => ban(p.userId || 0)}>Разблокировать</button> : <button className="btn-no" onClick={() => ban(p.userId || 0)}>Заблокировать</button>}
                                    </div>
                                    <div className="block-input">
                                        <button className="btn-main" onClick={() => SsoAPI.loginAs(p.username || "")}>Войти ╭ᑎ╮</button>
                                    </div>
                                    <a onClick={() => handleDelete(p.userId || 0)} className="challenge-activity-no">
                                        Удалить
                                    </a>
                                </li>
                            )}
                            </InfiniteScroll>
                        </ul>
                    </div>
                </main>
            </div>
            <div className="right-block" style={{right: "-100vw"}}>
                <header className="header">
                    <p className="header-back" onClick={handleCloseDetails} id="backmenu">Назад</p>
                    <p className="name-menu">Об организации</p>
                </header>
                <div className="container-right-block">
                    <ProfileIndividualDetailsEditor userId={userId} currentForm={undefined}/>
                </div>
            </div>
        </div>
    );
}