import React, {useEffect, useState} from "react";
import API from "../../../requests/_axios";
import '../../../styles/autocomplete.css';
import {IProfileUniversityForm} from "../../types/IProfileUniversityForm";
import {CONFIG} from "../../../CONFIG";
import SessionModel from "../../../stores/SessionModel";
import {useNavigate} from "react-router-dom";
import {ProfileUniversityHeader} from "./ProfileUniversityHeader";
import {NavigationUniversity} from "./NavigationUniversity";
import {ProfileUniversityDetailsEditor} from "../common/ProfileUniversityDetailsEditor";
import {UniversityChallengePicker} from "./UniversityChallengePicker";
import {Help} from "../common/Help";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";

export const ProfileUniversity: React.FC<{userId: number | null}> = ({userId}) => {
  const [form, setForm] = useState<IProfileUniversityForm>({
    shortName: "",
    fullName:"",
    rectorName:"",
    responsiblePersonName:"",
    responsiblePersonEmail:"",
    responsiblePersonPosition:"",
    responsiblePersonPhone:"",
    phone: "",
    fias: "",
    email: "",
    type: 1,
    site:"",
    city:"",
    region:""
  });
  const[preview, setPreview] = useState<any>();
  const[currentUserId, setCurrentUserId] = useState<any>(null);
  const[showUploadNew, setShowUploadNew] = useState(false);
  const[error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    ChallengeAPI.updateLastSeenUniversity();
    let url = "/challenge/api/v1/university/profile";
    if(userId !== null && userId !== undefined)
      url = url + "/" + userId;
    API.get(url, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
      setForm({...form,
        shortName: response.data.shortName,
        fullName: response.data.fullName,
        username: response.data.username,
        rectorName: response.data.rectorName,
        responsiblePersonName: response.data.responsiblePersonName,
        phone: response.data.phone,
        site: response.data.site,
        responsiblePersonEmail: response.data.responsiblePersonEmail,
        responsiblePersonPosition: response.data.responsiblePersonPosition,
        responsiblePersonPhone: response.data.responsiblePersonPhone,
        email: response.data.email,
        type: response.data.type,
        region: response.data.region || "",
        city: response.data.city || "",
        onModeration: response.data.onModeration,
        isApproved: response.data.isApproved,
        fias: response.data.fias,
        mailing: response.data.mailing,
        inn: response.data.inn,
        officialLetter: response.data.officialLetter,
        isRejected: response.data.isRejected,
        userId: response.data.userId
      });
      if(response.data.avatar !== null)
        setPreview(CONFIG.STORAGE_URL + "/" + ((userId !== null && userId !== undefined) ? userId : SessionModel.getCurrentUserAuthData().id) + "/avatar/" + response.data.avatar);
      else
        setPreview("/images/no_ava_university.svg");
    });
  },[]);

  const [avatar, setAvatar] = React.useState<File>();

  const handleAvatarChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    const fileList = e.target.files;

    if (!fileList) return;
    if (fileList[0].size >= 5000000) {
      setError("Размер изображения не должен превышать 5Мб.");
      return;
    }

    setAvatar(fileList[0]);

    const formData = new FormData();
    formData.append("file", fileList[0], fileList[0].name);
    API.post("/challenge/api/v1/university/profile/avatar", formData, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
      if(response.status === 200) {
        setPreview(CONFIG.STORAGE_URL + "/" + SessionModel.getCurrentUserAuthData().id + "/avatar/" + response.data.avatar);
      } else {
        setError("Изображение должно быть в формате JPG и размер не должен превышать 5 Мб.")
      }
    });
  };


  const handleOfficialLetterChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    const fileList = e.target.files;

    if (!fileList) return;
    if (fileList[0].size >= 5000000) {
      setError("Размер файла не должен превышать 5Мб.");
      return;
    }

    const formData = new FormData();
    formData.append("file", fileList[0], fileList[0].name);
    API.post("/challenge/api/v1/university/profile/official-letter", formData, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
      if(response.status === 200) {
        window.location.reload();
      } else {
        setError("Размер файла не должен превышать 5Мб.")
      }
    });
  };

  const handleSubmitModeration = () => {
    API.post("/challenge/api/v1/university/profile/submit", {}, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
      setError("");
      if(response.status === 400) {
        if(response.data.code === "009-029")
          setError("Не выбран ни один конкурс");
        else
          setError("Не заполнены обязательные поля профиля");
      } else {
        window.location.reload();
      }
    })
  }

  const handleShowDetails = () => {
    handleClosePicker();
    setCurrentUserId(SessionModel.getCurrentUserAuthData().userId);
    // @ts-ignore
    document.querySelector('#details-panel').style.right = "0"
  }

  const handleCloseDetails = () => {
    setCurrentUserId(null);
    // @ts-ignore
    document.querySelector('#details-panel').style.right = "-100vw"
  }

  const handleShowPicker = () => {
    handleCloseDetails();
    // @ts-ignore
    document.querySelector('#picker-panel').style.right = "0"
  }

  const handleClosePicker = () => {
    // @ts-ignore
    document.querySelector('#picker-panel').style.right = "-100vw"
  }

  const handleExit = () => {
    localStorage.removeItem("token");
    navigate("/");
  }

  return (
      <div className="platform-background">
        <Help challenge={undefined}/>
        <video autoPlay muted loop playsInline className="image-back-video">
          <source src={require("../../../../public/images/main_video.mp4").default}/>
        </video>
        <div className="left-block">
          <ProfileUniversityHeader newPreview={preview}/>
          <main className="content">
            <NavigationUniversity />
            <div className="block-center">
              <div className="user-profile">
                <label htmlFor="addimage" className="addpost-btn">
                  <img src={preview} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_university.svg"}} title="Добавьте логотип вашего учебного заведения" className="avater-user"/>
                  <input type="file" name="" accept=".jpg,.jpeg" id="addimage" onChange={handleAvatarChange} style={{display: 'none' }}/>
                </label>
                  <div className="user-info">
                    <p className="fio-or-name">{form.shortName || "Название организации"}</p>
                    <p className="city">{form.city !== "" && form.region !== "" ? (form.region + ", " + form.city) : ("Город")}</p>
                  </div>
              </div>
              <div className="user-profile-info">
                <button className="btn" id="personal" style={!form.onModeration && !form.isApproved && !form.isRejected ? {} : {display: 'none'}} onClick={handleShowDetails}>
                  <p className="btn-heading ">
                    Данные об организации*
                  </p>
                  <p className="btn-description">
                    Заполните профиль организации. Обратите внимание,
                    все поля в данной форме являются обязательными
                  </p>
                </button>
                <button className="btn-deactive" style={form.onModeration && !form.isRejected ? {} : {display: 'none'}} onClick={handleShowDetails}>
                  <p className="btn-heading lock">
                    Данные об организации
                  </p>
                  <p className="btn-description">
                    Ваша заявка принята и будет рассмотрена в течение пяти рабочих дней.
                    В это время с вами может связаться наш сотрудник для уточнения информации.
                    Результат рассмотрения заявки будет направлен вам на электронную почту,
                    указанную при регистрации
                  </p>
                </button>
                <button className="btn-complete" style={form.isApproved && !form.onModeration && !form.isRejected ? {} : {display: 'none'}} onClick={handleShowDetails}>
                  <p className="btn-heading complete">
                    Данные об организации
                  </p>
                  <p className="btn-description">
                    Все данные заполнены, для изменения данных
                    обратитесь в техподдержку Braim: <a href="mailto:info@braim.org" className="active-link">info@braim.org</a>
                  </p>
                </button>
                <button className="btn-complete" style={form.isRejected ? {display: ''} : {display: 'none'}}>
                  <p className="btn-heading lock" style={{color: "#FF3D00"}}>
                    Данные об организации
                  </p>
                  <p className="btn-description" style={{color: "#FF3D00"}}>
                    К сожалению, ваша заявка не прошла проверку.
                    Свяжитесь с нами по электронной почте <a href="mailto:info@braim.org" className="active-link">info@braim.org</a> или телефону +7 (499) 703-39-49 для уточнения информации.
                  </p>
                </button>
                <button className="btn" onClick={handleShowPicker} style={form.isRejected ? {display: 'none'} : {display: ''}}>
                  <p className="btn-heading kubok">
                    Мероприятия*
                  </p>
                  <p className="btn-description">
                    Отметьте мероприятия, в которых потенциально могут принять участие ваши представители. Ваш выбор не ограничивает участников в выборе мероприятия.
                  </p>
                </button>
                <button className="btn" style={{display: form.officialLetter || form.isRejected ? "none" : ""}}>
                  <p className="btn-heading ">
                    Документы*
                  </p>
                  <p className="btn-description">
                    Загрузите письмо за подписью руководства вашего учебного заведения о
                    предоставлении вам полномочий на платформе Braim (в формате pdf, размером не более 5 Мб) <a
                      href={`${CONFIG.STORAGE_URL}/letter_template.docx`} className="active-link">Скачать шаблон</a>
                  </p>
                  <label className="load-file-input">
                    <span>Загрузите файл</span>
                    <input type="file" onChange={handleOfficialLetterChange} className="load-file-hidden"/>
                  </label>
                </button>
                <button className="btn-complete" style={{display: form.officialLetter && !form.isRejected ? "" : "none"}}>
                  <p className="btn-heading complete">
                    Документы загружены
                  </p>
                  <p className="btn-description">
                    Ваши документы <a className="active-link" href={`${CONFIG.STORAGE_URL}/${SessionModel.getCurrentUserAuthData().id}/officialLetter/${form.officialLetter}`} target="_blank">загружены.</a>&nbsp;В случае изменения представителя или сроков полномочия представителя организации необходимо обратиться в техподдержку Braim: <a href="mailto:info@braim.org" className="active-link">info@braim.org</a><br/>
                    <a href={`${CONFIG.STORAGE_URL}/letter_template.docx`} className="active-link">Скачать шаблон</a>
                  </p>
                  <label className="load-file-input" style={{display: showUploadNew ? "" : "none"}}>
                    <span>Загрузите файл</span>
                    <input type="file" onChange={handleOfficialLetterChange} className="load-file-hidden"/>
                  </label>
                </button>
                <p className="btn">
                  <p className="btn-heading">
                    Инструкции и информационные материалы
                  </p>
                  <p className="btn-description">
                    Ознакомьтесь с <a href="https://disk.yandex.ru/d/IdD-UYlBkhaG3w" target="_blank" className="active-link">видеообзором</a> платформы Braim
                  </p>
                  <label className="load-file-input" style={{display: showUploadNew ? "" : "none"}}>
                    <span>Загрузите файл</span>
                    <input type="file" onChange={handleOfficialLetterChange} className="load-file-hidden"/>
                  </label>
                </p>
                <div id="error" style={{ display: error === "" ? "none" : "" }} className="error-message">{error}</div>
                <button className="btn-arrow" onClick={handleSubmitModeration} style={form.onModeration || form.isApproved || form.isRejected ? {display: 'none'} : {}}>Отправить на модерацию</button>
                <button className="btn-exit" onClick={handleExit}>Выход из профиля</button>
                {/*<a href="" className="link-no">Удалить аккаунт</a>*/}
              </div>
            </div>
          </main>
        </div>
        <div className="right-block" id="details-panel" style={{right: "-100vw"}}>
          <header className="header">
            <p className="header-back" id="backmenu" onClick={handleCloseDetails}>Назад</p>
            <p className="name-menu">Организация</p>
          </header>
          <ProfileUniversityDetailsEditor currentForm={form} userId={currentUserId}/>
        </div>
        <div className="right-block" id="picker-panel" style={{right: "-100vw"}}>
          <header className="header">
            <p className="header-back" id="backmenu" onClick={handleClosePicker}>Назад</p>
            <p className="name-menu">Мои конкурсы</p>
          </header>
          <UniversityChallengePicker/>
        </div>
      </div>
  )
}