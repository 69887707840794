import React, {useState} from "react";
import {IBanModal} from "../../types/IBanModal";

export const ModalBanMessage: React.FC<{
    banDetails: IBanModal | undefined,
    _callbackCancel: () => void,
    _callbackBan: (id: number, reason: string) => void}> = ({banDetails, _callbackCancel, _callbackBan}) => {

    const [reason, setReason] = useState("");
    const [sending, setSending] = useState(false);

    const ban = () => {
        if (sending || !banDetails) return;
        if (!reason) {
            alert("Введите причину блокировки");
            return;
        }
        setSending(true);
        _callbackBan(banDetails.id, reason);
    }

    if (banDetails) {
        return (
            <div className="modal-message">
                <div className="modal-block-message" style={{padding: "10px"}}>
                    <div className="modal-header">
                        <h5 className="modal-heading">{banDetails.title}</h5>
                        <div className="modal-close" onClick={_callbackCancel}></div>
                    </div>
                    <p className="modal-message-text" dangerouslySetInnerHTML={{__html: banDetails.text || ""}}></p>
                    <textarea rows={5} placeholder="Причина блокировки" className="input-challenges" onChange={(e) => setReason(e.target.value)}/>
                    <button className="btn-main" onClick={ban}>{sending ? "Подождите..." : "Заблокировать"}</button><button className="btn-no" onClick={_callbackCancel}>Отмена</button>
                </div>
            </div>)
    } else {
       return (<></>)
    }
}