import React, {useEffect, useState} from "react";
import {CertificateSettings} from "./right/CertificateSettings";
import * as CertificateAPI from "../../../../requests/CertificateAPI";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {IChallengeCertificate} from "../../../types/IChallengeCertificate";
import {CONFIG} from "../../../../CONFIG";
import {useSearchParams} from "react-router-dom";
import {CertificatePreview} from "./CertificatePreview";
import {ModalMessage} from "../ModalMessage";
import {OrgHeader} from "../OrgHeader";
import {OrgNavigation} from "../OrgNavigation";
import {BlankPage} from "../BlankPage";
import SessionModel from "../../../../stores/SessionModel";

export const Certificate = () => {

    const [rightBlockStyle, setRightBlockStyle] = useState("-100vw");
    const [currentCertificate, setCurrentCertificate] = useState<string | null>(null);
    const [certificateList, setCertificateList] = useState([]);
    const [filter, setFilter] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [previewCertificateId, setPreviewCertificateId] = useState<string | undefined>();
    const [showPopupMenu, setShowPopupMenu] = useState(false);
    const [error, setError] = useState("");
    const [hasCertificatePlan, setHasCertificatePlan] = useState(false);
    const [isApproved, setIsApproved] = useState(false);

    useEffect(() => {
        getCertificatePlan();
        setFilter(searchParams.get("filter") || "published");
        loadCertificates();
    },[]);

    const getCertificatePlan = () => {
        CertificateAPI.getActivePlan((response) => {
            if (response.status === 200) {
                setHasCertificatePlan(true);
            }
        });
        ChallengeAPI.getProfile((response) => {
            if (response.status === 200) {
                setIsApproved(response.data.isApproved);
            }
        })
    }

    const loadCertificates = () => {
        setCertificateList([]);
        CertificateAPI.getChallengeCertificates(searchParams.get("filter") || "published", (response) => {
            if (response.status === 200) {
                setCertificateList(response.data);
            } else {
                setError("Ошибка при загрузке списка сертификатов");
            }
        })
    }

    const toggleSettings = (certificateId: string | null) => {
        setShowPopupMenu(false);
        setCurrentCertificate(null);
        if (certificateId !== null) {
            setRightBlockStyle("0");
        } else {
            setRightBlockStyle("-100vw");
        }
        setTimeout(() => {
            setCurrentCertificate(certificateId)
        }, 100 );
    }

    const deleteCertificate = (certificateId: string | undefined) => {
        if(certificateId && confirm("Уверены, что хотите удалить сертификат?")) {
            CertificateAPI.deleteChallengeCertificate(certificateId, (response) => {
                if (response.status === 200) {
                    loadCertificates();
                } else {
                    setError("Нельзя удалить сертификат, поскольку он уже выдан участникам");
                }
            })
        }
    }

    const publish = (certificateId: string | undefined) => {
        if (certificateId) {
            CertificateAPI.publish(certificateId, (response) => {
                if (response.status === 200) {
                    loadCertificates();
                } else {
                    setError("Нельзя перенести сертификат в черновики, поскольку он уже выдан участникам");
                }
            })
        }
    }

    const closePreview = () => {
        setPreviewCertificateId(undefined);
    }
    
    if ((SessionModel.isOrg() || SessionModel.isSub()) && !hasCertificatePlan && !isApproved) {
        return <BlankPage message={"В этом разделе вы можете создавать сертификаты/дипломы для ваших мероприятий, созданных в разделе \"Мероприятия\". Для доступа к функционалу вам необходимо подключить данный сервис в разделе \"Тарифы\", далее \"Тарифы\", \"Дополнительные сервисы\""}/>
    } else if ((SessionModel.isOrg() || SessionModel.isSub()) && !hasCertificatePlan && isApproved) {
        return <BlankPage message={"В этом разделе вы можете создавать сертификаты/дипломы для ваших мероприятий, созданных в разделе \"Мероприятия\". Для доступа к функционалу вам необходимо подключить данный сервис в разделе \"Тарифы\", далее \"Тарифы\", \"Дополнительные сервисы\". Посмотрите <a href=\"https://disk.yandex.ru/d/gFdHsywtBCO5vw\" target=\"_blank\" class=\"active-link\">видеообзор</a> сервиса \"Достижения\""}/>
    } else {
        return (
            <>
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="platform-background">
                    <img src="/images/main.jpg" className="image-back-video" alt=""/>
                    <div className="left-block">
                        <OrgHeader preview={null}/>
                        <main className="content">
                            <OrgNavigation />
                            <div className="main-list-center">
                                <button className="button-add" id="add" onClick={() => setShowPopupMenu(true)}>
                                    Создать
                                </button>
                                <div className="button-add-popup" style={{display: showPopupMenu ? "" : "none"}}>
                                    <button className="add-stage" onClick={() => toggleSettings("DIPLOMA")}>Диплом</button>
                                    <button className="add-stage" onClick={() => toggleSettings("CERT")}>Сертификат</button>
                                    <button className="add-stage" onClick={() => toggleSettings("CHARTER")}>Грамота</button>
                                </div>
                                <div className="my-challenge">
                                    <div className="user-info">
                                        <p className="fio-or-name">Достижения</p>
                                    </div>
                                    <ul className="nav-block-center">
                                        <li className="nav-center-item">
                                            <a href="/certificates?filter=published" className={!filter || filter === "published" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Сохраненные</a>
                                        </li>
                                        <li className="nav-center-item">
                                            <a href="/certificates?filter=draft" className={filter === "draft" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Черновики</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="challenge-info">
                                    <input type="search" name="" id="" className="input-challenges"
                                           placeholder="мероприятие"/>
                                    {!SessionModel.isAdmin() ?
                                        <div className="first-message">
                                            <p className="city">
                                                <a href="https://disk.yandex.ru/d/gFdHsywtBCO5vw" target="_blank" className="active-link">Видеоинструкции</a> по работе с сервисом "Достижения"
                                            </p>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {certificateList && certificateList.map((certificate: IChallengeCertificate) =>
                                        <div className="cert-card" key={certificate.id}>
                                            <img src="/images/delete_red.svg" alt="" className="del-cert" onClick={() => deleteCertificate(certificate.id)}/>
                                            <div className="cert-card-main">
                                                <img src={certificate.logo ?
                                                    `${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.logo}` : "/images/no_ava_university.svg"
                                                } alt=""
                                                     className="challenge-avatar"/>
                                                <div className="user-info">
                                                    <p className="challenge-header" onClick={() => toggleSettings(certificate.id || null)}>{certificate.name}</p>
                                                    <p className="challenge-company">Сертификат участника</p>
                                                </div>
                                            </div>
                                            <div className="challenge-information">
                                                <p className="challenge-activity" onClick={() => publish(certificate.id)}>
                                                    {certificate.isDraft ? "Готов к выдаче" : "Переместить в черновики"}
                                                </p>
                                                <p className="cert-preview" onClick={() => setPreviewCertificateId(certificate.id)}>
                                                    Предпросмотр
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className="right-block" style={{right: rightBlockStyle}}>
                        <header className="header">
                            <p className="header-back" id="backmenu" onClick={() => toggleSettings(null)}>Назад</p>
                            <p className="name-menu">Новый</p>
                        </header>
                        {currentCertificate ? (
                            <CertificateSettings certificateId={currentCertificate}/>
                        ) : (<></>)}
                    </div>
                    <CertificatePreview certificateId={previewCertificateId} closePreview={closePreview}/>
                </div>
            </>
        )
    }
}