import React, {useEffect, useState} from "react";
import API from "../../../../requests/_axios";
import {IChallenge} from "../../../types/IChallenge";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CONFIG} from "../../../../CONFIG";
import {OrgHeader} from "../../common/OrgHeader";
import {OrgNavigation} from "../../common/OrgNavigation";
import SessionModel from "../../../../stores/SessionModel";
import {ModalMessage} from "../../common/ModalMessage";
import {toNumber} from "lodash";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";

export const ChallengeListAdmin = () => {

  const navigate = useNavigate();
  const [challengeList, setChallengeList] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [tab, setTab] = useState("current");
  const [cat, setCat] = useState("challenge");
  const [unloadInProgress, setUnloadInProgress] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setTab(searchParams.get("tab") || "current");
    setCat(searchParams.get("cat") || "challenge");
    fetchChallenges(searchParams.get("tab") || "current", searchParams.get("cat") || "challenge");
  }, []);

  const fetchChallenges = (tab: string, cat: string) => {
    API.get(`/challenge/api/v1/challenge?filter=${tab}&cat=${cat}&search=${searchQuery}&ownerId=${SessionModel.isAdmin() ? 0 : SessionModel.getCurrentUserAuthData().id}`).then((response => {
      setChallengeList(response.data);
    }));
  }

  const handleCreateChallenge = () => {
    API.post("/challenge/api/v1/challenge").then((response => {
      if (response.status === 200) {
        const updatedChallengesList = [...challengeList, response.data];
        setChallengeList(updatedChallengesList);
        navigate("/challenge/" + updatedChallengesList[updatedChallengesList.length - 1].id);
      } else {
        if (response.data.code === "009-035") {
          setError("Превышены лимиты, предусмотренные тарифом");
        } else if (response.data.code === "009-002") {
          setError("Действие недоступно для вашей роли");
        }
      }
    }));
  }

  const handleUnloading = (challenge: IChallenge) => {
    setUnloadInProgress(true);
    API.get(`/challenge/api/v1/xls/challenge/${challenge.id}`).then((response) => {
      setUnloadInProgress(false);
      if (response.status === 200) {
        let name = challenge.alias ? challenge.alias : challenge.id;
        const a = document.createElement('a');
        a.download = `${new Date().toISOString().split('T')[0]}_${name}.xlsx`;
        a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
        a.click();
      }
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setChallengeList([]);
      fetchChallenges(tab, cat);
    }
  }

  const handleChangeVisiblityLevel = (challengeId: number) => {
    ChallengeAPI.changeVisibilityLevel(challengeId, (response) => {
      if (response.status === 200)
        fetchChallenges(tab, cat);
      else
        setError("Произошла ошибка");
    })
  }

  const doMailing = (challengeId: number) => {
    if (confirm("Вы действительно хотите разослать?")) {
      setShowSpinner(true);
      ChallengeAPI.doChallengeMailing(challengeId, (response) => {
        setShowSpinner(false);
        if (response.status === 200) {
          window.location.reload();
        } else {
          setError("Произошла ошибка при рассылке");
        }
      });
    }
  }

  if (unloadInProgress) {
    return (
        <div className="load-block">
          <p className="load-sheet">
            Выгрузка
          </p>
          <p className="load-text">
            Началась выгрузка результатов, не закрывайте окно и не обновляйте страницу
          </p>
          <div className="circle-block">
            <div className="circle-load one-load"></div>
            <div className="circle-load two-load"></div>
            <div className="circle-load three-load"></div>
          </div>
        </div>
    )
  } else if (showSpinner) {
    return (
        <div className="load-block">
          <p className="load-sheet">
            Подождите
          </p>
          <p className="load-text">
            Производится рассылка
          </p>
          <div className="circle-block">
            <div className="circle-load one-load"></div>
            <div className="circle-load two-load"></div>
            <div className="circle-load three-load"></div>
          </div>
        </div>
    )
  } else {
    return (
        <div className="platform-background">
          <video autoPlay muted loop playsInline className="image-back-video">
            <source src={require("../../../../../public/images/main_video.mp4").default}/>
          </video>
          <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
          <div className="left-block">
            <OrgHeader preview={null}/>
            <main className="content">
              <OrgNavigation />
              <div className="main-list-center">
                <button className="button-add" onClick={handleCreateChallenge}>
                  Создать
                </button>
                <div className="my-challenge">
                  <div className="user-info">
                    <p className="fio-or-name">Мероприятия</p>
                  </div>
                  <ul className="nav-block-center">
                    <li className="nav-center-item">
                      <a href={`?tab=future&cat=${cat}`} className={tab === "future" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Будущие</a>
                    </li>
                    <li className="nav-center-item">
                      <a href={`?tab=current&cat=${cat}`} className={tab === "current" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Идущие</a>
                    </li>
                    <li className="nav-center-item">
                      <a href={`?tab=finished&cat=${cat}`} className={tab === "finished" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Прошедшие</a>
                    </li>
                    <li className="nav-center-item">
                      <a href={`?tab=draft&cat=${cat}`} className={tab === "draft" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Черновики</a>
                    </li>
                  </ul>
                </div>
                <ul className="challenge-info">
                  <input type="search" placeholder="Поиск" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchQuery(e.target.value);
                  }} onKeyDown={handleKeyDown} className="input-challenges"/>
                  {!SessionModel.isAdmin() ?
                      <div className="first-message">
                        <p className="city">
                          <a href="https://disk.yandex.ru/d/jvAi035p0_Vm0w" target="_blank" className="active-link">Видеоинструкции</a> по работе с сервисом
                        </p>
                      </div>
                      :
                      <></>
                  }
                  {challengeList && challengeList.length === 0 ? (
                      <li className="first-message">
                        <p className="fio-or-name">
                          {
                            tab === "current" ? ("Вы еще не создавали мероприятий") : ("Нет мероприятий")
                          }
                        </p>
                        <p className="city">
                          {
                            tab === "current" ? ("Для того чтобы создать мероприятие, вам необходимо нажать" +
                                "                    на кнопку создать и в открывшемся окне заполнить все поля") : ("В этом разделе пока нет мероприятий")
                          }
                        </p>
                      </li>
                  ) : (<></>)}
                  {challengeList && challengeList.map((challenge: IChallenge) =>
                      <li className="challenge-card" key={challenge.id} id={"challenge_" + challenge.id}>
                        <div className="challenge-card-main">
                          <img id={"challenge-avatar-" + challenge.id} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_challenge.svg"}}
                               src={CONFIG.STORAGE_URL + "/" + challenge.userId + "/challenge/" + challenge.id + "/avatar/" + challenge.avatar} alt=""
                               onClick={() => navigate("/challenge/" + challenge.id)}
                               className="challenge-avatar"/>
                          <div className="user-info">
                            <p className="challenge-header" onClick={() => navigate("/challenge/" + challenge.id)}>{challenge.name}</p>
                            <p className="challenge-company">{challenge.orgName || ""}</p>
                          </div>
                        </div>
                        <p className="challenge-description">
                          {challenge.description}
                        </p>
                        <div className="challenge-information">
                          {challenge.challengeType === "INDIVIDUAL" ? (<a href={`/challenge/${challenge.id}/users?tab=all`} className="active-link">Участники</a>) : (<></>)}
                          {challenge.challengeType === "COMMAND" ? (<a href={`/challenge/${challenge.id}/teams`} className="active-link">Команды</a>) : (<></>)}
                          {challenge.challengeType === "COMMAND" ? (<a href={`/challenge/${challenge.id}/ads`} className="active-link">Объявления</a>) : (<></>)}<br/>
                          {SessionModel.isAdmin() && challenge.userId !== SessionModel.getCurrentUserAuthData().id ?
                              <a onClick={() => handleChangeVisiblityLevel(toNumber(challenge.id))} className="active-link">{challenge.visibilityLevel === "DIRECT" ? "Отображать в ленте" : "Не отображать"}</a>
                          :
                              <></>
                          }
                        </div>
                        <div className="challenge-information">
                          <a href={challenge.site} className="challenge-landing" target="_blank">
                            Сайт
                          </a>
                          <button className="challenge-landing" onClick={() => handleUnloading(challenge)}>
                            Выгрузка
                          </button>
                          {SessionModel.isAdmin() && challenge.visibilityLevel === "FULL" ?
                              <button className={challenge.adminMailing ? "green-challenge-landing" : "challenge-landing"} style={{cursor: "pointer"}} onClick={() => doMailing(challenge.id || 0)}>{challenge.adminMailing ? "Переоповестить" : "Оповестить"}</button> : <></>
                          }
                          {/*<p className="challenge-date">*/}
                          {/*  Регистрация*/}
                          {/*</p>*/}
                        </div>
                      </li>
                  )}
                </ul>
              </div>
            </main>
          </div>
        </div>
    )
  }
}