import React, {useEffect, useState} from "react";
import SessionModel from "../../../../stores/SessionModel";
import {BlankPage} from "../BlankPage";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {Spinner} from "../Spinner";
import {Role} from "./Role";

export const RoleHome = () => {
  let panel;

  const [hasChallengePlan, setHasChallengePlan] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if ((SessionModel.isOrg() || SessionModel.isSub()) && !SessionModel.isAdmin()) {
      setShowSpinner(true);
    }
    getChallengePlan();
    if (SessionModel.isSub()) {
      setIsApproved(true);
      setShowSpinner(false);
    } else {
      ChallengeAPI.getProfile((response) => {
        if (response.status === 200) {
          setIsApproved(response.data.isApproved);
        } else {
          setIsApproved(false);
        }
        setShowSpinner(false);
      });
    }
  }, []);

  const getChallengePlan = () => {
    ChallengeAPI.getActivePlan((response) => {
      if (response.status === 200) {
        setHasChallengePlan(true);
      }
    })
  }

  if (showSpinner) {
    return <Spinner text={"Подождите..."} enabled={showSpinner}/>
  } else if (SessionModel.isOrg() && !hasChallengePlan && !isApproved) {
    panel = <BlankPage message={"В этом разделе вы можете добавлять администраторов мероприятий. Для доступа к функционалу вам необходимо подключить бесплатный базовый тариф или один из платных тарифов в разделе \"Тарифы\", далее \"Тарифы\""}/>
  } else if (SessionModel.isOrg() && !hasChallengePlan && isApproved) {
    panel = <BlankPage message={"В этом разделе вы можете добавлять администраторов мероприятий. Для доступа к функционалу вам необходимо подключить бесплатный базовый тариф или один из платных тарифов в разделе \"Тарифы\", далее \"Тарифы\". Посмотрите <a href=\"https://disk.yandex.ru/d/qa_yiJSmaUo3hw\" class=\"active-link\" target=\"_blank\">видеообзор</a> функционала"}/>
  } else {
    panel = <Role/>
  }

  return (panel)
}