import React, {useEffect, useState} from 'react';
import API from "../../../../../requests/_axios";
import {ITeamResponse} from "../../../../types/ITeamResponse";
import {CONFIG} from "../../../../../CONFIG";
import plural from "plural-ru";
import {IChallenge} from "../../../../types/IChallenge";
import {IProfilePersonalForm} from "../../../../types/IProfilePersonalForm";
import {ITeam} from "../../../../types/ITeam";
import SessionModel from "../../../../../stores/SessionModel";
import {toNumber} from "lodash";
import {useNavigate} from "react-router-dom";

export const Team: React.FC<{
  challengeId: number | undefined,
  challenge: IChallenge,
  enabled: boolean,
  showNewTeam: (team: ITeam | undefined) => void,
  showProfilePanel: (profile: IProfilePersonalForm, team: ITeam, enableRecruitmentPanel: boolean) => void}> = (
      {challengeId, challenge, enabled, showNewTeam, showProfilePanel}) => {

  const [teamResponse, setTeamResponse] = useState<ITeamResponse>();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.get("/challenge/api/v1/challenge/" + challengeId + "/team/my").then((response) => {
      if(response.status === 200) {
        setTeamResponse(response.data);
      }
      setLoading(false);
      /*if (challenge.challengeUser?.isTeamCreator === false && !response.data.team)
        navigate("/challenge/" + challenge.id + "/my/recruitment");*/
    });
  }, []);

  const deleteTeam = () => {
    API.delete(`/challenge/api/v1/challenge/${challengeId}/team/${teamResponse?.team.id}`).then((response) => {
      if(response.status === 200) {
        window.location.reload();
      }
    })
  }

  const completeOnBoarding = () => {
    setError("");
    API.post("/challenge/api/v1/challenge/" + challengeId + "/team/my/complete").then((response) => {
        window.location.reload();
    })
  }

  const backToOnBoarding = () => {
    setError("");
    API.delete("/challenge/api/v1/challenge/" + challengeId + "/team/my/complete").then((response) => {
      if (response.status === 200) {
        window.location.reload();
      } else {
        setError("Вы не можете вернуться к набору, поскольку загрузили решение или выполнили тест по одному из заданий этапа в данном составе");
        // @ts-ignore
        document.querySelector('.challenge-info').scrollTo(0,0);
      }
    })
  }

  const leaveTeam = () => {
    API.post(`/challenge/api/v1/challenge/${challengeId}/team/my/leave`).then((response) => {
      if(response.status === 200) {
        navigate("/challenge");
      }
    })
  }

  const leaveChallenge = () => {
    API.delete("/challenge/api/v1/challenge/" + challengeId + "/my").then((response) => {
      if(response.status === 200) {
        navigate("/challenge");
      }
    })
  }

  const selectTeam = () => {
    API.post("/challenge/api/v1/challenge/" + challengeId + "/choose/my").then((response) => {
      if(response.status === 200) {
        navigate(`/challenge/${challengeId}/my/recruitment`);
        window.location.reload();
      }
    });
  }

  const canCompleteOnBoarding = () : boolean => {
    return toNumber(SessionModel.getCurrentUserAuthData().id) === teamResponse?.team.userId &&
        teamResponse.team.status === 3 &&
        teamResponse.members.length >= (challenge?.partyFrom || 0)
  }

  const canBackToOnBoarding = () : boolean => {
    return toNumber(SessionModel.getCurrentUserAuthData().id) === teamResponse?.team.userId &&
        teamResponse.team.status === 1
  }

  const canDeleteTeam = () : boolean => {
    if (challenge.registrationEndDate && new Date(challenge.registrationEndDate) > new Date() && teamResponse?.team.status === 3)
      return toNumber(SessionModel.getCurrentUserAuthData().id) === teamResponse?.team.userId
    return false;
  }

  const canLeaveTeam = () : boolean => {
    if (challenge.registrationEndDate && new Date(challenge.registrationEndDate) > new Date() && teamResponse?.team.status === 3)
      return toNumber(SessionModel.getCurrentUserAuthData().id) !== teamResponse?.team.userId
    return false;
  }

  const onEmptyTgChat = () => {
    alert("Чат команды ещё не создан капитаном команды");
  }

  if(enabled && !loading) {
    if(teamResponse?.team.id) {
      return (
          <div className="challenge-info">
            <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
            {teamResponse.team.status === 2 ? (
                <>
                  <p className="important-message">
                    Ваша команда выбыла из мероприятия
                  </p>
                </>
            ): (<></>)}
            {(challenge?.partyFrom || 0) > teamResponse.members.length ? (
                <>
                  <p className="important-message">
                    В вашей команде должно быть не менее {plural(challenge?.partyFrom || 0, "%d участника", "%d участников", "%d участников")}
                  </p>
                  <div className="first-message">
                    <p className="city">
                      Привлечь участников в свою команду вы можете с помощью разделов "Объявления" и "Набор". <a href="https://youtu.be/B6UIhVP2n_s" target="_blank" className="active-link">Видеоинструкция</a>
                    </p>
                  </div>
                </>
                ) : (
                <div className="first-message">
                  <p className="city">
                    <a href="https://youtu.be/B6UIhVP2n_s" target="_blank" className="active-link">Видеоинструкция</a>
                  </p>
                </div>
            )}
            <div className="comand-card">
              <div className="comand-card-info">
                <img src={CONFIG.STORAGE_URL + "/" + teamResponse.team.userId + "/team/" + teamResponse.team.id + "/avatar/" + teamResponse.team.avatar}
                     onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_team.svg"}}
                     alt="" className="comand-avatar"/>
                  <div className="comand-info-text">
                    <h3 className="comand-name" onClick={() => showNewTeam(teamResponse?.team)}>
                      {teamResponse.team.name}
                    </h3>
                    <p className="comand-description">
                      {plural(teamResponse.members.length, "%d человек", "%d человека", "%d человек")}
                    </p>
                  </div>
              </div>
              {teamResponse.team.telegram ? (
                  <a href={teamResponse.team.telegram} className="telega" target="_blank">
                    Общий чат
                  </a>
              ) : (
                  <a onClick={onEmptyTgChat} className="telega" target="_blank">
                    Общий чат
                  </a>
              )}

            </div>
            <h3 className="comand-name">
              Участники команды
            </h3>
            {teamResponse.members && teamResponse.members.map((p: IProfilePersonalForm, i) =>
            <div className="challenge-card" key={i}>
              <div className="challenge-card-main">
                <img src={CONFIG.STORAGE_URL + "/" + p.userId + "/avatar/" + p.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                     alt=""
                     className="challenge-avatar"/>
                  <div className="user-info">
                    <p className="challenge-header" onClick={() => showProfilePanel(p, teamResponse.team, false)}>
                      {p.firstname + " " + p.lastname}
                    </p>
                    <p className="challenge-company">{p.city + ", " + p.region}</p>
                  </div>
              </div>
              <div className="challenge-information">
                <p className="challenge-activity">
                  {p.userId === teamResponse?.team.userId ? "Кэп" : p.teamRole || ""}
                </p>
                {p.telegram ?
                    <p className="message-user">
                      <a href={`https://t.me/${p.telegram}`} target="_blank">Сообщение</a>
                    </p>
                    :
                    <></>
                }
              </div>
            </div>
            )}

            <button className="btn-main" onClick={() => {if(window.confirm('Вы действительно желаете закончить набор и сформировать команду?')){completeOnBoarding()}}} style={{display: canCompleteOnBoarding() ? "" : "none"}}>Закончить формирование команды</button>
            <button className="btn-main" onClick={() => {if(window.confirm('Вы действительно желаете снова открыть набор?')){backToOnBoarding()}}} style={{display: canBackToOnBoarding() ? "" : "none"}}>Вернуться к набору команды</button>
            <button className="del-coamnd" onClick={() => {if(window.confirm('Вы действительно желаете расформировать и удалить команду?')){deleteTeam()}}} style={{display: canDeleteTeam() ? "" : "none"}}>Удалить команду</button>
            <button className="del-coamnd" onClick={() => {if(window.confirm('Вы действительно желаете покинуть команду?')){leaveTeam()}}} style={{display: canLeaveTeam() ? "" : "none"}}>Покинуть команду</button>

          </div>
    );
    } else {
      return (
          <div className="challenge-info">
            {challenge?.challengeUser?.status === 2 ? (
                <>
                  <p className="important-message">
                    Вы не собрали команду вовремя и выбыли из мероприятия
                  </p>
                </>
            ): (<>
              <div className="first-message">
                <p className="fio-or-name">
                  Создайте или выберите команду
                </p>
                <p className="city">
                  Данное мероприятие является командным. Для участия вам необходимо создать свою команду или присоединиться к уже существующей. <a href="https://disk.yandex.ru/i/kAEVWhXDI2q7uA" target="_blank" className="active-link"> Видеоинструкция</a>
                </p>
              </div>
              <button className="btn" id="personal" onClick={() => showNewTeam(undefined)}>
                <p className="btn-heading profile">
                  Создать команду
                </p>
                <p className="btn-description">
                  Создайте свою команду, если вы готовы быть капитаном.
                </p>
              </button>
              <button className="btn" onClick={() => selectTeam()}>
                <p className="btn-heading profile">
                  Выбрать команду
                </p>
                <p className="btn-description">
                  Присоединитесь к одной из уже зарегистрированных команд.
                </p>
              </button>
              <button className="btn-no" onClick={() => {if(window.confirm('Вы действительно желаете покинуть мероприятие? Если данный конкурс командный, а вы капитан, то ваша команда будет также удалена')){leaveChallenge()}}}>Отказаться от участия</button>
            </>)}
          </div>
      );
    }
  } else {
    return (<>Загрузка...</>);
  }
}