import React, {useEffect, useState} from "react";
import {CONFIG} from "../../../CONFIG";
import {useParams, useSearchParams} from "react-router-dom";
import {toNumber} from "lodash";
import API from "../../../requests/_axios";
import plural from "plural-ru";
import {ITeamResponse} from "../../types/ITeamResponse";
import {NewTeam} from "../personal/challenge/right/NewTeam";
import {ITeam} from "../../types/ITeam";
import {IProfilePersonalForm} from "../../types/IProfilePersonalForm";
import {ProfileIndividualView} from "../common/ProfileIndividualView";
import {TeamView} from "./right/TeamView";
import InfiniteScroll from "react-infinite-scroll-component";
import {OrgHeader} from "../common/OrgHeader";
import {OrgNavigation} from "../common/OrgNavigation";
import SessionModel from "../../../stores/SessionModel";
import {ModalBanMessage} from "../common/ModalBanMessage";
import {IBanModal} from "../../types/IBanModal";
import {IBanRequest} from "../../types/IBanRequest";

export const TeamList = () => {

    const params = useParams();
    const [teams, setTeams] = useState([]);
    const [tab, setTab] = useState("all");
    const [team, setTeam] = useState<ITeam>();
    const [teamResponse, setTeamResponse] = useState<ITeamResponse>();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchInProgress, setSearchInProgress] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTeamMember, setCurrentTeamMember] = useState<IProfilePersonalForm | undefined>(undefined);
    const [currentMemberTeam, setCurrentMemberTeam] = useState<ITeam | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);
    const [searchTrigger, setSearchTrigger] = useState(true);
    const [banMessage, setBanMessage] = useState<IBanModal>();

    useEffect(() => {
        setTab(searchParams.get("tab") || "all");
        setSearchQuery(searchParams.get("search") || searchQuery);
        setCurrentPage(currentPage || toNumber(searchParams.get("page")) || 0);
        fetchMoreData();
    }, [searchTrigger]);

    const fetchMoreData = () => {
        setCurrentPage(currentPage + 1);
        API.get(`/challenge/api/v1/challenge/${params.id}/team/search?query=${searchQuery}&page=${currentPage}&filter=${searchParams.get("tab")}`)
            .then((response) => {
                if(response.status === 200) {
                    setTeams(teams.concat(response.data.items));
                    setTotalPages(response.data.totalPages);
                    setTotalItems(response.data.total);
                    if (response.data.totalPages <= currentPage) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                }
            });
    }

    const handleShowDetails = (team: ITeam) => {
        hideTeamMember();
        hideTeamView();
        setTeam(team);
        // @ts-ignore
        document.querySelector('.right-block').style.right = "0"
    }
    const handleCloseDetails = () => {
        setTeam(undefined);
        // @ts-ignore
        document.querySelector('.right-block').style.right = "-100vw"
    }
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setTeams([]);
            setCurrentPage(0);
            setSearchTrigger(!searchTrigger);
        }
    }
    const ban = (teamId: number) => {
        setBanMessage({...banMessage, title: "Блокировка команды", text: "Вы действительно хотите заблокировать команду?", id: teamId})
    }

    const doBan = (id: number, reason: string) => {
        const request : IBanRequest = {id: id, reason: reason};
        API.post(`/challenge/api/v1/challenge/${params.id}/team/${id}/ban`, request)
            .then((response) => {
                if(response.status === 200) {
                    window.location.reload();
                }
                setSearchInProgress(false);
            });
    }


    const showProfilePanel = (profile: IProfilePersonalForm, team: ITeam | undefined, enableRecruitmentPanel: boolean) : void => {
        setCurrentTeamMember(profile);
        setCurrentMemberTeam(team);
        showTeamMember();
    }
    const showTeamMember = () => {
        handleCloseDetails();
        // @ts-ignore
        document.querySelector('#right-block-team-member').style.right = "0";
    }
    const hideTeamMember = () => {
        setCurrentMemberTeam(undefined);
        // @ts-ignore
        document.querySelector('#right-block-team-member').style.right = "-100vw";
    }

    const showTeamView = (teamResponse: ITeamResponse) => {
        handleCloseDetails();
        hideTeamMember();
        setTeamResponse(teamResponse);
        // @ts-ignore
        document.querySelector('#right-block-team-view').style.right = "0";
    }
    const hideTeamView = () => {
        setTeamResponse(undefined);
        // @ts-ignore
        document.querySelector('#right-block-team-view').style.right = "-100vw";
    }

    return (
        <div className="platform-background">
            <img src="/images/main.jpg" className="image-back-video" alt=""/>
            <ModalBanMessage banDetails={banMessage} _callbackCancel={() => setBanMessage(undefined)} _callbackBan={doBan}/>
            <div className="left-block">
                <OrgHeader preview={null}/>
                <main className="content">
                    <OrgNavigation />
                    <div className="main-list-center">
                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Пользователи</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href={`/challenge/${params.id}/teams?tab=all`} className={tab === "all" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Все</a>
                                </li>
                                <li className="nav-center-item">
                                    <a href={`/challenge/${params.id}/teams?tab=banned`} className={tab === "banned" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Заблокированные</a>
                                </li>
                            </ul>
                        </div>
                        <ul className="challenge-info" id="scrollableList">
                            <input type="text" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchQuery(e.target.value);
                            }} onKeyDown={handleKeyDown} placeholder="Название команды" className="input-challenges"/>
                            <div className="first-message">Всего: {totalItems}</div>
                            {!searchInProgress && teams.length === 0 ? (
                                <div className="first-message-error" style={{display: teams.length === 0 ? "" : "none"}}>
                                    <p className="fio-or-name">
                                        Поиск не дал результатов
                                    </p>
                                </div>
                            ) : (<></>)}
                            <InfiniteScroll next={fetchMoreData} hasMore={hasMore} loader={<h4>Загрузка...</h4>} dataLength={teams.length} scrollableTarget="scrollableList" className="challenge-info">

                                {teams && teams.map((teamResponse: ITeamResponse, i) =>
                                    <li key={i} className="comand-card">
                                        <div className="comand-card-info">
                                            <img src={CONFIG.STORAGE_URL + "/" + teamResponse.team.userId + "/team/" + teamResponse.team.id + "/avatar/" + teamResponse.team.avatar}
                                                 onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_team.svg"}}
                                                 alt="" className="comand-avatar" style={{cursor: "pointer"}} onClick={() => handleShowDetails(teamResponse?.team)}/>
                                            <div className="comand-info-text">
                                                <h3 className="comand-name" onClick={() => handleShowDetails(teamResponse?.team)}>
                                                    {teamResponse.team.name}
                                                </h3>
                                                <p className="comand-description" style={{cursor: "pointer"}} onClick={() => showTeamView(teamResponse)}>
                                                    {plural(teamResponse.members.length, "%d человек", "%d человека", "%d человек")}
                                                </p>
                                            </div>
                                        </div>
                                        {teamResponse.team.telegram  && !SessionModel.isOrg() ? (
                                            <a href={teamResponse.team.telegram} className="telega" target="_blank">
                                                Общий чат
                                            </a>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="block-input">
                                            {teamResponse.team.isBanned ?
                                                <div className="block-input">
                                                    <p className="legend-about">
                                                        Причина блокировки
                                                    </p>
                                                    <ul className="interests">
                                                        <li className="interest-item-comand" style={{backgroundColor: "rgba(208, 208, 255, 0.3)"}}>
                                                            {teamResponse.team.banReason}
                                                        </li>
                                                    </ul>
                                                </div>
                                                :
                                                <></>
                                            }
                                            {teamResponse.team.isBanned ? <button className="btn-main" onClick={() => doBan(teamResponse.team.id || 0, "")}>Разблокировать</button> : <button className="btn-no" onClick={() => ban(teamResponse.team.id || 0)}>Заблокировать</button>}
                                        </div>
                                    </li>
                                )}
                            </InfiniteScroll>
                        </ul>
                    </div>
                </main>
            </div>
            <div className="right-block" style={{right: "-100vw"}}>
                <header className="header">
                    <p className="header-back" onClick={handleCloseDetails} id="backmenu">Назад</p>
                    <p className="name-menu">Об организации</p>
                </header>
                <div className="container-right-block">
                    <NewTeam team={team} challengeId={toNumber(params.id)}/>
                </div>
            </div>
            <div className="right-block" id="right-block-team-view" style={{right: "-100vw"}}>
                <header className="header">
                    <p className="header-back" onClick={hideTeamView} id="backmenu">Назад</p>
                    <p className="name-menu">Участники команды</p>
                </header>
                <div className="container-right-block">
                    <TeamView teamResponse={teamResponse} showProfilePanel={showProfilePanel}/>
                </div>
            </div>
            <div className="right-block" id="right-block-team-member" style={{right: "-100vw"}}>
                <header className="header">
                    <p className="header-back" onClick={hideTeamMember} id="backmenu">Назад</p>
                    <p className="name-menu">Участник команды</p>
                </header>
                {
                    currentTeamMember ? (
                        <ProfileIndividualView
                            key={currentTeamMember.id}
                            currentForm={currentTeamMember}
                            team={currentMemberTeam} enableRecruitmentPanel={false}/>
                    ) : (<></>)
                }
            </div>
        </div>
    );
}