import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import API from "../../../requests/_axios";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";
import {IChallenge} from "../../types/IChallenge";
import {CONFIG} from "../../../CONFIG";
import {ProfileUniversityHeader} from "./ProfileUniversityHeader";
import {NavigationUniversity} from "./NavigationUniversity";
import SessionModel from "../../../stores/SessionModel";
import {getChallengeStatus} from "../../helpers/ChallengeHelper";

export const UniversityChallenges = () => {

    const [challengeList, setChallengeList] = useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [tab, setTab] = useState("current");
    const [currentDownload, setCurrentDownload] = useState(0);
    const [error, setError] = useState("");

    useEffect(() => {
        if (SessionModel.getCurrentUserAuthData().type !== "EDU") {
            window.location.href = "/";
        }
        setTab(searchParams.get("tab") || "current");
        fetchChallenges(searchParams.get("tab") || "current");
    }, []);

    const fetchChallenges = (tab: string) => {
        API.get(`/challenge/api/v1/challenge?filter=university&search=${searchQuery}`).then((response => {
            if (response.status === 200) {
                setChallengeList(response.data);
            } else {
                setError("1");
            }
        }));
    }

    const handleUnloading = (challenge: IChallenge) => {
        if (currentDownload !== 0) {
            alert("Дождитесь окончания текущей выгрузки");
            return;
        }
        setCurrentDownload(challenge.id || 0);
        if (challenge.id) {
            ChallengeAPI.unloadChallenge(challenge.id, SessionModel.getCurrentUserAuthData().id, (response) => {
                if (response.status === 200) {
                    let name = challenge.alias ? challenge.alias : challenge.id;
                    const a = document.createElement('a');
                    a.download = `${new Date().toISOString().split('T')[0]}_${name}.xlsx`;
                    a.href = `data:application/vnd.ms-excel;base64,${response.data.fileBase64}`;
                    a.click();
                }
                setCurrentDownload(0);
            });
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setChallengeList([]);
            fetchChallenges(tab);
        }
    }

    return (
        <div className="platform-background">
            <img src="/images/main.jpg" className="image-back-video" alt=""/>
            <div className="left-block">
                <ProfileUniversityHeader newPreview={null}/>
                <main className="content">
                    <NavigationUniversity />
                    <div className="main-list-center">
                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Участники</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href="?tab=students" className={tab === "students" || tab === "" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Все</a>
                                </li>
                                <li className="nav-center-item">
                                    <a href="?tab=challenges" className={tab === "challenges" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>По мероприятиям</a>
                                </li>
                                <li className="nav-center-item">
                                    <a href="?tab=unloads" className={tab === "unloads" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Выгрузки</a>
                                </li>
                            </ul>
                        </div>
                        <ul className="challenge-info">
                            <input type="search" placeholder="Поиск" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchQuery(e.target.value);
                            }} onKeyDown={handleKeyDown} className="input-challenges"/>
                            <li className="first-message">
                                <p className="city">
                                    В этом разделе отображаются все мероприятия, в которых принимают или принимали участие ваши представители.
                                </p>
                            </li>
                            {challengeList && challengeList.length === 0 ? (
                                <li className="first-message">
                                    <p className="fio-or-name">
                                        Нет мероприятий
                                    </p>
                                </li>
                            ) : (<></>)}
                            <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>
                                У вас нет разрешения на просмотр персональных данных. Вероятнее всего, у вас не подтверждены или истекли полномочия. Для уточнения причин обратитесь в техподдержку по адресу <a href="mailto:info@braim.org">info@braim.org</a> или в Телеграм <a href="https://t.me/braim_org" target="_blank">@braim_org</a>
                            </p>
                            {challengeList && challengeList.map((challenge: IChallenge) =>
                                <li className="challenge-card" key={challenge.id} id={"challenge_" + challenge.id}>
                                    <div className="challenge-card-main">
                                        <img id={"challenge-avatar-" + challenge.id} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_challenge.svg"}}
                                             src={CONFIG.STORAGE_URL + "/" + challenge.userId + "/challenge/" + challenge.id + "/avatar/" + challenge.avatar} alt=""
                                             className="challenge-avatar"/>
                                        <div className="user-info">
                                                <p className="challenge-header" style={{cursor: "default"}}>{challenge.name} ({challenge.endDate && new Date(challenge.endDate) < new Date() ? "Прошедшее" : "Идущее"})</p>
                                            <p className="challenge-company">{challenge.orgName || ""}</p>
                                        </div>
                                    </div>
                                    <p className="challenge-date">
                                        {getChallengeStatus(challenge)}
                                    </p>
                                    <p className="challenge-description">
                                        {challenge.description}
                                    </p>
                                    <div className="challenge-information">
                                        <a href={challenge.site || `${window.location.origin}/challenge/${challenge.id}/my`} className="challenge-landing" target="_blank">
                                            Сайт
                                        </a>
                                        <button className="challenge-landing" onClick={() => handleUnloading(challenge)}>
                                            { currentDownload !== 0 && currentDownload == challenge.id ? "Выгружается..." : "Выгрузка"}
                                        </button>
                                    </div>
                                </li>

                            )}
                        </ul>
                    </div>
                </main>
            </div>
        </div>
    )
}